import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { html } from 'htm/preact';
import { useState } from "preact/hooks";
import { base58, to32 } from './shared/core';


export function useInput(init: any, callback?: any) {
    const [state, setState] = useState(init);
    return [state, (ev: any) => {
        setState(ev.target.value);
        if (callback) callback(ev.target.value);
    }];
}

/*
export function useForm(init: any, callback?: any) {
    const [state, setState] = useState(init);
    return [state, (ev: any) => {
        setState({...state, ...data);
        if (callback) callback(ev.target.value);
    }];
}*/


const longToByteArray = function (long: number) {
    // we want to represent the input as a 8-bytes array
    var byteArray = [0, 0, 0, 0];

    for (var index = 0; index < byteArray.length; index++) {
        var byte = long & 0xff;
        byteArray[index] = byte;
        long = (long - byte) / 256;
    }

    return Uint8Array.from(byteArray.reverse());
};

export function generateId(organizationId: string, length = 16) {
    const t = longToByteArray(Math.round(Date.now() / 1000));
    const time = base58.encode(t);
    return organizationId + time + base58.random(length - (organizationId + time).length);
}

export function Pagination({ count, page, pageSize }: { count: number, page: number, pageSize?: number }) {
    pageSize = pageSize || 20;
    const pagesCount = Math.ceil(count / pageSize);
    return html`<div class="card-footer d-flex align-items-center justify-content-center">
    <a href="${window.location.pathname}?page=${page - 1}"
        class="btn btn-sm btn-falcon-default mr-1 ${page <= 1 ? 'disabled' : ''}" type="button" title="Previous"
        data-list-pagination="prev">
        <${FontAwesomeIcon} icon=${['fas', 'chevron-left']} /></a>
    <ul class="pagination mb-0" style="width:150px;">
        <span style="margin:auto">
            ${page}/${pagesCount} (${count})
        </span>
    </ul>
    <a href="${window.location.pathname}?page=${page + 1}"
        class="btn btn-sm btn-falcon-default ml-1 ${page >= pagesCount ? 'disabled' : ''}" type="button" title="Next"
        data-list-pagination="next">
        <${FontAwesomeIcon} icon=${['fas', 'chevron-right']} />
    </a>
</div>`;
}

export function getPage() {
    return parseInt(new URLSearchParams(location.search).get('page') || '1');

}


export function t(value: string) {
    return {
        'active': 'نشط',
        'Users': 'المستخدمين',
        'Settings': 'الإعدادات',
        'Password': 'كلمة المرور',
        'Loading': 'جار التحميل',
        'Create': 'إنشاء',
        'Edit': 'تعديل',
        'Delete': 'حذف',
        'Save': 'حفظ',
        'Cancel': 'إلغاء',
        'Search': 'بحث',
        'Yes': 'نعم',
        'No': 'لا',
        'Add': 'إضافة',
        'Update': 'تحديث',
        'Status': 'الحالة',
        'Name': 'الاسم',
        'Name/Company Name': 'الاسم الجتماعي /الاسم و اللقب',
        'Roles': 'الصلاحيات',
        'Unique Identifier': 'الرقم الوحيد',
        'User added successfully': 'تمت إضافة المستخدم بنجاح',
        'User updated successfully': 'تم تحديث المستخدم بنجاح',
        'Employee': 'موظف',
        'Administration': 'ادارة التطبيق',
        'Admin': 'مدير',
        'Files': 'الملفات',
        'Procedures': 'الإجراءات',
        'Procedures Types': 'أنواع الإجراءات',
        'Procedure Type': 'نوع الإجراء',
        'Taxable Entity': 'المطالب بالضريبة',
        'Contact Person': 'الممثل القانوني',
        'Contact Persons': 'الممثل القانوني',
        'Taxable Entities': 'المطالببن بالضريبة',
        'Tax Identification Number': 'المعرف الجبائي',
        'Legal Status': 'الوضع القانوني',
        'Address': 'العنوان',
        'Procedure': 'الإجراء',
        'Contacts': 'الممثل القانوني',
        'Contact': 'الممثل القانوني',
        'National Identification Number': 'الرقم الوطني',
        'Phone': 'الهاتف',
        'Email': 'البريد الإلكتروني',
        'Postal Code': 'الرمز البريدي',
        'User': 'المستخدم',
        'User removed successfully': 'تم حذف المستخدم بنجاح',
        'Serial Number': 'الرقم التسلسلي',
        'Ordering Number': 'رقم الطلب',
        'Accepted Date': 'تاريخ القبول',
        'Closed Date': 'تاريخ الإغلاق',
        'Date': 'التاريخ',
        'pending': 'قيد الانتظار',
        'Result': 'النتيجة',
        'Share': 'مشاركة',
        'Shared by': 'مشاركة بواسطة',
        'to': 'إلى',
        'at': 'في',
        'Vat Code': 'رمز الضريبة',
        'Category Code': 'رمز الفئة',
        'Main Activity': 'النشاط الرئيسي',
        'Common Name': 'الاسم الشائع',
        'Activity': 'النشاط',
        'Secondary Activity': 'النشاط الثانوي',
        'Code': 'الرمز',
        'Owner': 'الموضف المسؤول عن فتح الاجراء',
        'Accept': 'قبول',
        'Close': 'إغلاق',
        'Are you sure you want to accept this procedure?': 'هل أنت متأكد أنك تريد قبول هذا الإجراء؟',
        'Please enter the status of the procedure on closing': 'الرجاء إدخال حالة الإجراء عند الإغلاق',
        'Procedure closed successfully': 'تم إغلاق الإجراء بنجاح',
        'Procedure accepted successfully': 'تم قبول الإجراء بنجاح',
        'Regulatory Time Limit': 'الحد الزمني التنظيمي',
        'days': 'أيام',
        'Office': 'المكتب',
        'Download': 'تحميل',
        'Access': 'الحقوق',
        'Preview': 'معاينة',
        'shared successfully': 'تمت المشاركة بنجاح',
        'Procedure Name': 'اسم الإجراء',
        'This element exists in the system': 'هذا العنصر موجود في النظام',
        'Contact Person Role': 'صفة الممثل القانوني',
        'open': 'مفتوح',
        'done': 'منجز',
        'rejected': 'مرفوض',
        'accepted': 'مقبول',

    }[value] || value;
}