import { html } from "htm/preact";
import { generateId, getPage, Pagination, t, useInput } from "./core";
import { useContext, useEffect, useRef, useState } from "preact/hooks";

import Select from 'react-select'
import { citites, socialSecurityOptions } from "./data";

import { Api, debounce, filesStore, getDownloadUrl, merge, searchObjects, SessionContext, SFile, state, useSession } from "./store";
import { Billable } from "./shared/core";
import { route } from "preact-router";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CreatableSelect from 'react-select/creatable';
import { Drive } from "./products";
import { UpdateObject } from "./crud";

// hello

export function ObjectRow(path: string, object: any, onDelete: any) {

    const [open, setOpen] = useState(false);
    const [state, dispatch] = useSession();
    const [busy, setBusy] = useState(false);

    useEffect(() => {
        const handler = () => setOpen(false);
        document.addEventListener('click', handler, { passive: true });
        return () => {
            document.removeEventListener('click', handler);
        };
    }, []);


    function onDeleteClick(object: any) {
        if (busy) return;
        setBusy(true);
        Api.remove(path + '/' + object.id, state.token || '')
            .then(() => {
                console.log('DELETED');
                setOpen(false);
                onDelete(object);
                setBusy(false);
                toast.success(t('Deleted successfully'));
            })
            .catch((err) => {
                toast.success(t('Error deleting ' + err.message));
                setBusy(false);
            });
    };

    return html`<tr class="btn-reveal-trigger">
    <td class="no align-middle white-space-nowrap">${object.category}</td>
    <td class="name align-middle">
        <a href="${path}/${object.id}">
            <h6>${object.name}</h6>
        </a>
    </td>
    <td class="phone align-middle desktop">${object.common_name}</td>
    <td class="align-middle white-space-nowrap text-right actions-column">
        <div class="dropdown font-sans-serif">
            <button onclick=${(ev: any) => {
            ev.preventDefault();
            ev.stopImmediatePropagation();
            setOpen(!open);
        }} class="btn btn-link text-600 btn-sm dropdown-toggle btn-reveal"
                type="button"
                id="customer-dropdown-0" data-toggle="dropdown" data-boundary="window" aria-haspopup="true"
                aria-expanded="false">
                <${FontAwesomeIcon} icon=${['fas', 'ellipsis-h']} class="fs--1" /></button>
            <div class="dropdown-menu dropdown-menu-right border py-0 ${open ? 'show' : ''}"
                aria-labelledby="customer-dropdown-0">
                <div class="bg-white">
                    <!--<a class="dropdown-item" href="#!">Edit</a>-->
                    <a class="dropdown-item text-danger" onclick=${() => onDeleteClick(object)}>Delete</a></div>
            </div>
        </div>
    </td>
</tr>`;
}


const options = citites.map(it => ({ label: it.c + ', ' + it.g, value: it.id1 + '-' + it.id2 }));
const insuranceOptions = [{ label: 'Selectioner' }, { label: 'CNSS', value: 'cnss' }, { label: 'CNRPS', value: 'cnrps' }];

/*
function useForm(pairs:[string,string][],callback:any){

    const debounce = 
    const out:any[] = [];
    for(const pair of pairs) {
        const [state, setState] = useState(pair[1]);
        out.push([state, (ev: any) => {
            setState(ev.target.value);


            // dispatch({collection, type: 'mutate', id,  data: {[pair[0]]: ev.target.value}})
        }]);
    }
    return out;
}*/


export function FileUpload() {

    return html`<form class="dropzone dropzone-multiple p-0" id="my-awesome-dropzone" data-dropzone="data-dropzone" action="#!">
    <div class="fallback"><input name="file" type="file" multiple="multiple" /></div>
    <div class="dz-message" data-dz-message="data-dz-message"> <img class="me-2" src="../../../assets/img/icons/cloud-upload.svg" width="25" alt="" />Drop your files here</div>
    <div class="dz-preview dz-preview-multiple m-0 d-flex flex-column">
      <div class="d-flex media mb-3 pb-3 border-bottom btn-reveal-trigger"><img class="dz-image" src="../../../assets/img/generic/image-file-2.png" alt="..." data-dz-thumbnail="data-dz-thumbnail" />
        <div class="flex-1 d-flex flex-between-center">
          <div>
            <h6 data-dz-name="data-dz-name"></h6>
            <div class="d-flex align-items-center">
              <p class="mb-0 fs-10 text-400 lh-1" data-dz-size="data-dz-size"></p>
              <div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress=""></span></div>
            </div><span class="fs-11 text-danger" data-dz-errormessage="data-dz-errormessage"></span>
          </div>
          <div class="dropdown font-sans-serif"><button class="btn btn-link text-600 btn-sm dropdown-toggle btn-reveal dropdown-caret-none" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><span class="fas fa-ellipsis-h"></span></button>
            <div class="dropdown-menu dropdown-menu-end border py-2"><a class="dropdown-item" href="#!" data-dz-remove="data-dz-remove">Remove File</a></div>
          </div>
        </div>
      </div>
    </div>
  </form>`;
}

export interface ProcedureType {
    id: number;
    code: string;
    name: string;
    common_name: string;
    category?: string;
    time_limit?: number;
    data: { [key: string]: string };
}

export function ProcedureTypeForm({ data }: { data?: ProcedureType }) {

    const procedureType = data;

    const [name, setName] = useInput(procedureType?.name || '');
    const [code, setCode] = useInput(procedureType?.code || '');
    const [category, setCategory] = useInput(procedureType?.category || '');
    const [common_name, setCommonName] = useInput(procedureType?.common_name || '');
    const [time_limit, setTimeLimit] = useInput(procedureType?.time_limit ? procedureType?.time_limit / 24 + '' : '');

    const [validated, setValidated] = useState(false);
    const [state, dispatch] = useSession();


    const onSubmit = async (ev: Event) => {
        ev.preventDefault();
        ev.stopPropagation();
        setValidated(true);
        if ((ev.target as any).checkValidity()) {


            const data: any = { code, name, common_name, category, time_limit: parseInt(time_limit) * 24 };


            if (procedureType?.id) {
                Api.patch('/procedures-types', procedureType.id, data, state.token)
                    .then((procedureType) => {
                        toast.success(t('Procedure type updated successfully'));
                        route('/procedures-types/' + procedureType.id);
                    });

            } else {
                Api.post('/procedures-types', data, state.token)
                    .then((procedureType) => {
                        toast.success(t('Procedure type added successfully'));
                        route('/procedures-types/' + procedureType.id);
                    });
            }

        }

    };

    return html`<form onsubmit="${onSubmit}" class="row g-3 needs-validation ${validated ? 'was-validated' : ''}" novalidate>
    <div class="col-lg-6">
        <label class="form-label" for="code">${t('Code')}</label>
        <input required pattern=".{3,}" class="form-control" id="code" type="text" oninput="${setCode}"
            value=${code} />
    </div>
    <div class="col-lg-6">
        <label class="form-label" for="name">${t('Name')}</label>
        <input required pattern=".{3,}" class="form-control" id="name" type="text" oninput="${setName}"
            value="${name}" />
    </div>
    <div class="col-lg-6">
        <label class="form-label" for="name">${t('Common Name')}</label>
        <input required pattern=".{3,}" class="form-control" id="name" type="text" oninput="${setCommonName}" value="${common_name}" />
    </div>
    <div class="col-lg-6">
        <label class="form-label" for="name">${t('Category')}</label>
        <input  class="form-control" id="name" type="text" oninput="${setCategory}" value="${category}" />
    </div>
    <div class="col-lg-6">
        <label class="form-label" for="name">${t('Regulatory Time Limit')}</label>
        <input  class="form-control" id="name" type="text" oninput="${setTimeLimit}" value="${time_limit}" />
    </div>
    <div class="col-12 d-flex justify-content-end">
        <button class="btn btn-primary" type="submit"> ${procedureType?.id ? t('Update') : t('Create')}</button>
    </div>
</form>`;
}


export function AddProcedureType() {
    return html`<div class="row g-0">
    <div>
        <div class="card mb-3">
            <div class="card-header">
                <h5 class="mb-0">${t('Add')} ${t('Procedure Type')}</h5>
            </div>
            <div class="card-body bg-light">
                <${ProcedureTypeForm} />
            </div>
        </div>
    </div>
</div>`;
}


export function ObjectsOverview(name: string, path: string, ObjectRow_: any, props: any) {

    const [session, dispatch] = useSession();

    const [proceduresTypes, setProceduresTypes] = useState<ProcedureType[]>([]);

    const [sort, setSort] = useState({ field: '', dir: {} as any });

    const onSort = (field: string) => {
        return () => {
            setSort({ field, dir: { [field]: sort.dir[field] == 'asc' ? 'desc' : 'asc' } });
        };
    };

    const page = getPage();

    const q = session.search.trim().toLowerCase();
    const dir = sort.dir[sort.field] == 'asc' ? 1 : -1;


    const onSearch = (q?: any) => {
        Api.search('/procedures-types', q || '', 1, {}, session.token).then((res) => {
            setProceduresTypes(res.data);
        });
    };

    useEffect(() => {
        onSearch();
        dispatch({ type: 'onSearch', data: onSearch });
    }, []);

    const onDelete = (object: any) => {
        setProceduresTypes(proceduresTypes.filter(it => it.id != object.id));
    }

    return html`<div class="card mb-3" id="customersTable"
    data-list='{"valueNames":["name","email","phone","address","joined"],"page":10,"pagination":true}'>
    <div class="card-header">
        <div class="row flex-between-center">
            <div class="col-4 col-sm-auto d-flex align-items-center pr-0">
                <h5 class="fs-0 mb-0 text-nowrap py-2 py-xl-0">${t(name)}</h5>
            </div>
            <div class="col-8 col-sm-auto text-right pl-2">
                <div id="table-customers-replace-element">
                    <a href="${path}-add" class="btn btn-falcon-default btn-sm" type="button"><span
                            class="fas fa-plus" data-fa-transform="shrink-3 down-2"></span><span
                            class="d-none d-sm-inline-block ml-1">${t('Add')}</span></a>
                </div>
            </div>
        </div>
    </div>
    <div class="card-body p-0" style="height:${window.innerHeight - 240}px;overflow:auto">
        <div class="table-responsive">
            <table class="table table-sm table-striped fs--1 mb-0">
                <thead class="bg-200 text-900">
                    <tr>
                        <th class="no align-middle white-space-nowrap py-2" onclick=${onSort('id')}
                            class="sort ${sort.dir.id}" style="width:10px" data-sort>
                            ${t('Procedure Type')}</th>
                        <th onclick=${onSort('name')}
                            class="sort pr-1 align-middle white-space-nowrap ${sort.dir.name}" data-sort>${t('Name')}</th>
                        <th class="sort align-middle white-space-nowrap desktop" >${t('Common Name')}</th>
                        <th class="align-middle no-sort"></th>
                    </tr>
                </thead>
                <tbody class="list" id="table-customers-body">
                    ${proceduresTypes.map(it => ObjectRow(path, it, onDelete))}
                    <br/>
        <br/>
                </tbody>
            </table>
        </div>
    </div>
    <${Pagination} count=${proceduresTypes.length} page=${page}/>
</div>`;
}

export const UpdateProcedureType = (props: any) => UpdateObject(ProcedureTypeForm, '/procedures-types', props, 'Procedure Type');
export const ProceduresTypesOverview = (props: any) => ObjectsOverview('Procedures Types', '/procedures-types', 'ProcedureTypeRow', props);




function diff(a: any, base: any) {
    const out: any = {};
    for (const key in a) {
        if (a[key] != base[key]) {
            out[key] = a[key];
        }
    }
    return out;
}


function NoValue(label: string) {
    return html`<p class="font-italic text-400 mb-0">${label}</p>`;
}

function Value({ label, value }: { label: string, value: string }) {
    return value ? (html`${value}`) : NoValue(label);
}

export function ProcedureTypeDetails({ id }: { id?: string }) {

    const [session, dispatch] = useSession();

    const [procedureType, setProcedureType] = useState<ProcedureType | undefined>();

    useEffect(() => {
        if (id) {
            Api.get('/procedures-types', id, session.token as any).then((data) => {
                setProcedureType(data);
            });
        }
    }, []);

    if (procedureType) {

        return html`<div class="card mb-3">
    <div class="card-header">
        <div class="row align-items-center">
            <div class="col">
                <h5 class="mb-0">#${procedureType.code}</h5>
            </div>

            <div class="col-auto"><a class="btn btn-falcon-default btn-sm" href="/procedures-types/${id}/edit">
                    <${FontAwesomeIcon} icon=${['fas', 'pencil-alt']} /> ${t('Update')}</a></div>
        </div>
    </div>
    <div class="card-body bg-light border-top">
        <div class="row">
            <div class="col-lg col-xxl-5">
                <div class="row">
                    <div class="col-5 col-sm-4">
                        <p class="font-weight-semi-bold mb-1">${t('Name')}</p>
                    </div>
                    <div class="col">${procedureType.name}</div>
                </div>
                <div class="row">
                    <div class="col-5 col-sm-4">
                        <p class="font-weight-semi-bold mb-1">${t('Common Name')}</p>
                    </div>
                    <div class="col">${procedureType.common_name}</div>
                </div>
            </div>
            <div class="col-lg col-xxl-5 mt-4 mt-lg-0 offset-xxl-1">
                <div class="row">
                    <div class="col-5 col-sm-4">
                        <p class="font-weight-semi-bold mb-1">${t('Category')}</p>
                    </div>
                    <div class="col">${procedureType.category}</div>
                </div>
                <div class="row">
                    <div class="col-5 col-sm-4">
                        <p class="font-weight-semi-bold mb-1">${t('Regulatory Time Limit')}</p>
                    </div>
                    <div class="col">${procedureType.time_limit ? procedureType.time_limit / 24 + ' ' + t('days') : ''}</div>
                </div>
            </div>
        </div>
    </div>
</div>
`;

    } else {
        return html`<div>User ${id} not found</div>`;
    }
}
