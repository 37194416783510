import { html } from "htm/preact";
import { generateId, getPage, Pagination, t, useInput } from "./core";
import { useContext, useEffect, useRef, useState } from "preact/hooks";

import Select from 'react-select'
import { citites, socialSecurityOptions } from "./data";

import { Api, debounce, filesStore, getDownloadUrl, merge, searchObjects, SessionContext, SFile, state, useSession } from "./store";
import { Billable } from "./shared/core";
import { route } from "preact-router";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CreatableSelect from 'react-select/creatable';
import { Drive } from "./products";
import { FileUpload, ObjectRow, UpdateObject } from "./crud";
import { IdentifierTypes } from "./entities";


// hello

const options = citites.map(it => ({ label: it.c + ', ' + it.g, value: it.id1 + '-' + it.id2 }));
const insuranceOptions = [{ label: 'Selectioner' }, { label: 'CNSS', value: 'cnss' }, { label: 'CNRPS', value: 'cnrps' }];

/*
function useForm(pairs:[string,string][],callback:any){

    const debounce = 
    const out:any[] = [];
    for(const pair of pairs) {
        const [state, setState] = useState(pair[1]);
        out.push([state, (ev: any) => {
            setState(ev.target.value);


            // dispatch({collection, type: 'mutate', id,  data: {[pair[0]]: ev.target.value}})
        }]);
    }
    return out;
}*/




/*
pub struct Contact {
    pub id: String,
    pub name: String,
    pub legal_status: String,
    pub address: Option<String>,
    pub postal_code: Option<String>,
    pub created_by: i64,
    pub created_at: NaiveDateTime,
    pub updated_at: NaiveDateTime,
    pub data: JsonMap,
}
*/

/*
    pub id: String,
    pub name: String,
    pub phone: String,
    pub email: Option<String>,
    pub address: Option<String>,
    pub postal_code: Option<String>,
    pub created_by: i64,
    pub created_at: NaiveDateTime,
    pub updated_at: NaiveDateTime,
    pub data: JsonMap,
    */

export interface Contact {
    id: number;
    identifier: string;
    identifier_type: string;
    identifier_date?: Date;
    name: string;
    phone: string;
    email?: string;
    address?: string;
    postal_code?: string;
    data: { [key: string]: string };
}

export interface NewContact {
    identifier: string;
    identifier_type: string;
    identifier_date?: string;
    name: string;
    phone: string;
    email?: string;
    address?: string;
    postal_code?: string;
    data: { [key: string]: string };
}


export const LegalStatus = [
    'EI', 'SUARL', 'SARL', 'SA'
]

export function ContactForm({ data: contactData, formRef, onContactAdded }: { data: Contact, formRef?: any, onContactAdded: any }) {


    const contact = contactData;

    console.log('contact', contact);

    const [identifier, setIdentifier] = useInput(contact?.identifier || '');
    const [identifier_type, setIdentifierType] = useState<any>(contact?.identifier_type ? IdentifierTypes.find(it => it.value == contact.identifier_type) : undefined);
    const [identifier_date, setIdentifierDate] = useInput(contact?.identifier_date);

    const [phone, setPhone] = useInput(contact?.phone);
    const [email, setEmail] = useInput(contact?.email);
    const [name, setName] = useInput(contact?.name || '');
    //    const [legal_status, setLegalStatus] = useInput(contact?.legal_status || '');
    const [address, setAddress] = useInput(contact?.address || '');
    const [postal_code, setPostalCode] = useInput(contact?.postal_code || '');
    const [data, setData] = useState(contact?.data || {});

    //    const [contacts, setContacts] = useState<any[]>([]);


    const [validated, setValidated] = useState(false);
    const [state, dispatch] = useSession();

    const onSubmit = async (ev: Event) => {
        ev.preventDefault();
        ev.stopPropagation();
        setValidated(true);
        if ((ev.target as any).checkValidity()) {


            const newContact: NewContact = { identifier, identifier_type: identifier_type?.value, identifier_date, name, email, phone, address, postal_code, data };

            if (contact?.id) {

                Api.patch('/contacts', contact.id, newContact, state.token)
                    .then((contactType) => {
                        toast.success(t('Contact updated successfully'));
                        route('/contacts/' + contactType.id);
                    });

            } else {
                Api.post('/contacts', newContact, state.token).then((contact) => {
                    toast.success(t('User added successfully'));
                    if (onContactAdded) {
                        onContactAdded(contact);
                    } else {
                        route('/contacts/' + contact.id);
                    }
                });
            }

        }

    };


    return html`<form ref=${formRef} id="contact-form" onsubmit="${onSubmit}" class="row g-3 needs-validation ${validated ? 'was-validated' : ''}" novalidate>
    <div class="col-lg-12">
        <label class="form-label" for="name">${t('Name')}</label>
        <input pattern=".{3,}" class="form-control" id="name" type="text" oninput="${setName}" value="${name}" required />
    </div>
    <div class="col-lg-6">
        <label class="form-label" for="name">${t('Unique Identifier')}</label>
        <input type="text" class="form-control" id="name" value=${identifier} onInput=${setIdentifier} required />
        <div class="invalid-feedback">${t('Unique Identifier')} ${t('is required')}</div>
    </div>
    <div class="col-lg-3">
        <label class="form-label" for="type">${t('Identifier Type')}</label>
        <${Select} value=${identifier_type}
        options=${IdentifierTypes} onChange=${setIdentifierType}
        className="rs form-control ${validated && (identifier_type ? 'is-valid' : 'is-invalid')}" />
    </div>
    <div class="col-lg-3">
        <label class="form-label" for="type">${t('Identifier Date')}</label>
        <input type="date"  value=${identifier_date} onInput=${setIdentifierDate} class="form-control" />
    </div>
    <div class="col-lg-6">
        <label class="form-label" for="phone">${t('Phone')}</label>
        <input pattern=".{3,}" class="form-control" id="phone" type="text" oninput="${setPhone}" value="${phone}" required />
    </div>
    <div class="col-lg-6">
        <label class="form-label" for="email">${t('Email')}</label>
        <input pattern=".{3,}" class="form-control" id="email" type="email" oninput="${setEmail}" value="${email}" required />
    </div>
    <div class="col-lg-6">
        <label class="form-label" for="address">${t('Address')}</label>
        <input pattern=".{3,}" class="form-control" id="address" type="text" oninput="${setAddress}" value="${address}" required />
    </div>
    <div class="col-lg-6">
        <label class="form-label" for="postal_code">${t('Postal Code')}</label>
        <input pattern=".{3,}" class="form-control" id="postal_code" type="text" oninput="${setPostalCode}" value="${postal_code}" required />
    </div>
    ${formRef ? '' : html`
    <div class="col-12 d-flex justify-content-end">
        <button id="contact-submit" class="btn btn-primary" type="submit"> ${contact?.id ? t('Update') : t('Create')}</button>
    </div>`}
</form>`;
}


export function AddContact() {
    return html`<div class="row g-0">
    <div>
        <div class="card mb-3">
            <div class="card-header">
                <h5 class="mb-0">${t('Add')} ${t('Contact')}</h5>
            </div>
            <div class="card-body bg-light">
                <${ContactForm} />
            </div>
        </div>
    </div>
</div>`;
}


export function ObjectsOverview(name: string, path: string, ObjectRow_: any, props: any) {

    const [session, dispatch] = useSession();


    const [objects, setObjects] = useState<Contact[]>([]);
    const [sort, setSort] = useState({ field: '', dir: {} as any });

    const onSort = (field: string) => {
        return () => {
            setSort({ field, dir: { [field]: sort.dir[field] == 'asc' ? 'desc' : 'asc' } });
        };
    };

    const page = getPage();

    const q = session.search.trim().toLowerCase();
    const dir = sort.dir[sort.field] == 'asc' ? 1 : -1;
    // const allUsers = searchObjects(session.users, q, sort, dir, page);
    // const users = allUsers.slice((page - 1) * 20, page * 20);


    const onSearch = (q?: any) => {
        Promise.all([
            Api.search(path, q || '', 1, {}, session.token),

        ]).then(([data]: { data: any[] }[]) => {

            setObjects(data.data);
        });
    };

    useEffect(() => {
        onSearch();
        dispatch({ type: 'onSearch', data: onSearch });
    }, []);



    const onDelete = (object: any) => {
        setObjects(objects.filter(it => it.id != object.id));
    }

    return html`<div class="card mb-3" id="customersTable"
    data-list='{"valueNames":["name","email","phone","address","joined"],"page":10,"pagination":true}'>
    <div class="card-header">
        <div class="row flex-between-center">
            <div class="col-4 col-sm-auto d-flex align-items-center pr-0">
                <h5 class="fs-0 mb-0 text-nowrap py-2 py-xl-0">${t(name)}</h5>
            </div>
            <div class="col-8 col-sm-auto text-right pl-2">
                <div id="table-customers-replace-element">
                    <a href="${path}-add" class="btn btn-falcon-default btn-sm" type="button"><span
                            class="fas fa-plus" data-fa-transform="shrink-3 down-2"></span><span
                            class="d-none d-sm-inline-block ml-1">${t('Add')}</span></a>
                </div>
            </div>
        </div>
    </div>
    <div class="card-body p-0" style="height:${window.innerHeight - 240}px;overflow:auto">
        <div class="table-responsive">
            <table class="table table-sm table-striped fs--1 mb-0">
                <thead class="bg-200 text-900">
                    <tr>
                        <th class="no align-middle white-space-nowrap py-2" onclick=${onSort('id')}
                            class="sort ${sort.dir.id}" style="width:10px" data-sort>
                            ${t('National Identification Number')}</th>
                        <th onclick=${onSort('name')}
                            class="sort pr-1 align-middle white-space-nowrap ${sort.dir.name}" data-sort>${t('Name')}</th>
                      <!--  <th onclick=${onSort('role')}
                            class="sort pr-1 align-middle white-space-nowrap ${sort.dir.role}" data-sort>Email
                        </th> -->
                        <th class="sort align-middle white-space-nowrap desktop" >${t('Phone')}</th>
                        <th class="sort pr-1 align-middle white-space-nowrap pl-5 desktop" data-sort="address"
                            style="min-width: 200px;">${t('Email')}</th>
                        <th class="align-middle no-sort"></th>
                    </tr>
                </thead>
                <tbody class="list" id="table-customers-body">
                    ${objects.map(it => ObjectRow(path, it, onDelete))}
                    <br/>
        <br/>
                </tbody>
            </table>
        </div>
    </div>
    <${Pagination} count=${objects.length} page=${page}/>
</div>`;
}

export const UpdateContact = (props: any) => UpdateObject(ContactForm, '/contacts', props);
export const ContactsOverview = (props: any) => ObjectsOverview('Contacts', '/contacts', ContactRow, props);

function diff(a: any, base: any) {
    const out: any = {};
    for (const key in a) {
        if (a[key] != base[key]) {
            out[key] = a[key];
        }
    }
    return out;
}

export function ContactRow(row: Contact) {

    const [open, setOpen] = useState(false);

    useEffect(() => {
        const handler = () => setOpen(false);
        document.addEventListener('click', handler, { passive: true });
        return () => {
            document.removeEventListener('click', handler);
        };
    }, []);

    const [state, dispatch] = useSession();

    function onDeleteClick(row: Contact) {
        dispatch({
            type: 'mutate',
            collection: 'users',
            id: row.id,
            data: { deletedAt: Date.now() },
        });
    };

    return html`<tr class="btn-reveal-trigger">
    <td class="no align-middle white-space-nowrap">${row.identifier}</td>
    <td class="name align-middle white-space-nowrap">
        <a href="/users/${row.id}">
            <h6>${row.name}</h6>
        </a>
    </td>
    <td class="phone align-middle white-space-nowrap desktop">${(row.roles || []).map(t).join(',')}</td>
    <td class="address align-middle white-space-nowrap pl-5 desktop">${t(row.status)}</td>
<!--    <td class="joined align-middle desktop">${row.status}</td> -->
    <td class="align-middle white-space-nowrap text-right actions-column">
        <div class="dropdown font-sans-serif">
            <button onclick=${(ev: any) => {
            ev.preventDefault();
            ev.stopImmediatePropagation();
            setOpen(!open);
        }} class="btn btn-link text-600 btn-sm dropdown-toggle btn-reveal"
                type="button"
                id="customer-dropdown-0" data-toggle="dropdown" data-boundary="window" aria-haspopup="true"
                aria-expanded="false">
                <${FontAwesomeIcon} icon=${['fas', 'ellipsis-h']} class="fs--1" /></button>
            <div class="dropdown-menu dropdown-menu-right border py-0 ${open ? 'show' : ''}"
                aria-labelledby="customer-dropdown-0">
                <div class="bg-white">
                    <!--<a class="dropdown-item" href="#!">Edit</a>-->
                    <a class="dropdown-item text-danger" onclick=${() => onDeleteClick(user)}>Delete</a></div>
            </div>
        </div>
    </td>
</tr>`;
}

function NoValue(label: string) {
    return html`<p class="font-italic text-400 mb-0">${label}</p>`;
}

function Value({ label, value }: { label: string, value: string }) {
    return value ? (html`${value}`) : NoValue(label);
}

export function ContactDetails({ id }: { id?: string }) {

    const [session, dispatch] = useSession();

    const [user, setUser] = useState<{ data: Contact } | undefined>();

    useEffect(() => {
        if (id) {
            Api.get('/contacts', id, session.token as any).then((data) => {
                setUser({ data });
            });
        }
    }, []);


    const setNotes = (ev: any) => dispatch({ type: 'mutate', collection: 'users', id, data: { notes: ev.target.value }, debounce: true });
    if (user) {

        return html`<div class="card mb-3">
    <div class="card-header">
        <div class="row align-items-center">
            <div class="col">
                <h5 class="mb-0">#${user.data.name}</h5>
            </div>

            <div class="col-auto"><a class="btn btn-falcon-default btn-sm" href="/contacts/${id}/edit">
                    <${FontAwesomeIcon} icon=${['fas', 'pencil-alt']} /> Mettre à jour les détails</a></div>
        </div>
    </div>
    <div class="card-body bg-light border-top">
        <div class="row">
            <div class="col-lg col-xxl-5">
                <div class="row">
                    <div class="col-5 col-sm-4">
                        <p class="font-weight-semi-bold mb-1">${t('Unique Identifier')}</p>
                    </div>
                    <div class="col">${IdentifierTypes.find(it => it.value == user.data.identifier_type)?.label} :  ${user.data.identifier}</div>
                </div>
                <div class="row">
                    <div class="col-5 col-sm-4">
                        <p class="font-weight-semi-bold mb-1">${t('Name')}</p>
                    </div>
                    <div class="col">${user.data.name}</div>
                </div>
                <div class="row">
                    <div class="col-5 col-sm-4">
                        <p class="font-weight-semi-bold mb-0">Addresse</p>
                    </div>
                    <div class="col">
                        ${user.data.address} - ${user.data.postal_code}
                    </div>
                </div>
            </div>
            <div class="col-lg col-xxl-5 mt-4 mt-lg-0 offset-xxl-1">
                <div class="row">
                    <div class="col-5 col-sm-4">
                        <p class="font-weight-semi-bold mb-1">Email</p>
                    </div>
                    <div class="col">${user.data.email}</div>
                </div>
                <div class="row">
                    <div class="col-5 col-sm-4">
                        <p class="font-weight-semi-bold mb-1">Téléphone</p>
                    </div>
                    <div class="col">
                        <${Value} label="Pas de téléphone" value=${user.data.phone} />
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
`;

    } else {
        return html`<div>User ${id} not found</div>`;
    }
}
