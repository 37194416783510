import { html } from "htm/preact";
import { generateId, getPage, Pagination, t, useInput } from "./core";
import { useContext, useEffect, useRef, useState } from "preact/hooks";

import Select from 'react-select'
import { citites, socialSecurityOptions } from "./data";

import { Api, debounce, filesStore, getDownloadUrl, merge, searchObjects, SessionContext, SFile, state, useSession } from "./store";
import { Billable } from "./shared/core";
import { route } from "preact-router";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CreatableSelect from 'react-select/creatable';
import { Drive } from "./products";
import { ObjectsOverview, UpdateObject } from "./crud";

// hello

const options = citites.map(it => ({ label: it.c + ', ' + it.g, value: it.id1 + '-' + it.id2 }));
const insuranceOptions = [{ label: 'Selectioner' }, { label: 'CNSS', value: 'cnss' }, { label: 'CNRPS', value: 'cnrps' }];

/*
function useForm(pairs:[string,string][],callback:any){

    const debounce = 
    const out:any[] = [];
    for(const pair of pairs) {
        const [state, setState] = useState(pair[1]);
        out.push([state, (ev: any) => {
            setState(ev.target.value);


            // dispatch({collection, type: 'mutate', id,  data: {[pair[0]]: ev.target.value}})
        }]);
    }
    return out;
}*/


export function FileUpload() {

    return html`<form class="dropzone dropzone-multiple p-0" id="my-awesome-dropzone" data-dropzone="data-dropzone" action="#!">
    <div class="fallback"><input name="file" type="file" multiple="multiple" /></div>
    <div class="dz-message" data-dz-message="data-dz-message"> <img class="me-2" src="../../../assets/img/icons/cloud-upload.svg" width="25" alt="" />Drop your files here</div>
    <div class="dz-preview dz-preview-multiple m-0 d-flex flex-column">
      <div class="d-flex media mb-3 pb-3 border-bottom btn-reveal-trigger"><img class="dz-image" src="../../../assets/img/generic/image-file-2.png" alt="..." data-dz-thumbnail="data-dz-thumbnail" />
        <div class="flex-1 d-flex flex-between-center">
          <div>
            <h6 data-dz-name="data-dz-name"></h6>
            <div class="d-flex align-items-center">
              <p class="mb-0 fs-10 text-400 lh-1" data-dz-size="data-dz-size"></p>
              <div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress=""></span></div>
            </div><span class="fs-11 text-danger" data-dz-errormessage="data-dz-errormessage"></span>
          </div>
          <div class="dropdown font-sans-serif"><button class="btn btn-link text-600 btn-sm dropdown-toggle btn-reveal dropdown-caret-none" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><span class="fas fa-ellipsis-h"></span></button>
            <div class="dropdown-menu dropdown-menu-end border py-2"><a class="dropdown-item" href="#!" data-dz-remove="data-dz-remove">Remove File</a></div>
          </div>
        </div>
      </div>
    </div>
  </form>`;
}

export interface Activity {
    id: number;
    code: string;
    name: string;
    data: { [key: string]: string };
}

export function ActivityForm({ activity, formRef, onActivityAdded }: { activity?: Activity, formRef: any, onActivityAdded: any }) {

    const [name, setName] = useInput(activity?.name || '');

    const [validated, setValidated] = useState(false);
    const [state, dispatch] = useSession();



    const onSubmit = async (ev: Event) => {
        ev.preventDefault();
        ev.stopPropagation();
        setValidated(true);
        if ((ev.target as any).checkValidity()) {


            const data: any = { name };


            if (activity?.id) {

                Api.patch('/activities', activity.id, diff(data, activity), state.token)
                    .then((activity) => {
                        toast.success(t('Activity updated successfully'));
                        route('/activities/' + activity.id);
                    });

            } else {
                Api.post('/activities', data, state.token).then((activity) => {

                    toast.success(t('Activity added successfully'));

                    if (onActivityAdded) {
                        onActivityAdded(activity);
                    } else {
                        route('/activities/' + activity.id);
                    }
                });
            }

        }

    };

    return html`<form  ref=${formRef} onsubmit="${onSubmit}" class="row g-3 needs-validation ${validated ? 'was-validated' : ''}" novalidate>
    <div class="col-lg-6">
        <label class="form-label" for="name">${t('Name')}</label>
        <input required pattern=".{3,}" class="form-control" id="name" type="text" oninput="${setName}"
            value="${name}" />
    </div>
    <div class="col-12 d-flex justify-content-end">
        <button class="btn btn-primary" type="submit"> ${activity?.id ? t('Update') : t('Create')}</button>
    </div>
</form>`;
}


export function AddActivity() {
    return html`<div class="row g-0">
    <div>
        <div class="card mb-3">
            <div class="card-header">
                <h5 class="mb-0">Ajouter User</h5>
            </div>
            <div class="card-body bg-light">
                <${ActivityForm} />
            </div>
        </div>
    </div>
    <div>
        <div class="card mb-3">
        <div class="card-body bg-light">
            <${FileUpload} />
            </div>

        </div>
    </div>
</div>`;
}



export const UpdateActivity = (props: any) => UpdateObject(ActivityForm, '/activities', props);
export const ProceduresTypesOverview = (props: any) => ObjectsOverview('Procedures Types', '/activities', ActivityRow, props);
export const ActivityDetails = (props: any) => { return html`TODO`; };

function diff(a: any, base: any) {
    const out: any = {};
    for (const key in a) {
        if (a[key] != base[key]) {
            out[key] = a[key];
        }
    }
    return out;
}

export function ActivityRow(user: Activity) {

    const [open, setOpen] = useState(false);

    useEffect(() => {
        const handler = () => setOpen(false);
        document.addEventListener('click', handler, { passive: true });
        return () => {
            document.removeEventListener('click', handler);
        };
    }, []);

    const [state, dispatch] = useSession();

    function onDeleteClick(user: Activity) {
        dispatch({
            type: 'mutate',
            collection: 'users',
            id: user.id,
            data: { deletedAt: Date.now() },
        });
    };

    return html`<tr class="btn-reveal-trigger">
    <td class="no align-middle white-space-nowrap">${user.identifier}</td>
    <td class="name align-middle white-space-nowrap">
        <a href="/users/${user.id}">
            <h6>${user.name}</h6>
        </a>
    </td>
    <td class="phone align-middle white-space-nowrap desktop">${(user.roles || []).map(t).join(',')}</td>
    <td class="address align-middle white-space-nowrap pl-5 desktop">${t(user.status)}</td>
<!--    <td class="joined align-middle desktop">${user.status}</td> -->
    <td class="align-middle white-space-nowrap text-right actions-column">
        <div class="dropdown font-sans-serif">
            <button onclick=${(ev: any) => {
            ev.preventDefault();
            ev.stopImmediatePropagation();
            setOpen(!open);
        }} class="btn btn-link text-600 btn-sm dropdown-toggle btn-reveal"
                type="button"
                id="customer-dropdown-0" data-toggle="dropdown" data-boundary="window" aria-haspopup="true"
                aria-expanded="false">
                <${FontAwesomeIcon} icon=${['fas', 'ellipsis-h']} class="fs--1" /></button>
            <div class="dropdown-menu dropdown-menu-right border py-0 ${open ? 'show' : ''}"
                aria-labelledby="customer-dropdown-0">
                <div class="bg-white">
                    <!--<a class="dropdown-item" href="#!">Edit</a>-->
                    <a class="dropdown-item text-danger" onclick=${() => onDeleteClick(user)}>Delete</a></div>
            </div>
        </div>
    </td>
</tr>`;
}

function NoValue(label: string) {
    return html`<p class="font-italic text-400 mb-0">${label}</p>`;
}

function Value({ label, value }: { label: string, value: string }) {
    return value ? (html`${value}`) : NoValue(label);
}

export function UserDetails({ id }: { id?: string }) {

    const [session, dispatch] = useSession();

    const [user, setUser] = useState<User | undefined>();

    useEffect(() => {
        if (id) {
            Api.get('/users', id, session.token as any).then((data) => {
                setUser(data);
            });
        }
    }, []);
    /*
    const saveNotes:any = useRef(debounce(function(notes:string){
        dispatch({type:'mutate',collection: 'users',id, data:{notes} });
    },4000)).current;
*/
    const setNotes = (ev: any) => dispatch({ type: 'mutate', collection: 'users', id, data: { notes: ev.target.value }, debounce: true });
    if (user) {

        return html`<div class="card mb-3">
    <div class="card-header">
        <div class="row align-items-center">
            <div class="col">
                <h5 class="mb-0">#${user.identifier}</h5>
            </div>

            <div class="col-auto"><a class="btn btn-falcon-default btn-sm" href="/users/${id}/edit">
                    <${FontAwesomeIcon} icon=${['fas', 'pencil-alt']} /> Mettre à jour les détails</a></div>
        </div>
    </div>
    <div class="card-body bg-light border-top">
        <div class="row">
            <div class="col-lg col-xxl-5">
                <div class="row">
                    <div class="col-5 col-sm-4">
                        <p class="font-weight-semi-bold mb-1">${t('Name')}</p>
                    </div>
                    <div class="col">${user.name}</div>
                </div>
                <div class="row">
                    <div class="col-5 col-sm-4">
                        <p class="font-weight-semi-bold mb-0">Addresse</p>
                    </div>
                    <div class="col">
                        <${Value} label="Pas d'addresse" value=${user.address || user.city?.label} />
                    </div>
                </div>
            </div>
            <div class="col-lg col-xxl-5 mt-4 mt-lg-0 offset-xxl-1">
                <div class="row">
                    <div class="col-5 col-sm-4">
                        <p class="font-weight-semi-bold mb-1">Email</p>
                    </div>
                    <div class="col">${user.email}</div>
                </div>
                <div class="row">
                    <div class="col-5 col-sm-4">
                        <p class="font-weight-semi-bold mb-1">Téléphone</p>
                    </div>
                    <div class="col">
                        <${Value} label="Pas de téléphone" value=${user.phones?.join(';')} />
                    </div>
                </div>
                <div class="row">
                    <div class="col-5 col-sm-4">
                        <p class="font-weight-semi-bold mb-1">${t('Roles')}</p>
                    </div>
                    <div class="col">${user.roles?.map(t).join(', ')}</div>
                </div>
            </div>
        </div>
    </div>
</div>
`;

    } else {
        return html`<div>User ${id} not found</div>`;
    }
}
