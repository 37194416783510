import { html } from "htm/preact";


export function Checkbox({ label, set, value }: { label: string, set: (val: boolean) => void, value: boolean }) {
    return html`<div class="form-check form-check-inline">
    <input class="form-check-input" type="checkbox" value=${value} onclick=${(ev: any) => {
        set(ev.target.checked);
    }}/>
    <label class="form-check-label">
        ${label}
    </label>
</div>`;
}