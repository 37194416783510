import { IsEmail, IsMongoId, IsObject, MinLength } from "class-validator";
import { Mixin, decorate } from "ts-mixer";

export async function randomString(length = 9) {
    const array = new Uint8Array(length);
    await crypto.getRandomValues(array);
    return btoa(String.fromCharCode(...array));
}


export class Id {

    @decorate(IsMongoId())
    _id!: string;

    @decorate(IsObject())
    timestamps!: { [key: string]: number };
}

export class Offline {
    @decorate(IsObject())
    timestamps!: { [key: string]: number };
}

export class LoginForm {

    @decorate(IsEmail())
    email!: string;

    @decorate(MinLength(6))
    password!: string;
}


export class Person {

    @decorate(MinLength(4))
    firstName!: string;

    @decorate(MinLength(4))
    lastName!: string;


    birthdate?: string;
    gender?: 'male' | 'female';
}

export class RegisterForm {
    user!: UserRegisterForm;
    organization?: OrganizationForm;
}

export class UserRegisterForm extends Mixin(LoginForm, Person) {

}

export class LoginResponse {
    token!: string;
    user!: User;
}

export class OrganizationForm {
    name!: string;
}

export interface User {
    id: number;
    identifier: string;
    name: string;
    roles: string[];
    status: string;
}

export class Contact {


    email?: string;

    phones?: string[];

    address?: string;

    city?: { label: string, value: string };

    country?: string;
}


export class Stock {

}

export class Product {
    _id!: string;
    name!: string;
    price!: number;
    sku!: string;
    description!: string;

    images!: string[];

    stock!: { [key: string]: Stock };
}


export class NewPatient extends Mixin(Person, Contact) {
    notes?: string;
    insuranceId?: string;
    insuranceDate?: string;
    insurance?: { label: string, value: string };
    bloodType?: string;
}

export class Patient extends Mixin(Id, NewPatient) {
    no?: number;
}


export interface Mutation {
    _id?: string;
    collection: string;
    objectId: string;
    data: any;
    time: number;
    organizationId?: string;
}

export class NewVisit {
    patientId!: string;
    price!: number;
    received!: number;
}

export class Billable extends Mixin(Id) {
    patientId!: string;
    date!: string;
    description!: string;
    price!: number;
    received!: number;

    prescriptions?: Prescription[];
}


export class Prescription extends Mixin(Id) {
    patientId!: string;
    date!: string;

    insurance?: string;
    insuranceId?: string;
    lines!: { drug: any, posology: any, note: any }[];
    deletedAt?: number;

}

/*
export class Visit extends Mixin(Id, NewVisit) { }

export class VisitProcedure extends Mixin(Id) {
    visit_id!: string;
    sub_procedure_id: String,
    name: String,
    price: i32,         // indicatif
    tooth: Option<i32>, // after use ??
    created_at: NaiveDateTime,
    updated_at: NaiveDateTime,
}
*/



export function to32(view: Uint8Array): string {

    const CHARS = '0123456789ABCDEFGHJKLMNPRSTVWXYZ';

    const length = view.byteLength;

    var bits = 0
    var value = 0
    var output = ''

    for (var i = 0; i < length; i++) {
        value = (value << 8) | view[i]
        bits += 8

        while (bits >= 5) {
            output += CHARS[(value >>> (bits - 5)) & 31]
            bits -= 5
        }
    }

    if (bits > 0) {
        output += CHARS[(value << (5 - bits)) & 31]
    }


    return output
}

export class PurchaseOrderItem {
    _id!: string;
    ref!: string;
    name?: string;
    form?: string;
    requestedQuantity!: number;
    deliveredQuantity?: number;
}

export class PurchaseOrder {
    _id!: string;
    no!: number;
    organizationId!: string;
    items: PurchaseOrderItem[] = [];
    createdBy?: string;
    receivedBy?: string;
    deletedAt: any;
    status: any;
    date: any;
    createdAt!: number;
    createdByName!: string;
    receivedAt?: number;
}


export interface SavedFile {
    _id: string;
    name: string;
    type: "image/jpeg",
    size: number,
    patientId?: string;
    timestamps: {
        "_id": number,
    },
    organizationId: string;
    uploaded: true
}


export module base58 {
    function bytesToHex(uint8a: Uint8Array) {
        // pre-caching chars could speed this up 6x.
        let hex = '';
        for (let i = 0; i < uint8a.length; i++) {
            hex += uint8a[i].toString(16).padStart(2, '0');
        }
        return hex;
    }

    interface Alphabets {
        ipfs: string;
        btc: string;
        flickr: string;
        xmr: string;
        xrp: string;
    }

    const COMMON_B58_ALPHABET = '123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz';

    const alphabet: Alphabets = {
        ipfs: COMMON_B58_ALPHABET,
        btc: COMMON_B58_ALPHABET,
        xmr: COMMON_B58_ALPHABET,
        flickr: '123456789abcdefghijkmnopqrstuvwxyzABCDEFGHJKLMNPQRSTUVWXYZ',
        xrp: 'rpshnaf39wBUDNEGHJKLM4PQRST7VWXYZ2bcdeCg65jkm8oFqi1tuvAxyz'
    };

    export function encode(source: string | Uint8Array, type: keyof Alphabets = 'ipfs') {
        if (source.length === 0) return '';
        if (typeof source === 'string') {
            if (typeof TextEncoder !== 'undefined') {
                source = new TextEncoder().encode(source);
            } else {
                // note: only supports ASCII
                source = new Uint8Array(source.split('').map(c => c.charCodeAt(0)));
            }
        }
        type = type.toLowerCase() as keyof Alphabets;

        if (type === 'xmr') {
            // xmr ver is done in 8-byte blocks.
            // This gives us eight full-sized blocks and one 5-byte block.
            // Eight bytes converts to 11 or less Base58 characters;
            // if a particular block converts to <11 characters,
            // the conversion pads it with "1"s (1 is 0 in Base58).
            // Likewise, the final 5-byte block can convert to 7 or less Base58 digits;
            // the conversion will ensure the result is 7 digits. Due to the conditional padding,
            // the 69-byte string will always convert to 95 Base58 characters (8 * 11 + 7).
            let res = '';
            for (let i = 0; i < source.length + 3; i += 8) {
                const slice = source.slice(i, i + 8);
                res += encode(slice).padStart(slice.length === 8 ? 11 : 7, '1');
            }
            return res;
        }
        if (!alphabet.hasOwnProperty(type)) throw new Error('invalid type');
        const letters = alphabet[type];

        // Convert Uint8Array to BigInt, Big Endian.
        let x = BigInt('0x' + bytesToHex(source));
        let output: string[] = [];

        while (x > 0) {
            const mod = Number(x % BigInt(58));
            x = x / BigInt(58);
            output.push(letters[mod]);
        }

        for (let i = 0; source[i] === 0; i++) {
            output.push(letters[0]);
        }

        return output.reverse().join('');
    }


    export function random(length = 10) {
        var array = new Uint8Array(length);
        window.crypto.getRandomValues(array);
        const res = encode(array);
        return res.substr(0, length);
    }

}