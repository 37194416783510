import { html } from "htm/preact";
import { generateId, getPage, Pagination, t, useInput } from "./core";
import { useContext, useEffect, useRef, useState } from "preact/hooks";

import Select from 'react-select'
import { citites, socialSecurityOptions } from "./data";

import { Api, debounce, filesStore, getDownloadUrl, merge, searchObjects, SessionContext, SFile, state, useSession } from "./store";
import { Billable } from "./shared/core";
import { route } from "preact-router";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CreatableSelect from 'react-select/creatable';
import { Drive } from "./products";
import { FileUpload, ObjectRow, UpdateObject } from "./crud";
import AsyncCreatableSelect from "react-select/async-creatable";
import { Button, Modal } from "react-bootstrap";
import { ActivityForm } from "./activites";
import { Contact } from "./contacts";

// hello

const options = citites.map(it => ({ label: it.c + ', ' + it.g, value: it.id1 + '-' + it.id2 }));
const insuranceOptions = [{ label: 'Selectioner' }, { label: 'CNSS', value: 'cnss' }, { label: 'CNRPS', value: 'cnrps' }];

/*
function useForm(pairs:[string,string][],callback:any){

    const debounce = 
    const out:any[] = [];
    for(const pair of pairs) {
        const [state, setState] = useState(pair[1]);
        out.push([state, (ev: any) => {
            setState(ev.target.value);


            // dispatch({collection, type: 'mutate', id,  data: {[pair[0]]: ev.target.value}})
        }]);
    }
    return out;
}*/




/*
pub struct Entity {
    pub id: String,
    pub name: String,
    pub legal_status: String,
    pub address: Option<String>,
    pub postal_code: Option<String>,
    pub created_by: i64,
    pub created_at: NaiveDateTime,
    pub updated_at: NaiveDateTime,
    pub data: JsonMap,
}
*/

export interface Entity {
    secondary_activity_name: any;
    main_activity_name: any;
    id: number;
    identifier: string;
    identifier_type: string;
    identifier_date?: string;
    name?: string;
    name_ar?: string;
    commercial_name?: string;
    commercial_name_ar?: string;
    legal_status: string;
    address: string;
    postal_code: number;
    capital?: number;
    created_by: number;
    created_at: Date;
    updated_at: Date;
    data: { [key: string]: string };
    category_code: string;
    vat_code: string;
}

export interface NewEntity {
    identifier: string;
    identifier_type: string;
    identifier_date?: string;
    name: string;
    legal_status?: string;
    address: string;
    postal_code?: number;
    main_activity: number;
    secondary_activity?: number;
    category_code?: string;
    vat_code?: string;
    data: { [key: string]: string };
}


export const LegalStatus = `10,AUTRES SOCIETES DE PERSONNES
11,SOCIETE EN NON COLLECTIF
12,SOCIETE EN COMMANDITE SIMPLE
13,SOCIETE DE FAIT
14,GROUPEMENT D'INTERET ECONOMIQUE
20,AUTRES SOCIETES DE CAPITAUX
21,SOCIETE ANONYME
22,SOCIETE A RESPONSABILITE LIMITEE
23,SUARL (PP associée unique)
24,SUARL (PM associée unique)
29,ETABLISSEMENT STABLE
30,COOPERATIVE
31,"Coopérative de services, /commercialisation des récoltes agricoles ou de pêche opérant dans l’enceinte des marchés de gros"
32,Coopératives de services agricoles et de pêche
33,Coopératives ouvrières de production
35,ASSURANCE MUTUELLE
40,ETABL. PUB. IND. et COMM (Y COMPRISLES OFFICES
41,SOCIETES Nle et SOCIETES A PARTICIPATION PUBLIQUE
60,ADMINISTRATION CENTRALE
62,ADMINISTRATION REGIONALE
64,COLLECTIVITE LOCALE
66,ETABLISSEMENT PUB. ADMIN.
70,ASSOCIATION
72,MUTUELLE
80,ORGANISATION POLITIQUE
82,ORGANISATION NATIONALE
83,INSTANCE CONSTITUTIONNELLE
84,ORGANISATION INTERNATIONALE
86,AMBASSADE
90,AUTRES FORMES JURIDIQUES
97,PERSONNE PHYSIQUE EXERCANT EXCLUSIVEMENT UNA ACTIVITE AGRICOLE ET DE PECHE
98,SOCIETES DE FAIT ASSIMILEES A UNE PERSONNE PHYSIQUE
99,PERSONNE PHYSIQUE`.split('\n').map(it => {
    const [value, label] = it.split(',');
    return { value, label: `${value} - ${label}` };
});

const CategoryCodes = [
    { value: 'M', label: 'M - Personne Morale' },
    { value: 'P', label: 'P - Liberale' },
    { value: 'C', label: 'C - Autre personne physique' },
    { value: 'A', label: 'A - Agricole' },
    { value: 'N', label: 'N - Autres' },
];

const VatCodes = [
    { value: 'A', label: 'A - Assujetti Obligatoire' },
    { value: 'P', label: 'P - Assujetti Partiel' },
    { value: 'B', label: 'B - Assujetti par option' },
    { value: 'N', label: 'N - Non Assujeti' },
    { value: 'D', label: 'D - Assujetti Partiel par option' }

];


export const IdentifierTypes = [
    { value: 'I', label: 'CIN' },
    { value: 'T', label: 'Matricule Fiscale' },
    { value: 'P', label: 'Passeport' },
    { value: 'O', label: 'Autre' },
];

export function EntityForm({ data: object, formRef, onEntityAdded }: { data?: Entity, formRef: any, onEntityAdded: any }) {


    const entity = object?.data;

    const [identifier, setIdentifier] = useInput(entity?.identifier || '');
    const [identifier_type, setIdentifierType] = useState<any>(entity?.identifier_type ? IdentifierTypes.find(it => it.value == entity?.identifier_type) : undefined);
    const [identifier_date, setIdentifierDate] = useInput(entity?.identifier_date);


    const [name, setName] = useInput(entity?.name || '');
    const [legal_status, setLegalStatus] = useState(LegalStatus.find(it => it.value == entity?.legal_status));
    const [address, setAddress] = useInput(entity?.address || '');
    const [postal_code, setPostalCode] = useInput(entity?.postal_code || '');
    const [mainActivity, setMainActivity] = useState<any>();
    const [secondaryActivity, setSecondaryActivity] = useState<any>();
    const [data, setData] = useState(entity?.data || {});
    const [categoryCode, setCategoryCode] = useState(CategoryCodes.find(it => it.value == entity?.category_code));
    const [vatCode, setVatCode] = useState(VatCodes.find(it => it.value == entity?.vat_code));
    const [showActivity, setShowActivity] = useState(false);

    const [entities, setEntities] = useState<any[]>([]);


    const [validated, setValidated] = useState(false);
    const [state, dispatch] = useSession();

    const activityFormRef = useRef(null);


    const onSubmit = async (ev: Event) => {
        ev.preventDefault();
        ev.stopPropagation();
        setValidated(true);
        if ((ev.target as any).checkValidity()) {

            if (!identifier_type) {
                return;
            }

            const newEntity: NewEntity = {
                identifier,
                identifier_type: identifier_type?.value,
                identifier_date,
                name,
                vat_code: vatCode?.value,
                category_code: categoryCode?.value,
                main_activity: mainActivity.value, secondary_activity: secondaryActivity?.value, legal_status: (legal_status as any).value, address,
                postal_code: postal_code ? parseInt(postal_code) : undefined,
                data
            };


            if (entity?.id) {

                Api.patch('/entities', entity.id, newEntity, state.token)
                    .then((entity) => {
                        toast.success(t('Entity updated successfully'));
                        route('/entities/' + entity.id);
                    });

            } else {
                Api.post('/entities', newEntity, state.token).then((entity) => {
                    toast.success(t('Entity added successfully'));
                    if (onEntityAdded) {
                        onEntityAdded(entity);
                    } else {
                        route('/entities/' + entity.id);
                    }
                });
            }

        }

    };

    const [activityType, setActivityType] = useState<any>();

    const handleCreateMainActivity = (inputValue: string) => {
        setActivityType('main');
        setShowActivity(true);
    };

    const handleCreateSecondaryActivity = (inputValue: string) => {
        setActivityType('secondary');
        setShowActivity(true);
    }

    const onActivityAdded = (activity: any) => {
        if (activityType === 'main') {
            setMainActivity({ label: activity.name, value: activity.id });
        } else {
            setSecondaryActivity({ label: activity.name, value: activity.id });
        }
        setShowActivity(false);
    }

    const loadOptionsActivites = (inputValue: string) =>
        Api.search('/activites', inputValue, 1, {}, state.token).then((res) => {
            return res.data.map((it: any) => ({ label: it.name, value: it.id }));
        });



    return html`<form ref=${formRef} id="entity-form" onsubmit="${onSubmit}" class="row g-3 needs-validation ${validated ? 'was-validated' : ''}" novalidate>
    <div class="col-lg-12">
        <label class="form-label" for="name">${t('Name/Company Name')}</label>
        <input type="text" class="form-control" id="name" value=${name} onInput=${setName} required />
        <div class="invalid-feedback">${t('Name is required')}</div>
    </div>
    <div class="col-lg-6">
        <label class="form-label" for="name">${t('Unique Identifier')}</label>
        <input type="text" class="form-control" id="name" value=${identifier} onInput=${setIdentifier} required />
        <div class="invalid-feedback">${t('Unique Identifier')} ${t('is required')}</div>
    </div>
    <div class="col-lg-3">
        <label class="form-label" for="type">${t('Identifier Type')}</label>
        <${Select} value=${identifier_type}
        options=${IdentifierTypes} onChange=${setIdentifierType}
        className="rs form-control ${validated && (identifier_type ? 'is-valid' : 'is-invalid')}" />
    </div>
    <div class="col-lg-3">
        <label class="form-label" for="type">${t('Identifier Date')}</label>
        <input type="date"  value=${identifier_date} onInput=${setIdentifierDate} class="form-control" />
    </div>
    <!--
    <div class="col-lg-6">
    <label class="form-label" for="type">${t('Legal Status')}</label>
    <${Select} value=${legal_status}
    options=${LegalStatus} onChange=${setLegalStatus}
    className="rs form-control ${validated && (legal_status ? 'is-valid' : 'is-invalid')}" />
</div>
<div class="col-lg-6">
    <label class="form-label" for="type">${t('Vat Code')}</label>
    <${Select} value=${vatCode}
    options=${VatCodes} onChange=${setVatCode}
    className="rs form-control ${validated && (vatCode ? 'is-valid' : 'is-invalid')}" />
</div>
<div class="col-lg-6">
    <label class="form-label" for="type">${t('Category Code')}</label>
    <${Select}  value=${categoryCode}
    options=${CategoryCodes} onChange=${setCategoryCode}
    className="rs form-control ${validated && (categoryCode ? 'is-valid' : 'is-invalid')}" />
</div>
-->

<div class="col-lg-6">
<label class="form-label" for="email3">${t('Main')} ${t('Activity')}</label>
<${AsyncCreatableSelect} 
    getOptionLabel=${option => option.__isNew__ ? t('Add') + ` '${option.value}'` : option.label} cacheOptions defaultOptions
isClearable onCreateOption=${handleCreateMainActivity} loadOptions=${loadOptionsActivites}  value=${mainActivity} onChange="${setMainActivity}"
    className="rs form-control ${validated && (mainActivity ? 'is-valid' : 'is-invalid')}" />
</div>

<div class="col-lg-6">
<label class="form-label" for="email3">${t('Secondary')} ${t('Activity')}</label>
<${AsyncCreatableSelect}
getOptionLabel=${option => option.__isNew__ ? t('Add') + ` '${option.value}'` : option.label} cacheOptions defaultOptions
 isClearable onCreateOption=${handleCreateSecondaryActivity} loadOptions=${loadOptionsActivites} value=${secondaryActivity} onChange="${setSecondaryActivity}"
    className="rs form-control" />
</div>


    <div class="col-lg-6">

        <label class="form-label" for="address">${t('Address')}</label>
        <input type="text" class="form-control" id="address" value=${address} onInput=${setAddress} required />
        <div class="invalid-feedback">${t('Address is required')}</div>
    </div>
    <!--<div class="col-lg-6">
        <label class="form-label" for="postal_code">${t('Postal Code')}</label>
        <input type="text" class="form-control" id="postal_code" value=${postal_code} onInput=${setPostalCode} required />
        <div class="invalid-feedback">${t('Postal Code is required')}</div>
    </div>-->

    ${formRef ? '' : html`
    <div class="col-12 d-flex justify-content-end">
        <button id="entity-submit" class="btn btn-primary" type="submit"> ${entity?.id ? t('Update') : t('Create')}</button>
    </div>`}
</form>

<${Modal}
show=${showActivity}
onHide=${() => setShowActivity(false)}
backdrop="static"
keyboard=${false}
>
<${Modal.Header} closeButton>
  <${Modal.Title}>${t('Add')} ${t('Activity')}</Modal.Title>
</${Modal.Header}>
<${Modal.Body}>
  <${ActivityForm} formRef=${activityFormRef} onActivityAdded=${onActivityAdded}/>
</${Modal.Body}>
<${Modal.Footer}>
  <${Button} variant="secondary" onClick=${() => setShowActivity(false)}>
    Close
    </${Button}>
  <${Button} variant="primary" onClick=${() => (activityFormRef.current as any).dispatchEvent(new Event('submit'))} >${t('Add')}</${Button}>
</${Modal.Footer}>
</${Modal}>

`;
}


export function AddEntity() {
    return html`<div class="row g-0">
    <div>
        <div class="card mb-3">
            <div class="card-header">
                <h5 class="mb-0">${t('Add')} ${t('Taxable Entity')}</h5>
            </div>
            <div class="card-body bg-light">
                <${EntityForm} />
            </div>
        </div>
    </div>

</div>`;
}


export function ObjectsOverview(name: string, path: string, ObjectRow_: any, props: any) {

    const [session, dispatch] = useSession();

    const [entitiesTypes, setEntitiesTypes] = useState<Entity[]>([]);

    const [objects, setObjects] = useState<Entity[]>([]);
    const [sort, setSort] = useState({ field: '', dir: {} as any });

    const onSort = (field: string) => {
        return () => {
            setSort({ field, dir: { [field]: sort.dir[field] == 'asc' ? 'desc' : 'asc' } });
        };
    };

    const page = getPage();

    const q = session.search.trim().toLowerCase();
    const dir = sort.dir[sort.field] == 'asc' ? 1 : -1;
    // const allUsers = searchObjects(session.users, q, sort, dir, page);
    // const users = allUsers.slice((page - 1) * 20, page * 20);


    const onSearch = (q?: any) => {
        Promise.all([
            Api.search(path, q || '', 1, {}, session.token),

        ]).then(([data]: { data: any[] }[]) => {
            setObjects(data.data);
        });
    };

    useEffect(() => {
        onSearch();
        dispatch({ type: 'onSearch', data: onSearch });
    }, []);


    const onDelete = (object: any) => {
        setObjects(objects.filter(it => it.id != object.id));
    }

    return html`<div class="card mb-3" id="customersTable"
    data-list='{"valueNames":["name","email","phone","address","joined"],"page":10,"pagination":true}'>
    <div class="card-header">
        <div class="row flex-between-center">
            <div class="col-4 col-sm-auto d-flex align-items-center pr-0">
                <h5 class="fs-0 mb-0 text-nowrap py-2 py-xl-0">${t(name)}</h5>
            </div>
            <div class="col-8 col-sm-auto text-right pl-2">
                <div id="table-customers-replace-element">
                    <a href="${path}-add" class="btn btn-falcon-default btn-sm" type="button"><span
                            class="fas fa-plus" data-fa-transform="shrink-3 down-2"></span><span
                            class="d-none d-sm-inline-block ml-1">${t('Add')}</span></a>
                </div>
            </div>
        </div>
    </div>
    <div class="card-body p-0" style="height:${window.innerHeight - 240}px;overflow:auto">
        <div class="table-responsive">
            <table class="table table-sm table-striped fs--1 mb-0">
                <thead class="bg-200 text-900">
                    <tr>
                        <th class="no align-middle white-space-nowrap py-2" onclick=${onSort('id')}
                            class="sort ${sort.dir.id}" style="width:10px" data-sort>
                            ${t('Unique Identifier')}</th>
                        <th onclick=${onSort('name')}
                            class="sort pr-1 align-middle white-space-nowrap ${sort.dir.name}" data-sort>${t('Name')}</th>
                      <!--  <th onclick=${onSort('role')}
                            class="sort pr-1 align-middle white-space-nowrap ${sort.dir.role}" data-sort>Email
                        </th> -->
                        <th class="sort align-middle white-space-nowrap desktop" >${t('Legal Status')}</th>
                        <th class="sort pr-1 align-middle white-space-nowrap pl-5 desktop" data-sort="address"
                            style="min-width: 200px;">${t('Address')}</th>
                        <th class="align-middle no-sort"></th>
                    </tr>
                </thead>
                <tbody class="list" id="table-customers-body">
                    ${objects.map(it => EntityRow(path, it, onDelete))}
                    <br/>
        <br/>
                </tbody>
            </table>
        </div>
    </div>
    <${Pagination} count=${objects.length} page=${page}/>
</div>`;
}

export const UpdateEntity = (props: any) => UpdateObject(EntityForm, '/entities', props);
export const EntitiesOverview = (props: any) => ObjectsOverview('Taxable Entities', '/entities', EntityRow, props);

function diff(a: any, base: any) {
    const out: any = {};
    for (const key in a) {
        if (a[key] != base[key]) {
            out[key] = a[key];
        }
    }
    return out;
}

export function EntityRow(path: string, object: Entity, onDelete: any) {

    const [open, setOpen] = useState(false);
    const [state, dispatch] = useSession();
    const [busy, setBusy] = useState(false);

    useEffect(() => {
        const handler = () => setOpen(false);
        document.addEventListener('click', handler, { passive: true });
        return () => {
            document.removeEventListener('click', handler);
        };
    }, []);


    function onDeleteClick(object: Entity) {
        if (busy) return;
        setBusy(true);
        Api.remove(path + '/' + object.id, state.token || '')
            .then(() => {
                console.log('DELETED');
                setOpen(false);
                onDelete(object);
                setBusy(false);
            })
            .catch((err) => {
                console.log('ERROR', err);
                setBusy(false);
            });
    };


    return html`<tr class="btn-reveal-trigger">
    <td class="no align-middle white-space-nowrap">${object.identifier}</td>
    <td class="name align-middle white-space-nowrap">
        <a href="${path}/${object.id}">
            <h6>${object.name}</h6>
        </a>
    </td>
    <td class="phone align-middle white-space-nowrap desktop">${LegalStatus.find(it => it.value == object.legal_status)?.label}</td>
    <td class="address align-middle white-space-nowrap pl-5 desktop">${object.postal_code}</td>
    <td class="align-middle white-space-nowrap text-right actions-column">
        <div class="dropdown font-sans-serif">
            <button onclick=${(ev: any) => {
            ev.preventDefault();
            ev.stopImmediatePropagation();
            setOpen(!open);
        }} class="btn btn-link text-600 btn-sm dropdown-toggle btn-reveal"
                type="button"
                id="customer-dropdown-0" data-toggle="dropdown" data-boundary="window" aria-haspopup="true"
                aria-expanded="false">
                <${FontAwesomeIcon} icon=${['fas', 'ellipsis-h']} class="fs--1" /></button>
            <div class="dropdown-menu dropdown-menu-right border py-0 ${open ? 'show' : ''}"
                aria-labelledby="customer-dropdown-0">
                <div class="bg-white">
                    <!--<a class="dropdown-item" href="#!">Edit</a>-->
                    <a class="dropdown-item text-danger" onclick=${() => onDeleteClick(object)}>Delete</a></div>
            </div>
        </div>
    </td>
</tr>`;
}

function NoValue(label: string) {
    return html`<p class="font-italic text-400 mb-0">${label}</p>`;
}

function Value({ label, value }: { label: string, value: string }) {
    return value ? (html`${value}`) : NoValue(label);
}

export function EntityDetails({ id }: { id?: string }) {

    const [session, dispatch] = useSession();

    const [entity, setEntity] = useState<{ data: Entity, contacts: Contact[] } | undefined>();

    useEffect(() => {
        if (id) {
            Promise.all([Api.get('/entities', id, session.token as any),
            Api.search('/activites', '', 1, {}, session.token as any)
            ]).then(([data, activites]) => {
                data.data.main_activity_name = activites.data.find((it: any) => it.id == data.data.main_activity)?.name;
                data.data.secondary_activity_name = activites.data.find((it: any) => it.id == data.data.secondary_activity)?.name;
                setEntity(data);
            });
        }
    }, []);
    /*
    const saveNotes:any = useRef(debounce(function(notes:string){
        dispatch({type:'mutate',collection: 'users',id, data:{notes} });
    },4000)).current;
*/
    const setNotes = (ev: any) => dispatch({ type: 'mutate', collection: 'users', id, data: { notes: ev.target.value }, debounce: true });
    if (entity) {

        return html`<div class="card mb-3">
    <div class="card-header">
        <div class="row align-items-center">
            <div class="col">
                <h5 class="mb-0">#${entity.data.name}</h5>
            </div>

            <div class="col-auto"><a class="btn btn-falcon-default btn-sm" href="/entities/${entity.data.id}/edit">
                    <${FontAwesomeIcon} icon=${['fas', 'pencil-alt']} /> ${t('Update')}</a></div>
        </div>
    </div>
    <div class="card-body bg-light border-top">
        <div class="row">
            <div class="col-lg col-xxl-5">
                <div class="row">
                    <div class="col">
                        <p class="font-weight-semi-bold mb-1">${t('Name/Company Name')}</p>
                    </div>
                    <div class="col">${entity.data.name}</div>
                </div>
                <div class="row">
                    <div class="col">
                        <p class="font-weight-semi-bold mb-1">${t('Unique Identifier')}</p>
                    </div>
                    <div class="col">${entity.data.identifier}</div>
                </div>
                <div class="row">
                    <div class="col">
                        <p class="font-weight-semi-bold mb-0">${t('Address')}</p>
                    </div>
                    <div class="col">
                        <${Value} label="Pas d'addresse" value=${entity.data.address} />
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <p class="font-weight-semi-bold mb-1">${t('Main Activity')}</p>
                    </div>
                    <div class="col">${entity.data.main_activity_name}</div>
                </div>
                <div class="row">
                    <div class="col">
                        <p class="font-weight-semi-bold mb-1">${t('Secondary Activity')}</p>
                    </div>
                    <div class="col">${entity.data.secondary_activity_name}</div>
                </div>
            </div>
            <div class="col-lg col-xxl-5 mt-4 mt-lg-0 offset-xxl-1">

                <div class="row">
                    <div class="col-5 col-sm-4">
                        <p class="font-weight-semi-bold mb-1">${t('Contact Person')}</p>
                    </div>
                    <div class="col">${entity.contacts[0]?.name}</div>
                </div>
                <div class="row">
                    <div class="col-5 col-sm-4">
                        <p class="font-weight-semi-bold mb-1">${t('Vat Code')}</p>
                    </div>
                    <div class="col">${entity.data.vat_code}</div>
                </div>
                <div class="row">
                    <div class="col-5 col-sm-4">
                        <p class="font-weight-semi-bold mb-1">${t('Category Code')}</p>
                    </div>
                    <div class="col">${entity.data.category_code}</div>
                </div>

            </div>
            <div hidden class="col-lg col-xxl-5 mt-4 mt-lg-0 offset-xxl-1">

                <div class="row">
                    <div class="col-5 col-sm-4">
                        <p class="font-weight-semi-bold mb-1">${t('Legal Status')}</p>
                    </div>
                    <div class="col">${entity.data.legal_status}</div>
                </div>
                <div class="row">
                    <div class="col-5 col-sm-4">
                        <p class="font-weight-semi-bold mb-1">${t('Vat Code')}</p>
                    </div>
                    <div class="col">${entity.data.vat_code}</div>
                </div>
                <div class="row">
                    <div class="col-5 col-sm-4">
                        <p class="font-weight-semi-bold mb-1">${t('Category Code')}</p>
                    </div>
                    <div class="col">${entity.data.category_code}</div>
                </div>

            </div>
        </div>
    </div>
</div>
`;

    } else {
        return html`<div>User ${id} not found</div>`;
    }
}
