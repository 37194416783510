
import { createContext } from "preact";
import { route } from "preact-router";
import { useContext, useReducer } from "preact/hooks";
// import { openDB, deleteDB, wrap, unwrap, IDBPDatabase } from 'idb';
import * as storage from 'idb-keyval';


import { Billable, Id, LoginForm, LoginResponse, Mutation, Patient, Prescription, Product, PurchaseOrder, PurchaseOrderItem, randomString, RegisterForm, User } from "./shared/core";
import { generateId, t } from "./core";
import { html } from "htm/preact";
import { toast } from "react-toastify";

/*import Fuse from 'fuse.js'
*/
export const url = window.location.href.indexOf('localhost') !== -1 ? 'http://127.0.0.1:3099/api/v1' : '/api/v1';

let dirty: any = {};

const SYNC_INTERVAL = 5 * 1000;

export const state = {
    syncing: false,
    offline: false,
    setSyncing: (val: boolean) => { },
    setOffline: (val: boolean) => { },
};



function summary(mutations: any[]) {
    return Array.from(mutations.reduce((p, n) => {
        const key = n[0] + n[1] + n[2];
        const current = p[key];
        if (!current || current[3] < n[3]) {
            p[key] = n;
        }
        return p;
    }, new Map()).values());
}

const savedStore = localStorage.getItem('store');

export let SStore = savedStore ? JSON.parse(savedStore) : {
    userId: null,
    mutations: [],
};

/*
console.log('Store', Store);
Store.add = ([kind, data]: any) => {

    const now = Date.now();

    Store[kind] = Store[kind] ?? [];

    let obj = Store[kind].find((it: any) => it.id == data.id);

    const exists = Boolean(obj);

    if (!exists) {
        obj = { id: { v: randomString(9), t: now } };
        Store[kind].push(obj);
    }

    for (const key in data) {
        if (key != 'id' && obj[key] == data[key]) {
            delete data[key];
        } else {
            obj[key] = { v: data[key], t: now };
        }
    }

    Object.keys(data).filter(key => key !== 'id').forEach(key =>
        Store.mutations.push([kind, data.id, key, data[key], now])
    );

    // Store.mutations = summary(Store.mutations);
    save();

    sync();
}
*/

function diff(data: any, current?: DbItem) {
    if (!current) {
        return data;
    }
    const out: any = {};
    for (const key in data) {
        if (current[key].v != data[key]) {
            out[key] = data[key];
        }
    }
    return out;
}

function save(state: any) {
    localStorage.setItem('store', JSON.stringify(state));
}

export type Mutation1 = [Collection, string, string, any, number, string];


function apply(store: Db, event: Mutation1): boolean {
    let updated = false;
    const [kind, id, field, value, time, userId] = event;
    if (!store[kind]) {
        store[kind] = [];
    }
    const collection: DbItem[] = store[kind];
    let obj = collection.find(it => it.id.v == id);
    if (!obj) {
        obj = { id: { v: id, t: time } };
        collection.push(obj);
        updated = true;
    }
    if (!obj[field] || obj[field].t < time) {
        updated = true;
        obj[field] = { v: value, t: time };
    }
    return updated;
}



export interface DbItem {
    id: { v: string, t: number };
    [key: string]: { v: any, t: number };
}

export type Collection = string;

export type Db = { [key: string]: DbItem[] };


export interface Visit {
    _id: string;
    patientId: string;
    name: string;
    note?: string;
    scheduledAt?: number;
    cameAt?: number;
    startedAt?: number;
    endedAt?: number;
    timestamps?: any;
    index: number;
    queueNumber?: number;
    deletedAt?: number;
}

export interface SFile {
    id: number;
    name: string;
    created_at: string;
}

export class SessionContext {

    onSearch: any;

    uploading = false;
    busy = false;
    online = false;
    preloaded = false;
    status: string = 'ready';

    token?: string;
    user?: User;

    schedules: any[] = [];


    search = '';

    quick: { [key: string]: any } = {};

    newPurchaseOrder: { items: PurchaseOrderItem[] } = { items: [] };

    organizations: any[] = [];
    users: any[] = [];
    patients: Patient[] = [];
    billables: Billable[] = [];
    visits: Visit[] = [];
    files: SFile[] = [];
    drugs: any[] = [];
    prescriptions: Prescription[] = [];
    purchaseOrders: PurchaseOrder[] = [];


    products: Product[] = [];

    local = {
        selectedCalendar: null as any,
        calendarRef: null as any,
    };
    /* [0, 1, 3, 4].map(it => ({ _id: 'id' + it, name: 'name ' + it, category: 'waiting', index: it }))
        .concat([5, 6, 7, 8].map(it => ({ _id: 'id' + it, name: 'name ' + it, category: 'scheduled', index: it })))
        .concat([9, 10, 11, 12].map(it => ({ _id: 'id' + it, name: 'name ' + it, category: 'doing', index: it })))
        .map(it => ({ ...it, timestamps: {} }))*/

    mutations: Mutation[] = [];

    syncState: 'uptodate' | 'syncing' | 'offline' | 'outdated' = 'uptodate';
    last?: number;
    organization: any;
    options: any[] = [];
    exams: any[] = [];
    certificates: Certificate[] = [];

    constructor() {
        try {
            this.user = JSON.parse(localStorage.getItem('user') as any);
            this.token = localStorage.getItem('token') || undefined;
            this.last = parseInt(localStorage.getItem('last') || '0') || 0;

            console.info('store added', this);
            setInterval(() => {
                for (const collection in dirty) {
                    if (this[collection] && !this[collection].length) {
                        delete dirty[collection];
                        continue;
                    }
                    const t = Date.now();
                    console.log('collection', collection, this[collection]);
                    storage.set(collection, this[collection]);
                }
                if (Object.keys(dirty).length) {
                    storage.set('mutations', this.mutations);
                }
                dirty = {};
            }, 1000);
        } catch (error: any) {
            console.log(error.message)
        }
    }

    static async init() {
        const keys = (await storage.keys())
            .filter(key => ['status', 'init', 'busy', 'timeout', 'online', 'search', 'uploading'].indexOf(key.toString()) == -1);

        const values = await Promise.all(keys
            .map(key => storage.get(key)));
        return keys.reduce((p, key, i) => {
            p[key.toString()] = values[i];
            return p;
        }, {} as any)
    }

    static async login(form: LoginForm, dispatch: any) {
        (form as any).identifier = form.email;
        const res = await (await fetch(url + '/auth/login', {
            method: 'POST',
            headers: { 'content-type': 'application/json' },
            body: JSON.stringify(form)
        })).json();
        if (res.token) {
            dispatch({ type: 'logged', data: res });
        }
        return res.token;
    }

    static async register(form: RegisterForm, dispatch: any) {
        const res = await (await fetch(url + '/auth/register', {
            method: 'POST',
            headers: { 'content-type': 'application/json' },
            body: JSON.stringify(form)
        })).json();
        if (res.token) {
            dispatch({ type: 'logged', data: res });
        }
        return res.token;
    }

    static async forgotPassword(form: { email: string }) {
        return await (await fetch(url + '/auth/forgot-password', {
            method: 'POST',
            headers: { 'content-type': 'application/json' },
            body: JSON.stringify(form)
        })).json();
    }

    static async resetPassword(form: { token: string, password: string }) {
        return await (await fetch(url + '/auth/reset-password', {
            method: 'POST',
            headers: { 'content-type': 'application/json' },
            body: JSON.stringify(form)
        })).json();
    }

    /*
        setLogged({ token, user }: LoginResponse) {
            this.token = token;
            this.user = user;
            localStorage.setItem('token', token);
            localStorage.setItem('user', JSON.stringify(user));
        }
    */
    static mutate(state: SessionContext, collection: 'patients' | 'billables' | 'visits', object: any, id: string) {

        const data = JSON.parse(JSON.stringify(object));

        if (id) {
            const t = Date.now();

            let obj: any = (state[collection] as Id[]).find(it => it._id == id);

            if (!obj) {
                obj = { _id: id, timestamps: { _id: Date.now() } };
                state[collection].push(obj);
            }
            // cache[collection + id] = obj;

            const isSame = (a: any, b: any) => {
                const ta = typeof (a);
                const tb = typeof (b);
                if (ta !== tb || a !== b) {
                    return false;
                } else if (Array.isArray(a) || Array.isArray(b)) {
                    const ahash = (a || []).map((it: any) => it._id || it).join('');
                    const bhash = (b || []).map((it: any) => it._id || it).join('');
                    return ahash == bhash;
                } else if (ta == 'object') {
                    console.log(a, b)
                    return a?.value == b?.value;
                } else {
                    return a == b;
                }
            };

            for (const key in data) {
                if (isSame(obj[key], data[key])) {
                    delete data[key];
                } else {
                    obj[key] = data[key];
                    obj.timestamps = obj.timestamps || {};
                    obj.timestamps[key] = Date.now();
                }
            }
            if (collection == 'visits' && data.index) {
                // prevent fractional index
                delete data.index; // forces later mutate
            }
            if (id !== 'new') {
                state.mutations.push({ collection, objectId: id, data, time: Date.now() });
            }
        } else {
            id = data._id = generateId(state.user?.organizationId || '');
            state[collection].push({ ...data, timestamps: { _id: Date.now() } });
            state.mutations.push({ collection, objectId: object._id, data, time: Date.now() });
        }

        if (collection == 'visits') {
            state.mutations = state.mutations.concat(sortVisits(state));
        }

        dirty[collection] = true;
        return id;
    }

}




const initalState = new SessionContext();

export const Session = createContext(initalState);

export function useSession(): [state: SessionContext,
    dispatch: (val: { [key: string]: any } | ((state: SessionContext) => string | undefined)) => void] {
    return useContext(Session) as any;
}
const { Provider } = Session;

export const StateProvider = ({ children }: any) => {
    const [state, dispatch] = useReducer((state: SessionContext, action: { debounce?: boolean, type: string, data: any, collection?: any, id?: string }): SessionContext => {

        try {
            if (typeof (action) == 'function') {
                const key = (action as any)(state);
                if (key) {
                    console.log('KEY', key, action);
                    storage.set(key, (state as any)[key]);
                }
                return { ...state };
            }

            switch (action.type) {
                case 'upload':
                    checkAndUpload(state, dispatch);
                    return { ...state };
                case 'uploaded':
                    state.uploading = false;
                    setTimeout(() => dispatch({ type: 'upload' } as any), 60000);
                    return { ...state };
                case 'settings':
                    state.organizations[0] = { ...state.organizations[0], ...action.data };
                    const newStateWithSettings = { ...state, organizations: [...state.organizations] };
                    persist(newStateWithSettings);
                    return newStateWithSettings;
                case 'search':
                    if (state.onSearch) {
                        state.onSearch(action.data);
                    }
                    return { ...state, search: action.data };

                case 'onSearch':
                    return {
                        ...state, onSearch: debounce((ev: any) => {
                            action.data(ev);
                        }, 200), search: ''
                    };
                case 'logged':
                    const { token, user } = action.data;
                    storage.set('token', token);
                    storage.set('user', user);
                    localStorage.setItem('token', token);
                    localStorage.setItem('user', JSON.stringify(user));
                    // preload(token, dispatch);
                    return { ...state, token, user };
                case 'logout':
                    localStorage.clear();
                    storage.clear();
                    return new SessionContext() as any;
                case 'push':
                    if (state.token && state.mutations.length) {
                        saveOnline(state, { mutations: state.mutations, last: state.last }, [], dispatch);
                    }
                    return { ...state, mutations: [] };
                case 'init':
                    const newState = {
                        ...state, ...action.data,
                    } as SessionContext;
                    if (newState.token) {
                        // subscribe(state, dispatch);
                        newState.preloaded = true;

                    }


                    return newState;
                case 'message':
                    if (Array.isArray(action.data)) {
                        for (const mutation of action.data) {
                            if (!mutationsIds.has(mutation._id)) {
                                applyMutation(state, mutation);
                            }
                        }
                        state.last = (action.data as any[]).pop().updatedAt || state.last;
                        localStorage.setItem('last', state.last as any);
                    } else {
                        load(state, action.data);
                        checkAndUpload(state, dispatch);

                    }
                    persist(state);
                    return { ...state };
                case 'push-success':
                    if (state.token && state.mutations.length) {
                        saveOnline(state, { mutations: state.mutations, last: state.last }, [], dispatch);
                    }
                    return {
                        ...state,
                        mutations: [],
                        status: 'ready',
                    } as SessionContext;
                case 'push-failure':
                    /// const t2: any = setTimeout(() => dispatch({ type: 'push' } as any), 100000) as any;
                    return {
                        ...state, mutations: action.data.mutations,
                        status: 'ready',
                        // timeout: t2,
                    } as SessionContext;

                case 'mutate':
                    return mutate(state, action, dispatch) as any;
                case 'load':
                    const res = action.data;
                    load(state, res);
                    checkAndUpload(state, dispatch);

                    return { ...state };
                case 'set':
                    console.log('set', action);
                    Object.keys(action.data)
                        .forEach(key => {
                            storage.set(key, action.data[key]);
                        })
                    return { ...state, ...action.data };
                case 'sync':
                    // SessionContext.sync(state, dispatch);
                    return state;
                case 'preload-success':
                    load(state, action.data);
                    subscribe(state, dispatch);

                    checkAndUpload(state, dispatch);

                    return { ...state, preloaded: true };
                case 'preload-failure':
                    subscribe(state, dispatch);
                    return { ...state, preloaded: true };
                case 'online':
                    return { ...state, online: action.data as any };
                case 'quick-patient':
                    state.search = '';
                    if (window.location.pathname.indexOf('/waiting') !== -1) {
                        const todayVisits = state.visits.filter(it => isToday(new Date(it.scheduledAt || it.cameAt || 0)));
                        const exists = todayVisits.find(it => it.patientId == action.data._id);
                        if (!exists) {
                            return mutate(state, {
                                collection: 'visits',
                                id: generateId(state.user?.organizationId || ''),
                                data: {
                                    patientId: action.data._id,
                                    index: todayVisits.length,
                                    name: action.data.firstName + ' ' + action.data.lastName,
                                    queueNumber: (todayVisits.sort((a, b) => (b.queueNumber || 0) - (a.queueNumber || 0)).map(it => it.queueNumber)[0] || 0) + 1,
                                    cameAt: Date.now(),
                                }
                            }, dispatch) as any;
                        } else if (exists.deletedAt) {
                            return mutate(state, {
                                collection: 'visits', id: exists._id, data: {
                                    deletedAt: null,
                                }
                            }, dispatch) as any;
                        }
                    } else if (window.location.pathname.indexOf('/calendar') !== -1) {
                        if (state.local.selectedCalendar) {
                            const newState = mutate(state, {
                                collection: 'visits',
                                id: generateId(state.user?.organizationId || ''),
                                data: {
                                    patientId: action.data._id,
                                    index: 0,
                                    name: action.data.firstName + ' ' + action.data.lastName,
                                    scheduledAt: new Date(state.local.selectedCalendar.start).getTime(),
                                }
                            }, dispatch);
                            newState.local.selectedCalendar = null;
                            console.log(newState.local.calendarRef);
                            newState.local.calendarRef.getApi().unselect();
                            return newState;
                        } else {
                            toast.warn('Selectionez une date');
                        }
                    }
                    return { ...state };

                case 'quick':
                    if (action.data.drug) {
                        if (window.location.pathname.indexOf('/inventory') !== -1) {
                            const drug = action.data.drug;
                            state.newPurchaseOrder.items.push({
                                _id: "await randomString()",
                                ref: drug._id,
                                name: String(drug.fullname || drug.name || ''),
                                requestedQuantity: 1,
                            });
                            storage.set('newPurchaseOrder', state.newPurchaseOrder);
                        }
                    }
                    return { ...state };

                case 'fn':
                    action.data(state);
                    return { ...state };

                case 'busy':
                    return { ...state, busy: action.data };

                case 'quicked':
                    delete state.quick[action.data];
                    return { ...state };
                case 'remove-item':

                    const objects = (state as any)[action.collection] as any;
                    const object = objects.find((it: any) => it._id == action.id);
                    console.log(action, object);

                    object.items = object.items.filter((it: any) => it.ref !== (action as any).ref);
                    console.log(action, object);
                    return { ...state };

                case 'calendar-select':
                    return { ...state, local: { ...state.local, selectedCalendar: action.data } };
                case 'calendarRef':
                    return { ...state, local: { ...state.local, calendarRef: action.data } };

                case 'settings':
                    state.organization = state.organization || {};
                    const { key, value } = action.data;
                    switch (action.data.action) {
                        case 'add':
                            state.organization[key] = state.organization[key] || [];
                            state.organization[key].push(value);
                            break;
                        case 'add':
                            let array: any[] = state.organization[key] = state.organization[key] || [];
                            array.splice(array.indexOf(value), 1);
                            break;
                        case 'set':
                            state.organization[key] = value;
                            break;


                        default:
                            break;
                    }
                default:
                    console.log(state.patients);
                    return { ...state, patients: [...state.patients] } as SessionContext;
            };

        } catch (error) {
            console.error(error);
            return state;
        }
    }, initalState);

    if (!(state as any).init) {
        SessionContext.init().then((data) => {
            dispatch({ type: 'init', data });
        })
    }

    (state as any).init = true;
    return html`<${Provider} value=${[state, dispatch]}>${children}</${Provider}>`;
};

export const isToday = (someDate: Date) => {
    const today = new Date()
    return someDate.getDate() == today.getDate() &&
        someDate.getMonth() == today.getMonth() &&
        someDate.getFullYear() == today.getFullYear()
}


function persist(state: any) {
    console.log('presist', state);
    for (const key in state) {
        if (key != 'local') {
            storage.set(key, state[key]);
        }
    }
}


function sortValue(a: any) {
    if (typeof (a) == 'number') {
        return a;
    } else {
        return (a || '').toString().trim().toLowerCase();
    }
}

export function searchPatients(patients: Patient[], q: string, sort: any = {}, dir = 1, page = 1) {
    q = q.trim().toLowerCase();
    return patients
        .filter((it: any) => it && !it.deletedAt && (it.firstName && it.lastName))
        .sort((a: any, b: any) => (sort.field ? (sortValue(a[sort.field]) < sortValue(b[sort.field]) ? -1 : 1) : -1) * dir)
        .filter(it => !q
            || !q.split(' ').find(q => it.firstName.toLowerCase().indexOf(q) == -1
                && it.lastName.toLowerCase().indexOf(q) == -1
                && it.no?.toString().padStart(5, '0').indexOf(q) == -1)
        );
}

export function searchProducts(products: Product[], q: string, sort: any = {}, dir = 1, page = 1) {
    q = q.trim().toLowerCase();
    return products
        .filter((it: any) => it && !it.deletedAt)
        .sort((a: any, b: any) => (sort.field ? (sortValue(a[sort.field]) < sortValue(b[sort.field]) ? -1 : 1) : -1) * dir)
        .filter(it => !q
            || !q.split(' ').find(q => it.name.toLowerCase().indexOf(q) == -1
                && it.sku.toLowerCase().indexOf(q) == -1)
        );
}


export function searchObjects<T>(objects: T[], q: string, sort: any = {}, dir = 1, page = 1) {
    q = q.trim().toLowerCase();
    return objects
        .filter((it: any) => it && !it.deletedAt)
        .sort((a: any, b: any) => (sort.field ? (sortValue(a[sort.field]) < sortValue(b[sort.field]) ? -1 : 1) : -1) * dir)
        .filter(it => {
            const all = Object.keys(it).map(key => it[key]).filter(it => it).map(it => it.toString()).join(' ').toLowerCase();
            return !q || !q.split(' ').find(q => all.indexOf(q) == -1)
        })
        .slice((page - 1) * 20, page * 20);
}

export function searchVisibleDrugs(drugs: any[], q: string, sort: any = {}, dir = 1, page = 1) {
    q = q.trim().toLowerCase();
    return drugs
        .filter(it => it && !it.deletedAt)
        .sort((a: any, b: any) => (sort.field ? (sortValue(a[sort.field]) < sortValue(b[sort.field]) ? -1 : 1) : -1) * dir)
        .filter(it => !q
            || !q.split(' ').find(q => it.fullname?.toLowerCase().indexOf(q) == -1
                && it.name?.toLowerCase().indexOf(q) == -1
                && it._id?.toLowerCase().indexOf(q) == -1)
        );
}

export function debounce(func: any, wait: number, immediate: boolean = false) {
    let timeout: any;
    return function (this: any) {
        var context = this, args = arguments;
        var later = function () {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
};


const mutationsIds = new Set();


function saveOnline(state: SessionContext, body: { mutations: Mutation[], last?: number }, files: string[], dispatch: any) {
    body.last = body.last || 0;
    for (const mutation of body.mutations) {
        mutation._id = generateId(state.user?.organizationId || '');
        mutationsIds.add(mutation._id);
    }
    fetch(url + '/mutate', {
        method: 'POST', body: JSON.stringify(body), headers: {
            Authorization: 'Bearer ' + state.token,
            'content-type': 'application/json'
        },
    }).then(res => res.json())
        .then(res => {
            if (res.last) {
                //     dispatch({ type: 'load', data: res });
                dispatch({ type: 'push-success', data: res });
            } else {
                dispatch({ type: 'push-failure', data: body, error: res.message });

            }
        })
        .catch(err => {
            dispatch({ type: 'push-failure', data: body, error: err.message });
        })
}

function preload(token: string, dispatch: any) {
    fetch(url + '/preload', {
        method: 'GET', headers: {
            Authorization: 'Bearer ' + token,
            'content-type': 'application/json'
        },
    })
        .then(res => res.json())
        .then(data => {
            dispatch({ type: 'preload-success', data });
        })
        .catch(err => {
            dispatch({ type: 'preload-failure', data: err.message });
        })
}

const pushDebounced: any = debounce((dispatch: any) => {
    dispatch({ type: 'push' });
}, 500);





const pushUpdates = debounce(function (token: string, body: any, dispatch: any) {
    dispatch({ type: 'persist' });
    fetch(url + '/mutate', {
        method: 'POST', body, headers: {
            Authorization: 'Bearer ' + token,
            'content-type': 'application/json'
        },
    }).then(res => res.json())
        .then(res => {
            dispatch({ type: 'load', data: res });
        })
        .catch(err => {
            dispatch({ type: 'push-failure', data: body, error: err.message });
        })
}, 500);



export async function getDownloadUrl(token: string, fileId: string, filename: string) {
    const res = await (await fetch(url + '/storage/' + fileId + '/download/' + filename.replace(/\./g, '__'), {
        method: 'GET', headers: {
            Authorization: 'Bearer ' + token,
            'content-type': 'application/json'
        },
    })).json();
    return res.downloadUrl;
}


function subscribe(state: any, dispatch: any) {
    console.log('SUBSCRIBE');
    let eventSource = new EventSource(`${url}/subscribe?token=${state.token}&last=${state.last}`);
    eventSource.onmessage = ({ data, type, lastEventId }) => {
        dispatch({ type: 'message', data: JSON.parse(data) });
    };
    eventSource.onopen = (ev) => {
        dispatch({ type: 'online', data: true });
        dispatch({ type: 'push' });

    };

    eventSource.onerror = (ev) => {
        dispatch({ type: 'online', data: false });
    };
}


function applyMutation(state: any, { collection, data, objectId, time }: Mutation) {
    let current: any = (state[collection] as Id[]).find(it => it._id == objectId);
    if (!current) {
        current = { _id: objectId, timestamps: { _id: Date.now() } };
        state[collection].push(current);
    }
    current.timestamps = current.timestamps || {};
    for (const key in data) {
        if (time > (current.timestamps[key] || 0)) {
            current[key] = data[key];
            current.timestamps[key] = time;
        }
    }
}

function sortVisits(state: any): Mutation[] {

    const mutations: Mutation[] = [];


    state.visits = state.visits.sort((a: { index: number; }, b: { index: number; }) => a.index - b.index);
    const todayVisits = state.visits.filter((it: any) => !it.deletedAt && isToday(new Date(it.cameAt || it.scheduledAt || 0)))
    todayVisits.forEach((it: any, index: any) => {
        if (it.index !== index) {
            mutations.push({ collection: 'visits', objectId: it._id, data: { index }, time: Date.now() + 1 });
        }
        it.index = index;
        return it;
    });

    return mutations;
}


function load(state: any, data: any) {
    const keys = Object.keys(data).filter(key => key != 'token' && key != 'user');
    for (const key of keys) {
        console.log('load', key);
        if (Array.isArray(data[key])) {
            console.log('key', key);
            const currentItems: any[] = (state as any)[key] || [];
            const map = currentItems.reduce((p, n, i) => {
                p[n._id] = i;
                return p;
            }, {});

            const items: any[] = data[key];
            for (const item of items) {
                item.timestamps = item.timestamps || {};
                const index = map[item._id] ?? -1;
                if (index >= 0) {
                    currentItems[index] = item;
                } else {
                    currentItems.push(item);
                }
            }
            storage.set(key, currentItems).then(console.log).catch(console.error);
        }
    }
    state.last = data.last;
    localStorage.setItem('last', state.last);
}


function mutate(state: SessionContext, action: any, dispatch: any) {

    SessionContext.mutate(state, action.collection, action.data, action.id as any);

    if (action.debounce) {
        pushDebounced(dispatch);
        //                        state.timeout = setTimeout(() => dispatch({ type: 'push' } as any), 5000);
    } else {
        if (state.status == 'ready' && state.token && state.mutations.length) {
            saveOnline(state, { mutations: state.mutations, last: state.last }, [], dispatch);
            state.status = 'syncing';
            state.mutations = [];
        }
    }
    if (action.collection == 'organizations' && action.data.visibleDrugs) {
        searchDrugs('', 1, { _id: state.organizations[0].visibleDrugs, $limit: 1000 })
            .then(res => {
                dispatch({ type: 'set', data: { drugs: res.data } });
            })
    }
    return { ...state, timeout: null } as SessionContext;
}

export async function searchDrugs(q: string, page: number, filter: any) {
    const list = await (await fetch(url + '/data/drugs?$q=' + q + '&$skip=' +
        ((page - 1) * 100) + '&' + Object.keys(filter).map(key => `${key}=${filter[key]}`).join('&'))).json();
    return list;
}

export async function loadDrug(id: string) {
    return (await fetch(url + '/data/drugs/' + id)).json();
}

export const searchDrugsDebounced: (q: string, page: number, filter: any, callback: any) => any = debounce((q: string, page: number, filter: any, callback: any) => {
    searchDrugs(q, page, filter).then(callback);
}, 400);



export async function createPurchaseOrder(token: string, form: any, dispatch: any) {
    dispatch({ type: 'busy', data: true });
    try {
        const res = await (await fetch(url + '/purchase-orders', {
            method: 'POST',
            headers: { 'content-type': 'application/json', Authorization: 'Bearer ' + token },
            body: JSON.stringify(form)
        })).json();
        if (res._id) {
            toast.success('Bon de commande crée');
            route(`/inventory/purchase-orders/${res._id}`);
        } else {
            toast.error(res.message);
        }
    } catch (error: any) {
        toast.error(error.message);
    } finally {
        dispatch({ type: 'busy', data: false });
    }

}

function handleApiError(err: any) {
    console.log(err.message);
    if (err.message.includes('duplicate key value violates unique constraint')) {
        toast.error(t('This element exists in the system'));
    } else {
        toast.error(err.message);
    }
    throw err;
}

export module Api {

    export async function get(path: string, id: string, token: string) {
        const res = await (await fetch(url + path + '/' + id
            , { headers: { Authorization: 'Bearer ' + token || '' } })).json();
        return res;
    }

    export async function getBinary(path: string, token: string) {
        const res = await (await fetch(url + path
            , { headers: { Authorization: 'Bearer ' + token || '' } })).blob();
        return res;
    }

    export async function post(path: string, body: any, token?: string) {
        const res = await (await fetch(url + path,
            {
                method: 'POST',
                body: JSON.stringify(body),
                headers: {
                    'content-type': 'application/json',
                    Authorization: 'Bearer ' + token || ''
                }
            }));
        if (res.ok) {
            return res.json().catch(handleApiError);
        } else {
            return res.text().then(err => handleApiError(new Error(err)));
        }
    }

    export async function remove(path: string, token?: string) {
        const res = await (await fetch(url + path,
            {
                method: 'DELETE',
                headers: {
                    'content-type': 'application/json',
                    Authorization: 'Bearer ' + token || ''
                }
            })).json()
            .catch(handleApiError);
        return res;
    }

    export async function put(path: string, id: string | number, body: any, token?: string) {
        const res = await (await fetch(url + path + '/' + id,
            {
                method: 'PUT',
                body: JSON.stringify(body),
                headers: {
                    'content-type': 'application/json',
                    Authorization: 'Bearer ' + token || ''
                }
            }));
        if (res.ok) {
            return res.json().catch(handleApiError);
        } else {
            return res.text().then(err => handleApiError(new Error(err)));
        }
    }

    export async function patch(path: string, id: string | number, body: any, token?: string) {
        const res = await (await fetch(url + path + '/' + id,
            {
                method: 'PATCH',
                body: JSON.stringify(body),
                headers: {
                    'content-type': 'application/json',
                    Authorization: 'Bearer ' + token || ''
                }
            }));
        if (res.ok) {
            return res.json().catch(handleApiError);
        } else {
            return res.text().then(err => handleApiError(new Error(err)));
        }
    }

    export async function postDo(path: string, id: string | number, action: string, body: any, token: string) {
        const res = await (await fetch(url + path + '/' + id + '/' + action,
            {
                method: 'POST',
                body: JSON.stringify(body),
                headers: {
                    'content-type': 'application/json',
                    Authorization: 'Bearer ' + token || ''
                }
            })).json()
            .catch(handleApiError);
        return res;
    }

    export async function search(path: string, q: string, page: number, filter: any, token?: string) {
        const list = await (await fetch(url + path + '?q=' + q + '&$skip=' +
            ((page - 1) * 100) + '&' + Object.keys(filter).map(key => `${key}=${filter[key]}`).join('&')

            , { headers: { Authorization: 'Bearer ' + token || '' } })).json()
            .catch(handleApiError);
        return list;
    }

    export const searchDebounced: (path: string, q: string, page: number, filter: any, token: any, callback: any) => any = debounce((path: string, q: string, page: number, filter: any, token: string, callback: any) => {
        search(path, q, page, filter, token).then(callback);
    }, 400);


}


function uploadFile(state: SessionContext, fileId: string, dispatch: any) {
    if (state.uploading) {
        return state;
    }
    storage.get(fileId)
        .then(res => {
            console.log('FILE', res);
            dispatch({ type: 'uploaded', data: { fileId } });
        });


    return { ...state, uploading: true };
}
/*

async function checkAndUpload(state: SessionContext, dispatch: any) {
    if (state.uploading) {
        return setTimeout(() => dispatch({ type: 'check-upload' }), 1000);
    }

    const file = state.files.find(it => !it.uploaded);
    if (!file) {
        return setTimeout(() => dispatch({ type: 'check-upload' }), 1000);
    }
    const store = storage.createStore('storage', 'files');
    storage.set(id, { name: file.name, type: file.type, size: file.size, data: ev.target?.result },
        store,
    );

    const formData = new FormData();
    formData.append('file', file, id);
    const options = {
        method: 'POST',
        body: formData,
        // Uncomment to make it fail
        headers: { "Authorization": 'Bearer ' + state.token }
    };
    fetch('http://localhost:3000/api/v1/storage/upload', options)
        .then(res => {
            if (res.ok) {
                dispatch({
                    type: 'mutate', collection: 'files', id,
                    data: { uploaded: true },
                });

            } else {
                console.error(res);
            }
        });

    dispatch({
        type: 'mutate', collection: 'files', id,
        data: { name: file.name, type: file.type, size: file.size, patientId },
    });
};
}

/*
export const Settings = {
    add: (dispatch: any, key: string, value: any) => dispatch({ type: 'settings', data: { action: 'add', key, value } }),
    remove: (dispatch: any, key: string, value: any) => dispatch({ type: 'settings', data: { action: 'remove', key, value } }),
    set: (dispatch: any, key: string, value: any) => dispatch({ type: 'settings', data: { action: 'set', key, value } }),
}*/



function checkAndUpload(state: any, dispatch: any) {

    const files = state.files.filter(file => !file.uploaded);
    if (!files.length) {
        state.uploading = false;
        return state;
    }
    if (state.uploading) {
        return state;
    }
    if (files.length) {
        //        dispatch({ type: 'upload', data: files });
        state.uploading = true;

        (async () => {

            for (let i = 0; i < files.length; i++) {
                const file = files[i];

                try {
                    const f = await storage.get(file._id,
                        filesStore,
                    );

                    if (!f) {
                        continue;
                    }

                    const formData = new FormData();
                    console.log(f);
                    formData.append('file', new Blob([f.data], { type: f.type }), file.name);
                    const options = {
                        method: 'POST',
                        body: formData,
                        // Uncomment to make it fail
                        headers: { "Authorization": 'Bearer ' + state.token },
                    };
                    // alert('upload ' + file._id);
                    const res = await fetch(url + '/upload', options);
                    if (res.ok) {
                        dispatch({
                            type: 'mutate', collection: 'files', id: file._id,
                            data: { uploaded: true },
                        });

                    } else {
                        console.error(res);
                    }

                } catch (e) {
                    console.warn('failed to upload file ' + file._id, e);
                }

            }

        })().then(res => {
            dispatch({ type: 'uploaded' });
        }).catch(err => {
            dispatch({ type: 'uploaded' });
        })

    }
    return state;
}


export const filesStore = storage.createStore('storage', 'files');

export const thumbnailsStore = storage.createStore('thumbnails', 'thumbnails');


export function getThumbnail(id: string) {
    return url + '/storage/' + id + '/thumbnail?w=240';
}


export function merge(a: any, b: any) {
    a.timestamps = a.timestamps || {};
    b.timestamps = b.timestamps || {};

    for (const key in b) {
        if (key == 'timestamps') {
            continue;
        }
        if (b.timestamps[key] > a.timestamps[key]) {
            a[key] = b[key];
            a.timestamps[key] = b.timestamps[key];
        }
    }

}


/*
export function is(state:SessionContext, role:string) {
    state.user?.roles.f
}
*/


export async function updatePassword(token: string, oldPassword: string, newPassword: string) {
    const res = await fetch(url + '/auth/password', {
        method: 'POST',
        headers: {
            Authorization: 'Bearer ' + token,
            'content-type': 'application/json'
        },
        body: JSON.stringify({ oldPassword, newPassword }),
    });
    if (!res.ok) {
        throw new Error('Forbidden');
    }
    return res.json();
}

export function is(state: SessionContext, role: string) {
    return state.user?.roles?.includes(role)
}

export function can(state: SessionContext, scope: string) {
    return true;
    if (!state.user?.roles || state.user.roles.includes('admin')) {
        return true;
    }
    const data = {
        physician: ['calendar', 'waiting', 'drugs'],
        secretary: ['calendar', 'waiting'],
        pharmacist: ['drugs', 'purchase-orders'],
        receptionist: ['calendar'],
    }
    const rolesThatHasScope = Object.keys(data).filter(role => data[role].includes(scope));
    return Boolean(rolesThatHasScope.find(role => state.user?.roles?.includes(role)));
}


export interface Certificate {
    timestamps: any;
    _id: string;
    patientId: string;
    days: number;
    date: string;
    from: string;
    to: string;
    kind: 'medical' | 'attendance';
}