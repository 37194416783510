import { html } from "htm/preact";
import { generateId, getPage, Pagination, t, useInput } from "./core";
import { useContext, useEffect, useRef, useState } from "preact/hooks";

import Select, { createFilter } from 'react-select'
import Async, { useAsync } from 'react-select/async'
import { citites, socialSecurityOptions } from "./data";

import { Api, debounce, filesStore, getDownloadUrl, merge, searchObjects, SessionContext, SFile, state, useSession } from "./store";
import { Billable, Contact } from "./shared/core";
import { route } from "preact-router";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AsyncCreatableSelect from 'react-select/async-creatable';
import { Drive } from "./products";
import { UpdateObject } from "./crud";
import { FileUpload, ProcedureType } from "./procedures_types";

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Entity, EntityForm } from "./entities";
import { ContactForm } from "./contacts";
import AsyncSelect from "react-select/async";



// hello

const options = citites.map(it => ({ label: it.c + ', ' + it.g, value: it.id1 + '-' + it.id2 }));
const insuranceOptions = [{ label: 'Selectioner' }, { label: 'CNSS', value: 'cnss' }, { label: 'CNRPS', value: 'cnrps' }];

/*
function useForm(pairs:[string,string][],callback:any){

    const debounce = 
    const out:any[] = [];
    for(const pair of pairs) {
        const [state, setState] = useState(pair[1]);
        out.push([state, (ev: any) => {
            setState(ev.target.value);


            // dispatch({collection, type: 'mutate', id,  data: {[pair[0]]: ev.target.value}})
        }]);
    }
    return out;
}*/




export interface Procedure {
    note?: string;
    owner_name: any;
    id: number;
    type_id: string;
    entity_id?: string;
    contact_id?: string;
    created_by: string;
    created_at: string;
    closed_at?: string;
    accepted_at?: string;
    status: string;
    data: { [key: string]: string };
}

export interface NewProcedure {
    type_id: string;
    entity_id?: string;
    contact_id?: string;
    data?: { [key: string]: string };
}




export function ProcedureForm({ data }: { data?: { data: Procedure, procedure_type: ProcedureType, contact: Contact, entity: Entity, entity_contact: any } }) {

    const procedure = data?.data;

    const [proceduresTypes, setProceduresTypes] = useState<ProcedureType[]>([]);
    const [proceduresCategories, setProceduresCategories] = useState<string[]>([]);
    const [procedureCategory, setProcedureCategory] = useState<{ label: string, value: string }>(data?.procedure_type?.category ? { label: data.procedure_type.category, value: data.procedure_type.category } : undefined);
    const [typeId, setTypeId] = useState(data?.procedure_type);
    const [entity, setEntity] = useState<any>(data ? { label: data.entity.name, value: data.entity.id } : undefined);
    const [contact, setContact] = useState<any>(data ? { label: data.contact.name, value: data.contact.id } : undefined);
    const [contactRole, setContactRole] = useState<any>(data?.entity_contact?.role ? { label: data.entity_contact.role, value: data.entity_contact.role } : undefined);

    const [entities, setEntities] = useState<any[]>([]);


    const [validated, setValidated] = useState(false);
    const [state, dispatch] = useSession();

    const getProceduresTypes = () => {
        return procedureCategory ? proceduresTypes.filter(it => it.category == procedureCategory.value) : proceduresTypes;
    }


    useEffect(() => {
        Api.search('/procedures-types', '', 1, {}, state.token).then((res) => {
            setProceduresTypes(res.data);
            setProceduresCategories(Array.from(new Set(res.data.map(it => it.category).filter(it => it))));

        });
    }, []);

    const handleCreateEntity = (inputValue: string) => {
        //    alert('create entity ' + inputValue);
        handleShow();
        //setEntity({ label: inputValue, value: inputValue });
    };

    const handleCreateContact = (inputValue: string) => {
        //    alert('create entity ' + inputValue);
        setShowContact(true);
        //setEntity({ label: inputValue, value: inputValue });
    };

    const handleCreateContactRole = (inputValue: string) => {

        setContactRole({ label: inputValue, value: inputValue });

        //    alert('create entity ' + inputValue);
        //        setShowContact(true);
        //setEntity({ label: inputValue, value: inputValue });
    };



    const onSubmit = async (ev: Event) => {
        ev.preventDefault();
        ev.stopPropagation();
        setValidated(true);
        if ((ev.target as any).checkValidity()) {

            if (!typeId || !contactRole) {
                return;
            }

            const data: any = {
                new_procedure: { entity_id: entity.value, type_id: (typeId as any).id, contact_id: contact.value, data: {} },
                role: contactRole.value
            };


            if (procedure?.id) {

                Api.patch('/procedures', procedure.id, data, state.token)
                    .then((procedureType) => {
                        toast.success(t('Procedure updated successfully'));
                        route('/procedures/' + procedureType.id);
                    });

            } else {
                Api.post('/procedures', data, state.token).then((procedureType) => {

                    toast.success(t('User added successfully'));
                    route('/procedures/' + procedureType.id);
                });
            }

        }

    };

    const loadOptionsentities = (inputValue: string) =>
        Api.search('/entities', inputValue, 1, {}, state.token).then((res) => {
            return res.data.map((it: any) => ({ label: it.name, value: it.id }));
        });

    const loadOptionsContacts = (inputValue: string) =>
        Api.search('/contacts', inputValue, 1, {}, state.token).then((res) => {
            return res.data.map((it: any) => ({ label: it.name, value: it.id }));
        });

    const loadOptionsContactsRoles = (inputValue: string) =>
        Api.search('/contacts-roles', inputValue, 1, {}, state.token).then((res) => {
            return res.data.map((it: any) => ({ label: it.name, value: it.id }));
        });

    const filterConfig: any = {
        ignoreAccents: false,
        ignoreCase: true,
        matchFrom: 'any',
        stringify: (option: any) => {
            return JSON.stringify(option)
        },
    };

    const [show, setShow] = useState(false);
    const [showContact, setShowContact] = useState(false);

    const contactFormRef = useRef(null);
    const entityFormRef = useRef(null);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const onContactAdded = (contact: any) => {
        setContact({ label: contact.name, value: contact.id });
        setShowContact(false);
    };

    const onEntityAdded = (entity: any) => {
        setEntity({ label: entity.name, value: entity.id });
        setShow(false);
    };



    return html`<form onsubmit="${onSubmit}" class="row g-3 needs-validation ${validated ? 'was-validated' : ''}" novalidate>
    <div class="col-lg-3">
        <label class="form-label" for="type">${t('Procedure Type')}</label>
        <${Select} value=${procedureCategory}
        options=${proceduresCategories.map(it => ({ label: it, value: it }))} onChange=${setProcedureCategory}

        className="rs form-control ${validated && (typeId ? 'is-valid' : 'is-invalid')}" />
    </div>
    <div class="col-lg-9">
        <label class="form-label" for="type">${t('Procedure Name')}</label>
        <${Select} getOptionLabel=${option => `${option.name} (${option.common_name})`} getOptionValue=${option => option.id}  value=${typeId}
        options=${getProceduresTypes()} onChange=${setTypeId}

        className="rs form-control ${validated && (typeId ? 'is-valid' : 'is-invalid')}" />
    </div>
    <div class="col-lg-6">
        <label class="form-label" for="email3">${t('Taxable Entity')}</label>
        <${AsyncCreatableSelect} 
            getOptionLabel=${option => option.__isNew__ ? t('Add') + ` '${option.value}'` : option.label} cacheOptions defaultOptions
        isClearable onCreateOption=${handleCreateEntity} loadOptions=${loadOptionsentities}  value=${entity} onChange="${setEntity}"
            className="rs form-control ${validated && (entity ? 'is-valid' : 'is-invalid')}" />
    </div>

    <div class="col-lg-4">
        <label class="form-label" for="email3">${t('Contact Person')}</label>
        <${AsyncCreatableSelect}
        getOptionLabel=${option => option.__isNew__ ? t('Add') + ` '${option.value}'` : option.label} cacheOptions defaultOptions
         isClearable onCreateOption=${handleCreateContact} loadOptions=${loadOptionsContacts} value=${contact} onChange="${setContact}"
            className="rs form-control ${validated && (entity ? 'is-valid' : 'is-invalid')}" />
    </div>

    <div class="col-lg-2">
        <label class="form-label" for="email3">${t('Contact Person Role')}</label>
        <${AsyncCreatableSelect}
        getOptionLabel=${option => option.__isNew__ ? t('Add') + ` '${option.value}'` : option.label} cacheOptions defaultOptions
         isClearable onCreateOption=${handleCreateContactRole} loadOptions=${loadOptionsContactsRoles} value=${contactRole} onChange="${setContactRole}"
            className="rs form-control ${validated && (contactRole ? 'is-valid' : 'is-invalid')}" />
    </div>

    <div class="col-12 d-flex justify-content-end">
        <button class="btn btn-primary" type="submit"> ${procedure?.id ? t('Update') : t('Create')}</button>
    </div>
</form>

    <${Modal}
      show=${show}
      onHide=${handleClose}
      backdrop="static"
      keyboard=${false}
    >
      <${Modal.Header} closeButton>
        <${Modal.Title}>${t('Add')} ${t('Taxable Entity')}</Modal.Title>
      </${Modal.Header}>
      <${Modal.Body}>
        <${EntityForm} formRef=${entityFormRef} onEntityAdded=${onEntityAdded}/>
      </${Modal.Body}>
      <${Modal.Footer}>
        <${Button} variant="secondary" onClick=${handleClose}>
          Close
          </${Button}>
        <${Button} variant="primary" onClick=${() => (entityFormRef.current as any).dispatchEvent(new Event('submit'))}>${t('Add')}</${Button}>
      </${Modal.Footer}>
    </${Modal}>

    <${Modal}
    show=${showContact}
    onHide=${() => setShowContact(false)}
    backdrop="static"
    keyboard=${false}
  >
    <${Modal.Header} closeButton>
      <${Modal.Title}>${t('Add')} ${t('Contact Person')}</Modal.Title>
    </${Modal.Header}>
    <${Modal.Body}>
      <${ContactForm} formRef=${contactFormRef} onContactAdded=${onContactAdded}/>
    </${Modal.Body}>
    <${Modal.Footer}>
      <${Button} variant="secondary" onClick=${() => setShowContact(false)}>
        Close
        </${Button}>
      <${Button} variant="primary" onClick=${() => (contactFormRef.current as any).dispatchEvent(new Event('submit'))} >${t('Add')}</${Button}>
    </${Modal.Footer}>
  </${Modal}>

`;
}


export function AddProcedure() {
    return html`<div class="row g-0">
    <div>
        <div class="card mb-3">
            <div class="card-header">
                <h5 class="mb-0">${t('Add')} ${t('Procedure')}</h5>
            </div>
            <div class="card-body bg-light">
                <${ProcedureForm} />
            </div>
        </div>
    </div>

</div>`;
}

export function ObjectRow(path: string, [object, share]: any, onDelete: any) {

    const [open, setOpen] = useState(false);
    const [state, dispatch] = useSession();
    const [busy, setBusy] = useState(false);

    useEffect(() => {
        const handler = () => setOpen(false);
        document.addEventListener('click', handler, { passive: true });
        return () => {
            document.removeEventListener('click', handler);
        };
    }, []);


    function onDeleteClick(object: any) {
        if (busy) return;
        setBusy(true);
        Api.remove(path + '/' + object.id, state.token || '')
            .then(() => {
                console.log('DELETED');
                setOpen(false);
                onDelete(object);
                setBusy(false);
            })
            .catch((err) => {
                console.log('ERROR', err);
                setBusy(false);
            });
    };

    console.log('object', object);

    return html`<tr class="btn-reveal-trigger">
    <td class="no align-middle white-space-nowrap">${object.id}</td>
    <td class="name align-middle white-space-nowrap">
        <a href="${path}/${object.id}">
            <h6>${object.name}</h6>
        </a>
    </td>
    <td class="phone align-middle white-space-nowrap desktop">${object.created_at?.split('T')[0]}</td>
    <td class="phone align-middle white-space-nowrap desktop">${object.accepted_at?.split('T')[0]}</td>
    <td class="phone align-middle white-space-nowrap desktop">${object.closed_at?.split('T')[0]}</td>
    <td class="phone align-middle white-space-nowrap desktop">${share && share.created_by != state.user?.id ? html`${t('Shared by')} <b>${share.user_name}</b>` : ''}</td>

    <td class="phone align-middle white-space-nowrap desktop">${t(object.status)}</td>

   <!-- <td class="address align-middle white-space-nowrap pl-5 desktop">${t(object.email)}</td>-->
    <td class="align-middle white-space-nowrap text-right actions-column">
        <div class="dropdown font-sans-serif">
            <button onclick=${(ev: any) => {
            ev.preventDefault();
            ev.stopImmediatePropagation();
            setOpen(!open);
        }} class="btn btn-link text-600 btn-sm dropdown-toggle btn-reveal"
                type="button"
                id="customer-dropdown-0" data-toggle="dropdown" data-boundary="window" aria-haspopup="true"
                aria-expanded="false">
                <${FontAwesomeIcon} icon=${['fas', 'ellipsis-h']} class="fs--1" /></button>
            <div class="dropdown-menu dropdown-menu-right border py-0 ${open ? 'show' : ''}"
                aria-labelledby="customer-dropdown-0">
                <div class="bg-white">
                    <!--<a class="dropdown-item" href="#!">Edit</a>-->
                    <a class="dropdown-item text-danger" onclick=${() => onDeleteClick(object)}>Delete</a></div>
            </div>
        </div>
    </td>
</tr>`;
}



export function ObjectsOverview(name: string, path: string, ObjectRow_: any, props: any) {

    const [session, dispatch] = useSession();

    const [proceduresTypes, setProceduresTypes] = useState<ProcedureType[]>([]);
    const [proceduresCategories, setProceduresCategories] = useState<string[]>([]);

    const [objects, setObjects] = useState<Procedure[]>([]);
    const [sort, setSort] = useState({ field: '', dir: {} as any });

    const onSort = (field: string) => {
        return () => {
            setSort({ field, dir: { [field]: sort.dir[field] == 'asc' ? 'desc' : 'asc' } });
        };
    };

    const page = getPage();

    const q = session.search.trim().toLowerCase();
    const dir = sort.dir[sort.field] == 'asc' ? 1 : -1;
    // const allUsers = searchObjects(session.users, q, sort, dir, page);
    // const users = allUsers.slice((page - 1) * 20, page * 20);



    const onSearch = (q?: any) => {
        Promise.all([
            Api.search(path, q || '', 1, {}, session.token),
            Api.search('/procedures-types', '', 1, {}, session.token),
        ]).then(([data, types, categories]: { data: any[] }[]) => {
            data.data.forEach(([it, share]: any) => {
                it.name = types.data.find((t: any) => t.id == it.type_id)?.name || it.type_id;
                if (share) {
                    share.user_name = (data as any).users.find((t: any) => t.id == share.created_by)?.name;
                }
            });
            setObjects(data.data);
            setProceduresTypes(types.data);
            setProceduresCategories(Array.from(new Set(types.data.map(it => it.category).filter(it => it))));
        });
    };

    useEffect(() => {
        onSearch();
        dispatch({ type: 'onSearch', data: onSearch });
    }, []);




    const onDelete = (object: Procedure) => {
        setObjects(objects.filter(it => it[0].id != object.id));
    }

    return html`<div class="card mb-3" id="customersTable"
    data-list='{"valueNames":["name","email","phone","address","joined"],"page":10,"pagination":true}'>
    <div class="card-header">
        <div class="row flex-between-center">
            <div class="col-4 col-sm-auto d-flex align-items-center pr-0">
                <h5 class="fs-0 mb-0 text-nowrap py-2 py-xl-0">${t(name)}</h5>
            </div>
            <div class="col-8 col-sm-auto text-right pl-2">
                <div id="table-customers-replace-element">
                    <a href="${path}-add" class="btn btn-falcon-default btn-sm" type="button">
                    <span class="fas fa-plus" data-fa-transform="shrink-3 down-2"></span><span
                            class="d-none d-sm-inline-block ml-1">${t('Add')}</span></a>
                </div>
            </div>
        </div>
    </div>
    <div class="card-body p-0" style="height:${window.innerHeight - 240}px;overflow:auto">
        <div class="table-responsive">
            <table class="table table-sm table-striped fs--1 mb-0">
                <thead class="bg-200 text-900">
                    <tr>
                        <th class="no align-middle white-space-nowrap py-2" onclick=${onSort('identifier')}
                            class="sort ${sort.dir.identifier}" style="width:10px" data-sort>
                            ${t('Serial Number')}</th>
                        <th onclick=${onSort('name')}
                            class="sort pr-1 align-middle white-space-nowrap ${sort.dir.name}" data-sort>${t('Name')}</th>
                        <th class="sort align-middle white-space-nowrap desktop" >${t('Date')}</th>
                        <th class="sort align-middle white-space-nowrap desktop" >${t('Accepted Date')}</th>
                        <th class="sort align-middle white-space-nowrap desktop" >${t('Closed Date')}</th>
                        <th class="sort align-middle white-space-nowrap desktop" ></th>
                        <th class="sort pr-1 align-middle white-space-nowrap pl-5 desktop" data-sort="address"
                            style="min-width: 200px;">${t('Result')}</th>
                        <th class="align-middle no-sort"></th>
                    </tr>
                </thead>
                <tbody class="list" id="table-customers-body">
                    ${objects.map(it => ObjectRow(path, it, onDelete))}
                    <br/>
        <br/>
                </tbody>
            </table>
        </div>
    </div>
    <${Pagination} count=${objects.length} page=${page}/>
</div>`;
}

export const UpdateProcedure = (props: any) => UpdateObject(ProcedureForm, '/procedures', props);
export const ProceduresOverview = (props: any) => ObjectsOverview('Procedures', '/procedures', null, props);

function diff(a: any, base: any) {
    const out: any = {};
    for (const key in a) {
        if (a[key] != base[key]) {
            out[key] = a[key];
        }
    }
    return out;
}
/*
export function ProcedureRow(user: ProcedureType) {

    const [open, setOpen] = useState(false);

    useEffect(() => {
        const handler = () => setOpen(false);
        document.addEventListener('click', handler, { passive: true });
        return () => {
            document.removeEventListener('click', handler);
        };
    }, []);

    const [state, dispatch] = useSession();

    function onDeleteClick(user: ProcedureType) {
        dispatch({
            type: 'mutate',
            collection: 'users',
            id: user.id,
            data: { deletedAt: Date.now() },
        });
    };

    return html`<tr class="btn-reveal-trigger">
    <td class="no align-middle white-space-nowrap">${user.id}</td>
    <td class="name align-middle white-space-nowrap">
        <a href="/users/${user.id}">
            <h6>${user.name}</h6>
        </a>
    </td>
    <td class="phone align-middle white-space-nowrap desktop">${(user.roles || []).map(t).join(',')}</td>
    <td class="address align-middle white-space-nowrap pl-5 desktop">${t(user.status)}</td>
<!--    <td class="joined align-middle desktop">${user.status}</td> -->
    <td class="align-middle white-space-nowrap text-right actions-column">
        <div class="dropdown font-sans-serif">
            <button onclick=${(ev: any) => {
            ev.preventDefault();
            ev.stopImmediatePropagation();
            setOpen(!open);
        }} class="btn btn-link text-600 btn-sm dropdown-toggle btn-reveal"
                type="button"
                id="customer-dropdown-0" data-toggle="dropdown" data-boundary="window" aria-haspopup="true"
                aria-expanded="false">
                <${FontAwesomeIcon} icon=${['fas', 'ellipsis-h']} class="fs--1" /></button>
            <div class="dropdown-menu dropdown-menu-right border py-0 ${open ? 'show' : ''}"
                aria-labelledby="customer-dropdown-0">
                <div class="bg-white">
                    <!--<a class="dropdown-item" href="#!">Edit</a>-->
                    <a class="dropdown-item text-danger" onclick=${() => onDeleteClick(user)}>Delete</a></div>
            </div>
        </div>
    </td>
</tr>`;
}*/

function NoValue(label: string) {
    return html`<p class="font-italic text-400 mb-0">${label}</p>`;
}

function Value({ label, value }: { label: string, value: string }) {
    return value ? (html`${value}`) : NoValue(label);
}

export function Note({ id, note: content }: { id: string, note: string }) {


    const [session, dispatch] = useSession();

    const [originalNote, setOriginalNote] = useState(content);

    const [note, setNote] = useInput(content);


    const onSaveClick = () => {
        Api.patch('/procedures', id, { note }, session.token).then(() => {
            setOriginalNote(note);
            toast.success(t('saved successfully'));
        });
    }


    return html`<div class="card mb-3">
                <div class="card-header d-flex flex-between-center bg-light py-2">
                <h5 class="fs-0 mb-0 text-nowrap py-2 py-xl-0">${t('Note')}</h5>
                    <div class="col-auto">
                <a class="btn btn-falcon-default btn-sm" onClick=${onSaveClick}>
                    <${FontAwesomeIcon} icon=${['fas', 'pencil-alt']} />${t('Update')}</a>
            </div>
                </div>
                <div class="card-body">
                    <textarea value=${note} class="form-control" rows="2" style=${note != originalNote ? 'border-color: orange;border-width: unset;' : ''} onInput=${setNote} ></textarea>
                </div>
            </div>`;
}

export function ProcedureDetails({ id }: { id?: string }) {

    const [session, dispatch] = useSession();

    const [procedure, setProcedure] = useState<{ data: Procedure, procedure_type: ProcedureType, contact?: any, entity?: any, entity_contact?: any, shares: any[] } | undefined>();

    useEffect(() => {
        if (id) {
            Api.get('/procedures', id, session.token as any).then((data) => {
                data.shares.forEach((it: any) => {
                    it.created_by_name = data.users.find((t: any) => t.id == it.created_by)?.name;
                    it.user_name = data.users.find((t: any) => t.id == it.user_id)?.name;
                });
                data.data.owner_name = data.users.find((t: any) => t.id == data.data.owner_id)?.name;
                data.data.created_by_name = data.users.find((t: any) => t.id == data.data.created_by)?.name;
                setProcedure(data);
            });
        }
    }, []);


    const [showShare, setShowShare] = useState(false);
    const [shareUser, setShareUser] = useState<any>();
    const [shareValidated, setShareValidated] = useState(false);

    const onShareSubmit = async (ev: Event) => {
        ev.preventDefault();
        ev.stopPropagation();
        setShareValidated(true);
        if (shareUser) {
            Api.post('/procedures/' + id + '/share', { user_id: shareUser.value }, session.token).then(() => {
                setShowShare(false);
                toast.success(t('Procedure shared successfully'));
            });
        }
    };

    const loadOptionsUsers = (inputValue: string) =>
        Api.search('/users', inputValue, 1, {}, session.token).then((res) => {
            return res.data.map((it: any) => ({ label: it.name, value: it.id }));
        });




    if (procedure) {

        const onAcceptClick = () => {
            if (confirm(t('Are you sure you want to accept this procedure?'))) {
                Api.post('/procedures/' + id + '/accept', {}, session.token).then((res) => {
                    setProcedure({ ...procedure, data: { ...procedure.data, accepted_at: res.accepted_at, status: res.status } });
                    toast.success(t('Procedure accepted successfully'));
                });
            }
        };

        const onCloseClick = () => {

            const status = prompt(t('Please enter the status of the procedure on closing'), t('done'));
            if (status) {
                Api.post('/procedures/' + id + '/close', { status }, session.token).then((res) => {
                    setProcedure({ ...procedure, data: { ...procedure.data, closed_at: res.closed_at, status: res.status } });
                    toast.success(t('Procedure closed successfully'));
                });
            }

        };

        return html`<div class="card mb-3">
    <div class="card-header">
        <div class="row align-items-center">
            <div class="col">
                <h5 class="mb-0">#${procedure.data.id}  ${procedure.procedure_type.name}</h5>
            </div>

            ${procedure.data.accepted_at ? '' : html`
            <div class="col-auto">
            <a class="btn btn-falcon-default btn-sm" onClick=${onAcceptClick}>
                <${FontAwesomeIcon} icon=${['fas', 'pencil-alt']} /> ${t('Accept')}</a>
        </div>`}
        ${procedure.data.closed_at ? '' : html`
        <div class="col-auto">
            <a class="btn btn-falcon-default btn-sm" onClick=${onCloseClick}>
                <${FontAwesomeIcon} icon=${['fas', 'pencil-alt']} /> ${t('Close')}</a>
        </div>`}
            <div class="col-auto">
                <a class="btn btn-falcon-default btn-sm" href="/procedures/${id}/edit">
                    <${FontAwesomeIcon} icon=${['fas', 'pencil-alt']} />${t('Update')}</a>
            </div>
            <div class="col-auto">
            <${Share} path="/procedures" id=${id} label="Procedure" />
        </div>
        </div>
    </div>
    <div class="card-body bg-light border-top">
        <div class="row">
            <div class="col-lg col-xxl-5">
                <div class="row">
                    <div class="col-5 col-sm-4">
                        <p class="font-weight-semi-bold mb-1">${t('Taxable Entity')}</p>
                    </div>
                    <div class="col">${procedure.entity?.name}</div>
                </div>
                <div class="row">
                    <div class="col-5 col-sm-4">
                        <p class="font-weight-semi-bold mb-1">${t('Tax Identification Number')}</p>
                    </div>
                    <div class="col">${procedure.entity?.identifier}</div>
                </div>
                <div class="row">
                <div class="col-5 col-sm-4">
                        <p class="font-weight-semi-bold mb-1">${t('Contact Person')}</p>
                    </div>
                    <div class="col">${procedure.contact?.name}</div>
                </div>
                <div class="row">
                    <div class="col-5 col-sm-4">
                        <p class="font-weight-semi-bold mb-1">${t('Contact Person Role')}</p>
                    </div>
                    <div class="col">${procedure.entity_contact?.role}</div>
                </div>
                <div class="row">
                    <div class="col-5 col-sm-4">
                        <p class="font-weight-semi-bold mb-0">${t('Address')}</p>
                    </div>
                    <div class="col">
                        <${Value} label="Pas d'addresse" value=${procedure.entity.address || procedure.city?.label} />
                    </div>
                </div>
            </div>
            <div class="col-lg col-xxl-5 mt-4 mt-lg-0 offset-xxl-1">
                <div class="row">
                    <div class="col-5 col-sm-4">
                        <p class="font-weight-semi-bold mb-1">${t('Status')}</p>
                    </div>
                    <div class="col">${t(procedure.data.status)}</div>
                </div>
                    <div class="row">
                    <div class="col-5 col-sm-4">
                        <p class="font-weight-semi-bold mb-1">${t('Date')}</p>
                    </div>
                    <div class="col">${procedure.data.created_at}</div>
                </div>
                <div class="row">
                    <div class="col-5 col-sm-4">
                        <p class="font-weight-semi-bold mb-1">${t('Accepted Date')}</p>
                    </div>
                    <div class="col">${procedure.data.accepted_at}</div>
                </div>
                <div class="row">
                    <div class="col-5 col-sm-4">
                        <p class="font-weight-semi-bold mb-1">${t('Closed Date')}</p>
                    </div>
                    <div class="col">${procedure.data.closed_at}</div>
                </div>

                <div class="row">
                    <div class="col-5 col-sm-4">
                        <p class="font-weight-semi-bold mb-1">${t('Owner')}</p>
                    </div>
                    <div class="col">${procedure.data.owner_name}</div>
                </div>
            </div>
        </div>
    </div>
</div>

<${Note} id=${id} note=${procedure.data.note} />

    <${Drive} procedureId=${procedure.data.id} readonly=${procedure.data.accepted_at} />


    ${procedure.shares.length ? html`
    <div class="card mb-3">
        <div class="card-header">
            <h5 class="mb-0">${t('Shares')}</h5>
        </div>
        <div class="card-body bg-light">
            ${procedure.shares.map((it: any) => html`<div class="row">
                <div class="col">
                    <p>${t('Shared by')} <b>${it.created_by_name}</b> ${t('to')} <b>${it.user_name}</b> ${t('at')} <b>${new Date(it.created_at).toDateString()}</b></p>
                </div>
                <div class="col">
                    <button class="btn btn-danger btn-sm" onClick=${() => {
                Api.remove('/shares/' + it.id, session.token).then(() => {
                    setProcedure({ ...procedure, shares: procedure.shares.filter((t: any) => t.id != it.id) });
                    toast.success(t('Share removed successfully'));
                }
                );
            }
            }>${t('Remove')}</button>
            
                </div>
            </div>`)
                }
        </div>
    </div>` : ''}
`;

    } else {
        return html`<div>User ${id} not found</div>`;
    }
}


export interface Option {
    label: string;
    value: string;
}

export function Share(props: { path: string, id: number, label: string, onShare?: any }) {

    const Access = [
        { label: t('Download'), value: 'download' },
        { label: t('Preview'), value: 'preview' },
        { label: t('Update'), value: 'update' },
    ];

    const [session] = useSession();

    const [showShare, setShowShare] = useState(false);
    const [shareUser, setShareUser] = useState<Option>();
    const [access, setAccess] = useState<any>();
    const [validated, setValidated] = useState(false);

    const [office, setOffice] = useState<Option>();

    const onShareSubmit = async (ev: Event) => {

        ev.preventDefault();
        ev.stopPropagation();
        setValidated(true);
        if (shareUser && access) {
            Api.post(props.path + '/' + props.id + '/share', { user_id: shareUser.value, access: access.value }, session.token).then((share) => {
                setShowShare(false);
                toast.success(t(props.label) + ' ' + t('shared successfully'));
                if (props.onShare) props.onShare(share);
            });
        }
    };

    const loadOptionsUsers = (inputValue: string) =>

        Api.search('/users', inputValue, 1, {}, session.token).then((res) => {
            return res.data.filter(it => office ? it.office_id == office.value : true).map((it: any) => ({ label: it.name, value: it.id }));
        });

    const loadOptionsOffices = (inputValue: string) =>
        Api.search('/offices', inputValue, 1, {}, session.token).then((res) => {
            return res.data.map((it: any) => ({ label: it.name, value: it.id }));
        });


    return html`<a onClick=${() => setShowShare(true)} class="btn btn-falcon-primary btn-sm" type="button">${t('Share')} ${t(props.label)}</a>
    <${Modal} show=${showShare} onHide=${() => setShowShare(false)} backdrop="static" keyboard=${false}>
        <${Modal.Header} closeButton>
        <${Modal.Title}>${t('Share')}</Modal.Title>
        </${Modal.Header}>
        <${Modal.Body}>
            <div class="col-lg-6">
        <label class="form-label" for="email3">${t('Office')}</label>
        <${AsyncSelect}  cacheOptions defaultOptions isClearable loadOptions=${loadOptionsOffices}  value=${office} onChange="${setOffice}"
            className="rs form-control" />
        </div>
        <div class="col-lg-6">
        <label class="form-label" for="email3">${t('User')}</label>
        <${AsyncSelect}  isClearable loadOptions=${loadOptionsUsers}  value=${shareUser} onChange="${setShareUser}"
            className="rs form-control ${validated && (shareUser ? 'is-valid' : 'is-invalid')}" />
        </div>
        <div class="col-lg-6">
            <label class="form-label" for="type">${t('Access')}</label>
            <${Select} value=${access}
            options=${Access} onChange=${setAccess}
            className="rs form-control ${validated && (access ? 'is-valid' : 'is-invalid')}" />
        </div>
        </${Modal.Body}>
        <${Modal.Footer}>
        <${Button} variant="secondary" onClick=${() => setShowShare(false)}>
            Close
            </${Button}>
        <${Button} variant="primary" onClick=${onShareSubmit}>${t('Add')}</${Button}>
        </${Modal.Footer}>
    </${Modal}>`;
}