import { html } from "htm/preact";
import { generateId, getPage, Pagination, useInput } from "./core";
import { useEffect, useRef, useState } from "preact/hooks";


import { Api, loadDrug, searchDrugs, searchDrugsDebounced, searchObjects, useSession } from "./store";
import { Patient, Prescription } from "./shared/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { route } from "preact-router";
import { toast } from "react-toastify";
import Select from 'react-select';
import CreatableSelect from "react-select/creatable";

import QRCode from 'qrcode.react';
import { Editor } from '@tinymce/tinymce-react';
import { PrintPreview } from "./print-editor";




export function DrugsOverview(props: any) {

    const [state] = useSession();

    const [sort, setSort] = useState({ field: '', dir: {} as any });
    const [drugs, setDrugs] = useState({ data: [], count: 0 });
    const [search, setSearch] = useState(state.search);
    const [page, setPage] = useState(0);

    const [hospital, setHospital] = useState(false);

    let urlPage = getPage();

    if (state.search != search || urlPage != page) {
        if (state.search != search) {
            route(window.location.pathname);
            urlPage = 1;
            setPage(urlPage);
            setSearch(state.search);
            searchDrugs(state.search, urlPage, { hospital }).then(setDrugs);
        } else {
            setPage(urlPage);
            setSearch(state.search);
            searchDrugsDebounced(state.search, urlPage, { hospital }, setDrugs);
        }
    }



    const onSort = (field: string) => {
        return () => {
            setSort({ field, dir: { [field]: sort.dir[field] == 'asc' ? 'desc' : 'asc' } });
        };
    };
    /*
        useEffect(()=>{
            searchDrugs('',page).then(setDrugs);
        },[]);
    */


    return html`
    <style>
        .drugs .ma {
            width: 100px;
        }
        .drugs .price {
            min-width: 80px;
        }
        .drugs .actions {
            width: 60px;
        }
    </style>
    <div class="card mb-3 drugs">
    <div class="card-header">
        <div class="row flex-between-center">
            <div class="col-4 col-sm-auto d-flex align-items-center pr-0">
                <h5 class="fs-0 mb-0 text-nowrap py-2 py-xl-0">Médicaments</h5>
            </div>
            <div class="col-8 col-sm-auto text-right pl-2">
                <div id="table-customers-replace-element">

                    <div class="form-check" style="display: unset;margin-right: 20px;padding: 0px;">
                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault"  onclick=${(ev: any) => {
            setHospital(ev.target.checked);
            searchDrugs(state.search, page, { hospital: ev.target.checked }).then(setDrugs);
        }}/>
                        <label class="form-check-label" for="flexCheckDefault">
                            Hopital ${hospital}
                        </label>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="card-body p-0" style="height:${window.innerHeight - 240}px;overflow:auto">
        <div class="">
            <table class="table table-sm table-striped fs--1 mb-0">
                <thead class="bg-200 text-900">
                    <tr class="d-flex">
                    
                        <th>
                            Visible
                        </th>
                        <th onclick=${onSort('no')}
                            class="align-middule sort ${sort.dir.no} ma desktop" data-sort>
                            AMM</th>
                        <th onclick=${onSort('firstName')}
                            class="col sort align-middle ${sort.dir.firstName}" data-sort>
                            Nom</th>
                      <!--  <th onclick=${onSort('email')}
                            class="sort pr-1 align-middle white-space-nowrap ${sort.dir.email}" data-sort>Email
                        </th> -->
                        <th class="col-3 sort align-middle desktop" >Generique</th>
                        <th onclick=${onSort('price')}
                            class="col-1 sort text-right ${sort.dir.price} price" data-sort="price">
                            Prix</th>
                        <th class="align-middle no-sort actions"></th>
                    </tr>
                </thead>
                <tbody class="list" id="table-customers-body">
                    ${drugs.data.map(DrugRow)}
                    <br/>
        <br/>
                </tbody>
            </table>
        </div>
    </div>
    <${Pagination} count=${drugs.count} page=${page} pageSize=${100}/>
</div>`;
}


export function DrugRow(drug: any) {

    const [open, setOpen] = useState(false);

    useEffect(() => {
        const handler = () => setOpen(false);
        document.addEventListener('click', handler, { passive: true });
        return () => {
            document.removeEventListener('click', handler);
        };
    }, [])

    const [state, dispatch] = useSession();

    let visibleDrugs = (state.organizations[0] || {}).visibleDrugs || [];

    function onDeleteClick(patient: Patient) {
        dispatch({
            type: 'mutate',
            collection: 'patients',
            id: patient._id,
            data: { deletedAt: Date.now() },
        });
    };

    return html`<tr class="btn-reveal-trigger d-flex">

    <td class="align-middle actions">
    <span class="form-check form-switch">
            <input onclick=${(ev: any) => {
            if (ev.target.checked) {
                visibleDrugs = [...visibleDrugs, drug._id];
            } else {
                visibleDrugs = [...visibleDrugs];
                visibleDrugs.splice(visibleDrugs.indexOf(drug._id), 1);
            }
            dispatch({ type: 'mutate', collection: 'organizations', data: { visibleDrugs: [...visibleDrugs] }, id: state.user?.organizationId });
        }} checked=${visibleDrugs.indexOf(drug._id) !== -1} class="form-check-input" type="checkbox" id="flexSwitchCheckDefault"/>
        </span>
    </td>

    <td class="align-middle ma desktop">${drug._id}</td>
    <td class="col text-break align-middle">
        <a class="bolder-black" href="/drugs/${drug._id}">
            <h6 style="margin-top:2px"> ${drug.fullname || drug.name}</h6>
        </a></td>
    <td class="col-3 text-break align-middle desktop">${drug.generic_name}</td>
    <td class="col-1 text-right price">${drug.price?.toFixed(3)} DT</td>
    <td class="text-right actions">
        <div class="dropdown font-sans-serif">
            <button onclick=${(ev: any) => {
            ev.preventDefault();
            ev.stopImmediatePropagation();
            setOpen(!open);
        }} class="btn btn-link text-600 btn-sm dropdown-toggle btn-reveal"
                type="button"
                id="customer-dropdown-0" data-toggle="dropdown" data-boundary="window" aria-haspopup="true"
                aria-expanded="false">
                <${FontAwesomeIcon} icon=${['fas', 'ellipsis-h']} class="fs--1" /></button>
            <div class="dropdown-menu dropdown-menu-right border py-0 ${open ? 'show' : ''}"
                aria-labelledby="customer-dropdown-0">
                <div class="bg-white py-2">
                    <!--<a class="dropdown-item" href="#!">Edit</a>-->
                    <a class="dropdown-item text-danger" onclick=${() => onDeleteClick(drug)}>Delete</a></div>
            </div>
        </div>
    </td>
</tr>`;
}





export function PrescriptionForm({ patient }: { patient: Patient }) {

    const [drug, setDrug] = useState(null as any);
    const [quantity, setQuantity] = useInput(1);
    const [posology, setPosology] = useState(null as any);
    const [note, setNote] = useState('');
    const [lines, setLines] = useState([] as any[]);
    const [validated, setValidated] = useState(false);

    const today = new Date();
    const todayDate = `${today.getFullYear()}-${(today.getMonth() + 1).toString().padStart(2, '0')}-${today.getDate().toString().padStart(2, '0')}`;

    const [date, setDate] = useInput(todayDate);

    const [state, dispatch] = useSession();

    const drugsOptions = state.drugs.map(it => ({ label: it.fullname || it.name, value: it._id }));
    const posologiesOptions: any[] = [];

    function onAddClick(ev: any) {
        ev.preventDefault();
        ev.stopPropagation();
        setValidated(true);
        if ((ev.target as any).checkValidity() && drug) {
            setLines(lines.concat([{ drug, posology, note }]));

            setValidated(false);
            setDrug(null);
            setNote('');
            setPosology(null);
        }
    }

    function doSetDrug(drug: any) {
        setDrug(drug);
        const lastUsedPosology = state.prescriptions
            .sort((a, b) => a.date < b.date ? 1 : -1)
            .find(it => it.lines.find(it => it.drug?.value == drug.value))?.lines
            .find(it => it.drug?.value == drug.value)?.posology;
        setPosology(lastUsedPosology);
    }

    const createPrescription = () => {
        if (lines.length) {

            const id = generateId(state.user?.organizationId || '');

            dispatch({
                type: 'mutate',
                collection: 'prescriptions',
                id,
                data: {
                    patientId: patient._id,
                    insurance: patient.insurance,
                    insuranceId: patient.insuranceId,
                    lines,
                    date,
                },
            });

            toast.success('Ok');
            route('/prescriptions/' + id);
        }

    };

    function handleCreate(value: any) {
        dispatch({
            type: 'mutate',
            collection: 'options',
            id: generateId(state.user?.organizationId || ''),
            data: { type: 'posology', value },
        });
        setPosology({ label: value, value });
    };

    const allPosologiesOptions = posologiesOptions
        .concat((state.options)
            .filter(it => it.type == 'posology')
            .map(it => ({ label: it.value, value: it.value })));


    return html`<div class="row">

    <div class="col-lg-6">
    <form onsubmit="${onAddClick}" class="row g-3 needs-validation ${validated ? 'was-validated' : ''}" novalidate>
        
            <div class="col-lg-9">
                <label class="form-label" for="email3">Médiament</label>
                <${Select} options=${drugsOptions} value=${drug} onChange="${doSetDrug}"
                    className="rs form-control ${validated && (drug ? 'is-valid' : 'is-invalid')}" />
            </div>
            <div class="col-lg-3">
                <label class="form-label" for="last-name">Quantité</label>
                <input pattern=".{3,}" class="form-control" id="last-name" type="number" oninput="${setQuantity}" value=${quantity} />
            </div>
        
        <div>
            <label class="form-label" for="email3">Posologie</label>
            <${CreatableSelect} isClearable onCreateOption=${handleCreate} options=${allPosologiesOptions} value=${posology} onChange="${setPosology}"
                className="rs form-control ${validated && (posology ? 'is-valid' : 'is-invalid')}" />
        </div>
        <div>
            <label class="form-label" for="last-name">Note</label>
            <textarea pattern=".{3,}" class="form-control" id="last-name" type="text" oninput="${(ev: any) => setNote(ev.target.value)}"
            value="${note}" />
        </div>
        <br/>
        <div class="d-flex justify-content-end">
            <button class="btn btn-primary" type="submit">Ajouter la ligne</button>
        </div>
        </form>
    </div>
    <div class="col-lg-6" style="padding-left:30px">
    <div class="row">
        <div class="col-6"></div>
        <div class="col-6">
            <input pattern=".{3,}" class="form-control" type="date" oninput="${setDate}"
            value="${date}" />
        </div>
        </div>
        <div style="margin-bottom:15px">
            <label class="form-label">Nom de malade</label>
            <h5> ${patient?.firstName} ${patient?.lastName}</h5>
        </div>
        <div style="margin-bottom:15px">
            <label class="form-label">${patient.insurance?.label}</label>
            <h5> ${patient?.insuranceId}</h5>
        </div>
        <div>
            <label class="form-label">Médicaments</label>
            ${lines.map(line => html`<div>
                <div><h5> ${line.drug.label}</h5></div>
                <div>${line.posology?.label}</div>
                <div>${line.note}</div>
            </div>`)} 
        </div>
    </div>
</div>
    <div class="col-12 d-flex justify-content-end">
        <button onclick=${createPrescription} class="btn btn-primary" type="submit">Créer</button>
    </div>
    <!--<${DrugView} id=${drug?.value}/>-->
`;
}


export function AddPrescription({ id }: { id?: string }) {

    const [session, dispatch] = useSession();
    const patient = session.patients.find(it => it._id == id);

    return html`<div class="row g-0">
    <div>
        <div class="card mb-3">
            <div class="card-header">
                <h5 class="mb-0">Ajouter Prescription</h5>
            </div>
            <div class="card-body bg-light">
                <${PrescriptionForm} patient=${patient} />
            </div>
        </div>
    </div>
</div>`;
}

export function AddExams({ id }: { id?: string }) {

    const [session, dispatch] = useSession();
    const patient = session.patients.find(it => it._id == id);

    return html`<div class="row g-0">
    <div>
        <div class="card mb-3">
            <div class="card-header">
                <h5 class="mb-0">Demander des Examens</h5>
            </div>
            <div class="card-body bg-light">
                <${ExamsForm} patient=${patient} />
            </div>
        </div>
    </div>
</div>`;
}


export function AddAttendanceCertificate({ id }: { id?: string }) {

    const [session, dispatch] = useSession();
    const patient = session.patients.find(it => it._id == id);

    return html`<div class="row g-0">
    <div>
        <div class="card mb-3">
            <div class="card-header">
                <h5 class="mb-0">Demander des Examens</h5>
            </div>
            <div class="card-body bg-light">
                <${AttendanceCertificateForm} patient=${patient} />
            </div>
        </div>
    </div>
</div>`;
}
export function AddMedicalCertificate({ id }: { id?: string }) {

    const [session, dispatch] = useSession();
    const patient = session.patients.find(it => it._id == id);

    return html`<div class="row g-0">
    <div>
        <div class="card mb-3">
            <div class="card-header">
                <h5 class="mb-0">Certificat Médical</h5>
            </div>
            <div class="card-body bg-light">
                <${MedicalCertificateForm} patient=${patient} />
            </div>
        </div>
    </div>
</div>`;
}


export function PrescriptionView({ id }: { id: string }) {

    const [state, dispatch] = useSession();
    const prescription = state.prescriptions.find(it => it._id == id);
    if (prescription) {
        const patient = state.patients.find(it => it._id == prescription.patientId);


        function cancel() {
            if (confirm('Ete vous sure de vouloir annuler cette ordonnace?')) {
                dispatch({
                    type: 'mutate',
                    id: prescription?._id,
                    collection: 'prescriptions',
                    data: { deletedAt: Date.now() },
                })
            }
        }

        const template = state.organizations[0]?.prescriptionTemplate;
        if (!template) {
            alert('Vous devez d\'abord configurer le modèle!');
            route('/settings/prescription');
            return;
        }

        return html`<${PrintPreview} template=${template} prescription=${prescription} patient=${patient}></${PrintPreview}>`;
    } else {
        return html`<div>NOT FOUND</div>`;
    }
}


export function ExamsView({ id }: { id: string }) {

    const [state, dispatch] = useSession();
    const exams = state.exams.find(it => it._id == id);
    if (exams) {
        const patient = state.patients.find(it => it._id == exams.patientId);


        function cancel() {
            if (confirm('Ete vous sure de vouloir annuler cette ordonnace?')) {
                dispatch({
                    type: 'mutate',
                    id: exams?._id,
                    collection: 'prescriptions',
                    data: { deletedAt: Date.now() },
                })
            }
        }

        const template = state.organizations[0]?.examsTemplate;
        if (!template) {
            alert('Vous devez d\'abord configurer le modèle!');
            route('/settings/exams');
            return;
        }

        return html`<${PrintPreview} template=${template} exams=${exams} patient=${patient}></${PrintPreview}>`;
    } else {
        return html`<div>NOT FOUND</div>`;
    }
}

export function CertificateView({ id }: { id: string }) {

    const [state, dispatch] = useSession();
    const certificate = state.certificates.find(it => it._id == id);
    if (certificate) {
        const patient = state.patients.find(it => it._id == certificate.patientId);


        function cancel() {
            if (confirm('Ete vous sure de vouloir annuler cette ordonnace?')) {
                dispatch({
                    type: 'mutate',
                    id: certificate?._id,
                    collection: 'certificates',
                    data: { deletedAt: Date.now() },
                })
            }
        }

        if (certificate.kind == 'attendance') {
            const template = state.organizations[0].attendanceCertificateTemplate;
            if (!template) {
                alert('Vous devez d\'abord configurer le modèle!');
                route('/settings/attendance-certificate');
                return;
            }

            return html`<${PrintPreview} template=${template} attendanceCertificate=${certificate} patient=${patient}></${PrintPreview}>`;
        } else {
            const template = state.organizations[0].medicalCertificateTemplate;
            if (!template) {
                alert('Vous devez d\'abord configurer le modèle!');
                route('/settings/medical-certificate');
                return;
            }
            return html`<${PrintPreview} template=${template} medicalCertificate=${certificate} patient=${patient}></${PrintPreview}>`;
        }

    } else {
        return html`<div>NOT FOUND</div>`;
    }
}

export function DrugView({ id }: { id: string }) {

    const [drug, setDrug] = useState(null as any);


    useEffect(() => {
        if (id) {
            loadDrug(id).then(setDrug);
        }
    }, [id]);

    if (drug) {
        drug.hospital = drug._id.endsWith('H');

        return html`<div class="row g-0">
        <div>
            <div class="card mb-3">
                <div class="card-header">
                    <h5 class="mb-0">${drug.fullname || drug.name}  
                    ${drug.hospital ? html`<${FontAwesomeIcon} icon=${['fa', 'hospital']}/>` : ''}</h5>
                    
                </div>
                <div class="card-body">

                        <table class="table fs--1 mt-3">
                          <tbody>
                            <tr>
                              <td class="bg-100" style="width: 30%;">Autorisation de mise sur le marché</td>
                              <td>N° <strong>${drug._id}</strong> le <strong>${drug.ma_date}</strong></td>
                            </tr>
                            <tr>
                              <td class="bg-100" style="width: 30%;">veic_class</td>
                              <td>${drug.veic_class}</td>
                            </tr>
                            <tr>
                              <td class="bg-100" style="width: 30%;">sub_class</td>
                              <td>${drug.sub_class} / ${drug.therapeutic_class}</td>
                            </tr>
                            <tr>
                              <td class="bg-100" style="width: 30%;">generic_name</td>
                              <td>${drug.generic_name}</td>
                            </tr>
                            <tr>
                              <td class="bg-100" style="width: 30%;">posology</td>
                              <td>${drug.posology}</td>
                            </tr>
                            <tr>
                              <td class="bg-100" style="width: 30%;">indication</td>
                              <td>${drug.indication}</td>
                            </tr>
                            <tr>
                              <td class="bg-100" style="width: 30%;">packaging</td>
                              <td>${drug.packaging}</td>
                            </tr>
                            <tr>
                              <td class="bg-100" style="width: 30%;">form</td>
                              <td>${drug.form}</td>
                            </tr>
                            <tr>
                              <td class="bg-100" style="width: 30%;">specification</td>
                              <td>${drug.specification}</td>
                            </tr>
                            <tr>
                              <td class="bg-100" style="width: 30%;">shelf_life</td>
                              <td>${drug.shelf_life}</td>
                            </tr>
                            <tr>
                              <td class="bg-100" style="width: 30%;">code</td>
                              <td>${drug.code}</td>
                            </tr>
                            <tr>
                              <td class="bg-100" style="width: 30%;">table</td>
                              <td>${drug.table}</td>
                            </tr>
                            <tr>
                              <td class="bg-100" style="width: 30%;">princeps</td>
                              <td>${drug.princeps}</td>
                            </tr>
                            <tr>
                              <td class="bg-100" style="width: 30%;">laboratory</td>
                              <td>${drug.laboratory} - ${drug.country}</td>
                            </tr>
                            <tr>
                              <td class="bg-100" style="width: 30%;">price</td>
                              <td>${drug.price} DT</td>
                            </tr>
                          </tbody>
                        </table>
                </div>
            </div>
        </div>
    </div>`

    } else {
        return html`<div>loading...</div>`;
    }

}





export function PrescriptionSettings() {

    const [state, dispatch] = useSession();


    const [header, setHeader] = useState(state.organizations[0]?.prescriptions?.header);
    const [footer, setFooter] = useState(state.organizations[0]?.prescriptions?.footer);

    const [validated, setValidated] = useState(false);


    const editorRef = useRef(null as any);
    const log = () => {
        if (editorRef.current) {
            console.log(editorRef.current.getContent());
        }
    };


    function onSaveClick(ev: any) {
        ev.preventDefault();
        ev.stopPropagation();
        setValidated(true);
        if ((ev.target as any).checkValidity()) {
            setHeader(editorRef.current.getContent());
            const settings = { prescriptions: { header: editorRef.current.getContent(), footer } };
            Api.put('/settings', '', settings, state.token)
                .then(res => {
                    dispatch({ type: 'settings', data: settings });
                    toast.success('Paramètres mis à jour');
                });
        }
    }



    return html`<form onsubmit="${onSaveClick}" class="row g-3 needs-validation ${validated ? 'was-validated' : ''}" novalidate>
            <div>
                <label class="form-label" for="header">En Tête</label>
                <${Editor}
          onInit=${(evt, editor) => editorRef.current = editor}
          initialValue=${header}
          init=${{
            height: 500,
            menubar: false,
            plugins: [
                'advlist autolink lists link image charmap print preview anchor',
                'searchreplace visualblocks code fullscreen',
                'insertdatetime media table paste code help wordcount'
            ],
            toolbar: 'undo redo | formatselect | ' +
                'bold italic backcolor | alignleft aligncenter ' +
                'alignright alignjustify | bullist numlist outdent indent | ' +
                'removeformat | help',
            force_p_newlines: false,
            force_br_newlines: false,

            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
        }}
        />

            </div>
            <div>
                <label class="form-label" for="footer">Pied du page</label>
                <textarea pattern=".{3,}" class="form-control" id="footer" type="text" oninput="${(ev: any) => setFooter(ev.target.value)}"
                value="${footer}" />
            </div>
            <button class="btn btn-primary" type="submit">Enregistrer</button>
        </form>`;
}


export function PrescriptionPublicView({ id }: { id: any }) {

    const [data, setData] = useState({ lines: [] } as any);

    useEffect(() => {
        Api.get('/p', id, '').then(res => {
            console.log('PRESCRIPTION', res);
            setData(res);
        });
    });


    return html`<div class="container">
    <div class="prescription" >
        <div class="row">
        <div class="col" style="min-height:100px" innerHTML=${data?.header}></div>
        <div class="col" style="text-align:right"> <${QRCode} value="https://Finances.tn/p/${data._id}?${data?.firstName}*${data?.lastName}*${data.date}*${data.lines.map(it => it.drug.value).join('*')}"/> </div>

        </div>
        <div class="date">
            <label class="form-label">Date</label>
            <h5>${data.date}</h5>
        </div>
        <div class="patient">
            <label class="form-label">Nom du malade</label>
            <h5> ${data?.firstName} ${data?.lastName}</h5>
        </div>
        <div class="insurance">
            <label class="form-label">${data?.insurance?.label}</label>
            <h5> ${data?.insuranceId}</h5>
        </div>
        <div class="drugs">
            <label class="form-label">Médicaments</label>
            ${data.lines.map(line => html`<div>
                <div><h5> ${line.drug?.label}</h5></div>
                <div class="posology">${line.posology?.label}</div>
                <div class="note">${line.note}</div>
            </div>`)} 
        </div>
        <br/><br/>
        <div innerHTML=${data.footer}></div>

    </div>
    </div>`;

}




export function PrescriptionsOverview(props: any) {

    const [session] = useSession();

    const [sort, setSort] = useState({ field: '', dir: {} as any });

    const [rows, setRows] = useState([] as (Prescription & Patient)[]);

    useEffect(() => {
        setRows(session.prescriptions
            .sort((p, n) => p.timestamps._id > n.timestamps._id ? -1 : 1).map(prescription => ({
                ...session.patients.find(it => it._id == prescription.patientId) || {} as any,
                ...prescription,
            })) as any);
    }, [session]);

    const onSort = (field: string) => {
        return () => {
            setSort({ field, dir: { [field]: sort.dir[field] == 'asc' ? 'desc' : 'asc' } });
        };
    };

    const page = getPage();

    const q = session.search.trim().toLowerCase();
    const dir = sort.dir[sort.field] == 'asc' ? 1 : -1;
    const prescriptions = searchObjects(rows, q, sort, dir, page);


    return html`<div class="card mb-3" id="customersTable"
    data-list='{"valueNames":["name","email","phone","address","joined"],"page":10,"pagination":true}'>
    <div class="card-header">
        <div class="row flex-between-center">
            <div class="col-4 col-sm-auto d-flex align-items-center pr-0">
                <h5 class="fs-0 mb-0 text-nowrap py-2 py-xl-0">Patients</h5>
            </div>
            <div class="col-8 col-sm-auto text-right pl-2">
                <div id="table-customers-replace-element">
                    <a href="/patients-add" class="btn btn-falcon-default btn-sm" type="button"><span
                            class="fas fa-plus" data-fa-transform="shrink-3 down-2"></span><span
                            class="d-none d-sm-inline-block ml-1">Ajouter</span></a>
                            <!--
                    <a class="btn btn-falcon-default btn-sm mx-2" type="button"><span class="fas fa-filter"
                            data-fa-transform="shrink-3 down-2"></span><span
                            class="d-none d-sm-inline-block ml-1">Filter</span></a>
                    <a class="btn btn-falcon-default btn-sm" type="button"><span class="fas fa-external-link-alt"
                            data-fa-transform="shrink-3 down-2"></span><span
                            class="d-none d-sm-inline-block ml-1">Export</span></a>
-->
                </div>
            </div>
        </div>
    </div>
    <div class="card-body p-0" style="height:${window.innerHeight - 240}px;overflow:auto">
        <div class="table-responsive">
            <table class="table table-sm table-striped fs--1 mb-0">
                <thead class="bg-200 text-900">
                    <tr>
                    <!--
                        <th>
                            <div class="form-check fs-0 mb-0 d-flex align-items-center">
                                <input class="form-check-input" id="checkbox-bulk-customers-select" type="checkbox"
                                    data-bulk-select='{"body":"table-customers-body","actions":"table-customers-actions","replacedElement":"table-customers-replace-element"}' />
                            </div>
                        </th>-->
                        <th class="no align-middle white-space-nowrap py-2" onclick=${onSort('no')}
                            class="sort ${sort.dir.no}" style="width:10px" data-sort>
                            N°</th>
                        <th onclick=${onSort('firstName')}
                            class="sort pr-1 align-middle white-space-nowrap ${sort.dir.firstName}" data-sort>
                            Nom</th>
                      <!--  <th onclick=${onSort('email')}
                            class="sort pr-1 align-middle white-space-nowrap ${sort.dir.email}" data-sort>Email
                        </th> -->
                        <th class="sort align-middle white-space-nowrap desktop" >Téléphone</th>
                        <th class="sort pr-1 align-middle white-space-nowrap pl-5 desktop" data-sort="address"
                            style="min-width: 200px;">Addresse</th>
                        <th onclick=${onSort('birthdate')}
                            class="sort pr-1 align-middle white-space-nowrap ${sort.dir.birthdate}  desktop" data-sort="joined">
                            Date de naissance</th>
                        <th class="align-middle no-sort"></th>
                    </tr>
                </thead>
                <tbody class="list" id="table-customers-body">
                    ${prescriptions.map(it => PrescriptionRow(it))}
                    <br/>
        <br/>
                </tbody>
            </table>
        </div>
    </div>
    <${Pagination} count=${rows.length} page=${page}/>
</div>`;
}


export function PrescriptionRow(row: Prescription & Patient, collection = 'prescriptions') {

    const [open, setOpen] = useState(false);

    useEffect(() => {
        const handler = () => setOpen(false);
        document.addEventListener('click', handler, { passive: true });
        return () => {
            document.removeEventListener('click', handler);
        };
    }, [])

    const [state, dispatch] = useSession();


    function onDeleteClick(patient: Patient) {
        dispatch({
            type: 'mutate',
            collection,
            id: patient._id,
            data: { deletedAt: Date.now() },
        });
    };

    return html`<tr class="btn-reveal-trigger">
    <!--
    <td class="align-middle py-2" style="width: 28px;">
        <div class="form-check fs-0 mb-0 d-flex align-items-center">
            <input class="form-check-input" type="checkbox" id="customer-0"
                data-bulk-select-row="data-bulk-select-row" />
        </div>
    </td>
    -->
    <td class="no align-middle white-space-nowrap">${row._id}</td>
    <td class="name align-middle white-space-nowrap"><a href="/${collection}/${row._id}">
                    <h6>${row.date} ${row.firstName} ${row.lastName}</h6>
        </a></td>
    <td class="phone align-middle white-space-nowrap desktop">${(row.phones || [])[0]}</td>
    <td class="address align-middle white-space-nowrap pl-5 desktop">${row.address} ${row.city?.label}</td>
    <td class="joined align-middle desktop">${row.birthdate}</td>
    <td class="align-middle white-space-nowrap text-right actions-column">
        <div class="dropdown font-sans-serif">
            <button onclick=${(ev: any) => {
            ev.preventDefault();
            ev.stopImmediatePropagation();
            setOpen(!open);
        }} class="btn btn-link text-600 btn-sm dropdown-toggle btn-reveal"
                type="button"
                id="customer-dropdown-0" data-toggle="dropdown" data-boundary="window" aria-haspopup="true"
                aria-expanded="false">
                <${FontAwesomeIcon} icon=${['fas', 'ellipsis-h']} class="fs--1" /></button>
            <div class="dropdown-menu dropdown-menu-right border py-0 ${open ? 'show' : ''}"
                aria-labelledby="customer-dropdown-0">
                <div class="bg-white">
                    <!--<a class="dropdown-item" href="#!">Edit</a>-->
                    <a class="dropdown-item text-danger" onclick=${() => onDeleteClick(row)}>Delete</a></div>
            </div>
        </div>
    </td>
</tr>`;
}


export function ExamsForm({ patient }: { patient: Patient }) {

    const [exam, setExam] = useState(null as any);
    const [note, setNote] = useState('');
    const [lines, setLines] = useState([] as any[]);
    const [validated, setValidated] = useState(false);

    const today = new Date();
    const todayDate = `${today.getFullYear()}-${(today.getMonth() + 1).toString().padStart(2, '0')}-${today.getDate().toString().padStart(2, '0')}`;

    const [date, setDate] = useInput(todayDate);

    const [state, dispatch] = useSession();

    const examsOptions: any[] = [];

    function onAddClick(ev: any) {
        ev.preventDefault();
        ev.stopPropagation();
        setValidated(true);
        if ((ev.target as any).checkValidity() && exam) {
            setLines(lines.concat([{ exam, note }]));

            setValidated(false);
            setNote('');
            setExam(null);
        }
    }

    const createExams = () => {
        if (lines.length) {

            const id = generateId(state.user?.organizationId || '');

            dispatch({
                type: 'mutate',
                collection: 'exams',
                id,
                data: {
                    patientId: patient._id,
                    insurance: patient.insurance,
                    insuranceId: patient.insuranceId,
                    lines,
                    date,
                },
            });

            toast.success('Ok');
            route('/exams/' + id);
        }

    };

    function handleCreate(value: any) {
        dispatch({
            type: 'mutate',
            collection: 'options',
            id: generateId(state.user?.organizationId || ''),
            data: { type: 'exams', value },
        });
        setExam({ label: value, value });
    };

    const allExamsOptions = examsOptions
        .concat((state.options)
            .filter(it => it.type == 'exams')
            .map(it => ({ label: it.value, value: it.value })));


    return html`<div class="row">

    <div class="col-lg-6">
    <form onsubmit="${onAddClick}" class="row g-3 needs-validation ${validated ? 'was-validated' : ''}" novalidate>
        <div>
            <label class="form-label" for="email3">Examen</label>
            <${CreatableSelect} isClearable onCreateOption=${handleCreate} options=${allExamsOptions} value=${exam} onChange="${setExam}"
                className="rs form-control ${validated && (exam ? 'is-valid' : 'is-invalid')}" />
        </div>
        <div>
            <label class="form-label" for="last-name">Note</label>
            <textarea pattern=".{3,}" class="form-control" id="last-name" type="text" oninput="${(ev: any) => setNote(ev.target.value)}"
            value="${note}" />
        </div>
        <br/>
        <div class="d-flex justify-content-end">
            <button class="btn btn-primary" type="submit">Ajouter la ligne</button>
        </div>
        </form>
    </div>
    <div class="col-lg-6" style="padding-left:30px">
    <div class="row">
        <div class="col-6"></div>
        <div class="col-6">
            <input pattern=".{3,}" class="form-control" type="date" oninput="${setDate}"
            value="${date}" />
        </div>
        </div>
        <div style="margin-bottom:15px">
            <label class="form-label">Nom de malade</label>
            <h5> ${patient?.firstName} ${patient?.lastName}</h5>
        </div>
        <div style="margin-bottom:15px">
            <label class="form-label">${patient.insurance?.label}</label>
            <h5> ${patient?.insuranceId}</h5>
        </div>
        <div>
            <label class="form-label">Examens</label>
            ${lines.map(line => html`<div>
                <div><h5> ${line.exam.label}</h5></div>
                <div>${line.note}</div>
            </div>`)} 
        </div>
    </div>
</div>
    <div class="col-12 d-flex justify-content-end">
        <button onclick=${createExams} class="btn btn-primary" type="submit">Créer</button>
    </div>
`;
}


export function AttendanceCertificateForm({ patient }: { patient: Patient }) {

    const [from, setFrom] = useInput('00:00');
    const [to, setTo] = useInput('00:00');

    const [validated, setValidated] = useState(false);

    const today = new Date();
    const todayDate = `${today.getFullYear()}-${(today.getMonth() + 1).toString().padStart(2, '0')}-${today.getDate().toString().padStart(2, '0')}`;

    const [date, setDate] = useInput(todayDate);

    const [state, dispatch] = useSession();

    const create = () => {
        if (to && from && date) {

            const id = generateId(state.user?.organizationId || '');

            dispatch({
                type: 'mutate',
                collection: 'certificates',
                id,
                data: {
                    patientId: patient._id,
                    from,
                    to,
                    date,
                    kind: 'attendance',
                },
            });

            toast.success('Ok');
            route('/certificates/' + id);
        }

    };

    return html`<div class="row">

        <div class="col-6">
        <label class="form-label" for="email1">Date</label>
        <input pattern=".{3,}" class="form-control" type="date" oninput="${setDate}"
            value="${date}" />
        </div>
        <div class="col-3">
        <label class="form-label" for="email1">A partir de</label>
            <input pattern=".{3,}" class="form-control" type="time" oninput="${setFrom}"
            value="${from}" />
        </div>
        <div class="col-3">
        <label class="form-label" for="email1">Jusqu'au</label>
            <input pattern=".{3,}" class="form-control" type="time" oninput="${setTo}"
            value="${to}" />
        </div>
        </div>
        <div style="margin-bottom:15px">
            <label class="form-label">Nom de malade</label>
            <h5> ${patient?.firstName} ${patient?.lastName}</h5>
        </div>

    <div class="col-12 d-flex justify-content-end">
        <button onclick=${create} class="btn btn-primary" type="submit">Créer</button>
    </div>
`;
}


export function MedicalCertificateForm({ patient }: { patient: Patient }) {

    const [days, setDays] = useInput(1);

    const today = new Date();
    const todayDate = `${today.getFullYear()}-${(today.getMonth() + 1).toString().padStart(2, '0')}-${today.getDate().toString().padStart(2, '0')}`;

    const [date, setDate] = useInput(todayDate);

    const [state, dispatch] = useSession();

    const create = () => {
        if (days && date) {

            const id = generateId(state.user?.organizationId || '');

            dispatch({
                type: 'mutate',
                collection: 'certificates',
                id,
                data: {
                    patientId: patient._id,
                    days,
                    date,
                    kind: 'medical',
                },
            });

            toast.success('Ok');
            route('/certificates/' + id);
        }

    };

    return html`<div class="row">
        <div style="margin-bottom:15px">
            <label class="form-label">Nom de malade</label>
            <h5> ${patient?.firstName} ${patient?.lastName}</h5>
        </div>

        <div class="col-6">
            <label class="form-label" for="email1">Date</label>
            <input pattern=".{3,}" class="form-control" type="date" oninput="${setDate}"
            value="${date}" />
        </div>
            <div class="col-6">
                <label class="form-label" for="email1">Jours</label>
                <input pattern=".{3,}" class="form-control" type="number" oninput="${setDays}"
                value="${days}" />
            </div>

        </div>

<br/>

    <div class="col-12 d-flex justify-content-end">
        <button onclick=${create} class="btn btn-primary" type="submit">Créer</button>
    </div>
`;
}


export function ExamsOverview(props: any) {

    const [session] = useSession();

    const [sort, setSort] = useState({ field: '', dir: {} as any });

    const [rows, setRows] = useState([] as (Prescription & Patient)[]);

    useEffect(() => {
        setRows(session.exams
            .sort((p, n) => p.timestamps._id > n.timestamps._id ? -1 : 1).map(exam => ({
                ...session.patients.find(it => it._id == exam.patientId) || {} as any,
                ...exam,
            })) as any);
    }, [session]);

    const onSort = (field: string) => {
        return () => {
            setSort({ field, dir: { [field]: sort.dir[field] == 'asc' ? 'desc' : 'asc' } });
        };
    };

    const page = getPage();

    const q = session.search.trim().toLowerCase();
    const dir = sort.dir[sort.field] == 'asc' ? 1 : -1;
    const exams = searchObjects(rows, q, sort, dir, page);


    return html`<div class="card mb-3" id="customersTable"
    data-list='{"valueNames":["name","email","phone","address","joined"],"page":10,"pagination":true}'>
    <div class="card-header">
        <div class="row flex-between-center">
            <div class="col-4 col-sm-auto d-flex align-items-center pr-0">
                <h5 class="fs-0 mb-0 text-nowrap py-2 py-xl-0">Demandes d'examens</h5>
            </div>
            <div class="col-8 col-sm-auto text-right pl-2">
                <div id="table-customers-replace-element">
                    <a href="/patients-add" class="btn btn-falcon-default btn-sm" type="button"><span
                            class="fas fa-plus" data-fa-transform="shrink-3 down-2"></span><span
                            class="d-none d-sm-inline-block ml-1">Ajouter</span></a>
                </div>
            </div>
        </div>
    </div>
    <div class="card-body p-0" style="height:${window.innerHeight - 240}px;overflow:auto">
        <div class="table-responsive">
            <table class="table table-sm table-striped fs--1 mb-0">
                <thead class="bg-200 text-900">
                    <tr>
                        <th class="no align-middle white-space-nowrap py-2" onclick=${onSort('no')}
                            class="sort ${sort.dir.no}" style="width:10px" data-sort>
                            N°</th>
                        <th onclick=${onSort('firstName')}
                            class="sort pr-1 align-middle white-space-nowrap ${sort.dir.firstName}" data-sort>
                            Nom</th>
                        <th class="sort align-middle white-space-nowrap desktop" >Téléphone</th>
                        <th class="sort pr-1 align-middle white-space-nowrap pl-5 desktop" data-sort="address"
                            style="min-width: 200px;">Addresse</th>
                        <th onclick=${onSort('birthdate')}
                            class="sort pr-1 align-middle white-space-nowrap ${sort.dir.birthdate}  desktop" data-sort="joined">
                            Date de naissance</th>
                        <th class="align-middle no-sort"></th>
                    </tr>
                </thead>
                <tbody class="list" id="table-customers-body">
                    ${exams.map(ExamsRequestRow)}
                    <br/>
        <br/>
                </tbody>
            </table>
        </div>
    </div>
    <${Pagination} count=${rows.length} page=${page}/>
</div>`;
}


export function ExamsRequestRow(row: Prescription & Patient) {

    return PrescriptionRow(row, 'exams');

}


export function CertificatesOverview(props: any) {

    const [session] = useSession();

    const [sort, setSort] = useState({ field: '', dir: {} as any });

    const [rows, setRows] = useState([] as (Prescription & Patient)[]);

    useEffect(() => {
        setRows(session.certificates
            .sort((p, n) => p.timestamps._id > n.timestamps._id ? -1 : 1).map(certificate => ({
                ...session.patients.find(it => it._id == certificate.patientId) || {} as any,
                ...certificate,
            })) as any);
    }, [session]);

    const onSort = (field: string) => {
        return () => {
            setSort({ field, dir: { [field]: sort.dir[field] == 'asc' ? 'desc' : 'asc' } });
        };
    };

    const page = getPage();

    const q = session.search.trim().toLowerCase();
    const dir = sort.dir[sort.field] == 'asc' ? 1 : -1;
    const exams = searchObjects(rows, q, sort, dir, page);


    return html`<div class="card mb-3" id="customersTable"
    data-list='{"valueNames":["name","email","phone","address","joined"],"page":10,"pagination":true}'>
    <div class="card-header">
        <div class="row flex-between-center">
            <div class="col-4 col-sm-auto d-flex align-items-center pr-0">
                <h5 class="fs-0 mb-0 text-nowrap py-2 py-xl-0">Certificats</h5>
            </div>
            <div class="col-8 col-sm-auto text-right pl-2">
                <div id="table-customers-replace-element">
                    <a href="/patients-add" class="btn btn-falcon-default btn-sm" type="button"><span
                            class="fas fa-plus" data-fa-transform="shrink-3 down-2"></span><span
                            class="d-none d-sm-inline-block ml-1">Ajouter</span></a>
                </div>
            </div>
        </div>
    </div>
    <div class="card-body p-0" style="height:${window.innerHeight - 240}px;overflow:auto">
        <div class="table-responsive">
            <table class="table table-sm table-striped fs--1 mb-0">
                <thead class="bg-200 text-900">
                    <tr>
                        <th class="no align-middle white-space-nowrap py-2" onclick=${onSort('no')}
                            class="sort ${sort.dir.no}" style="width:10px" data-sort>
                            N°</th>
                        <th onclick=${onSort('firstName')}
                            class="sort pr-1 align-middle white-space-nowrap ${sort.dir.firstName}" data-sort>
                            Nom</th>
                        <th class="sort align-middle white-space-nowrap desktop" >Téléphone</th>
                        <th class="sort pr-1 align-middle white-space-nowrap pl-5 desktop" data-sort="address"
                            style="min-width: 200px;">Addresse</th>
                        <th onclick=${onSort('birthdate')}
                            class="sort pr-1 align-middle white-space-nowrap ${sort.dir.birthdate}  desktop" data-sort="joined">
                            Date de naissance</th>
                        <th class="align-middle no-sort"></th>
                    </tr>
                </thead>
                <tbody class="list" id="table-customers-body">
                    ${exams.map(CertificateRow)}
                    <br/>
        <br/>
                </tbody>
            </table>
        </div>
    </div>
    <${Pagination} count=${rows.length} page=${page}/>
</div>`;
}

export function CertificateRow(row: Prescription & Patient) {

    return PrescriptionRow(row, 'certificates');

}