import { html } from "htm/preact";
import { FileDetails } from "./patients";
import { Drive } from './products';
import { useEffect, useState } from "preact/hooks";
import * as store from './store';
import Router, { route } from "preact-router";
import { Link } from 'preact-router/match';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as bootstrap from "bootstrap";
import { can, is, searchPatients, useSession } from "./store";
import { AddUser, UpdateUser, UserDetails, UsersOverview } from "./users";
import { t } from "./core";
import { AddProcedureType, ProcedureTypeDetails, ProceduresTypesOverview, UpdateProcedureType } from "./procedures_types";
import { AddProcedure, ProcedureDetails, ProceduresOverview, UpdateProcedure } from "./procedures";
import { AddEntity, EntitiesOverview, EntityDetails, UpdateEntity } from "./entities";
import { AddContact, ContactDetails, ContactsOverview, UpdateContact } from "./contacts";

function showInstallInstructions() {
    var m = new bootstrap.Modal(document.getElementById('install-info') as any)
    m.show();
}

function closeInstallInstructions() {
    var m = new bootstrap.Modal(document.getElementById('install-info') as any)
    m.hide();
}



export function Nav1({ showNavbar, setShowNavbar }: any) {

    const [state] = useSession();

    const [shown, setShown] = useState({} as any);

    const setOpen = (name: string) => setShown({ ...shown, [name]: !shown[name] })

    return html`<nav class="navbar navbar-light navbar-vertical navbar-expand-xl">
    <div style="opacity:0" class="d-flex align-items-center">
        <div class="toggle-icon-wrapper">
            <button class="btn navbar-toggler-humburger-icon navbar-vertical-toggle" data-toggle="tooltip"
                data-placement="left" title="Toggle Navigation"><span class="navbar-toggle-icon"><span
                        class="toggle-line"></span></span></button>

        </div>
        <a class="navbar-brand" href="/">
            <div class="d-flex align-items-center py-3"><span class="font-sans-serif">Finances</span>
            </div>
        </a>
    </div>
    <div class="collapse navbar-collapse ${showNavbar ? 'show' : ''}" id="navbarVerticalCollapse">
        <div class="navbar-vertical-content scrollbar">
            <ul class="navbar-nav flex-column">
            <li class="nav-item">
            <${Link}  onclick=${() => setShowNavbar(false)} activeClassName="active" class="nav-link" href="/procedures">
                <div class="d-flex align-items-center">
                    <span class="nav-link-icon">
                        <span class="fas fa-images"></span>
                    </span>
                    <span class="nav-link-text">${t('Procedures')}</span>
                </div>
            </${Link}>
        </li>
            <li class="nav-item">
                <${Link}  onclick=${() => setShowNavbar(false)} activeClassName="active" class="nav-link" href="/entities">
                    <div class="d-flex align-items-center">
                        <span class="nav-link-icon">
                            <span class="fas fa-images"></span>
                        </span>
                        <span class="nav-link-text">${t('Taxable Entities')}</span>
                    </div>
                </${Link}>
            </li>
            <li class="nav-item">
            <${Link}  onclick=${() => setShowNavbar(false)} activeClassName="active" class="nav-link" href="/contacts">
                <div class="d-flex align-items-center">
                    <span class="nav-link-icon">
                        <span class="fas fa-images"></span>
                    </span>
                    <span class="nav-link-text">${t('Contact Persons')}</span>
                </div>
            </${Link}>
        </li>
                <li class="nav-item">
                    <${Link}  onclick=${() => setShowNavbar(false)} activeClassName="active" class="nav-link" href="/files">
                        <div class="d-flex align-items-center">
                            <span class="nav-link-icon">
                                <span class="fas fa-images"></span>
                            </span>
                            <span class="nav-link-text">${t('Files')}</span>
                        </div>
                    </${Link}>
                </li>

                <li class="nav-item">
                    <${Link} path="/admin/:child"  onclick=${() => { setShowNavbar(false); setOpen('admin') }} activeClassName="active" class="nav-link dropdown-indicator ${shown['admin'] ? '' : 'collapsed'}  " href="/admin/users">
                        <div class="d-flex align-items-center">
                            <span class="nav-link-icon">
                                <span class="fas fa-chart-pie"></span>
                            </span>
                            <span class="nav-link-text">${t('Administration')}</span>
                        </div>
                    </${Link}>
                    <ul class="nav collapse ${shown['admin'] ? 'show' : ''}">
                        <li class="nav-item"><${Link} class="nav-link" activeClassName="active" href="/admin/procedures-types">${t('Procedures Types')}</${Link}></li>
                        <li class="nav-item"><${Link} class="nav-link" activeClassName="active" href="/admin/users">${t('Users')}</${Link}></li>
                       <!-- <li class="nav-item"><${Link} class="nav-link" activeClassName="active" href="/admin/settings">${t('Settings')}</${Link}></li> -->
                    </ul>
                </li>
            </ul>

        </div>
    </div>
</nav>`;
}

export function Nav2({ showNavbar, setShowNavbar }: any) {

    const [show, setShow] = useState('');
    const [syncing, setSyncing] = useState(false);
    const [offline, setOffline] = useState(false);


    const [profileOpen, setProfileOpen] = useState(false);

    const [state, dispatch] = store.useSession();

    useEffect(() => {
        const handler = () => {
            setProfileOpen(false);
            setShow('');
            setShowNavbar(false);
        };
        document.addEventListener('click', handler, { passive: true });
        return () => {
            document.removeEventListener('click', handler);
        };
    }, [])


    // state.setSyncing = setSyncing;
    // state.setOffline = setOffline;

    function canShowSearch() {
        return window.location.pathname !== '/patients' && !window.location.pathname.startsWith('/drugs');
    }


    return html`<nav class="navbar navbar-light navbar-glass navbar-top navbar-expand">

    <button onclick=${(ev: any) => {
            ev.preventDefault();
            ev.stopImmediatePropagation();
            setShowNavbar(!showNavbar);
        }} class="btn navbar-toggler-humburger-icon navbar-toggler mr-1 mr-sm-3" type="button" data-toggle="collapse"
        data-target="#navbarVerticalCollapse" aria-controls="navbarVerticalCollapse" aria-expanded="false"
        aria-label="Toggle Navigation"><span class="navbar-toggle-icon"><span
                class="toggle-line"></span></span></button>
    <a class="navbar-brand mr-1 mr-sm-3" href="/">
        <div class="d-flex align-items-center"><span class="font-sans-serif">Finances</span>
        </div>
    </a>

    <ul class="navbar-nav align-items-center d-none d-lg-block">
        <li class="nav-item">
            <div class="search-box" data-list='{"valueNames":["title"]}'>
                <form class="position-relative" data-toggle="search" data-display="static">
                    <input class="form-control search-input fuzzy-search" type="search" placeholder="${t('Search')}..."
                        aria-label="Search" value=${state.search} oninput=${(ev: any) => {
            dispatch({ type: 'search', data: ev.target.value });
            setShow(ev.target.value.length >= 2 && canShowSearch() ? 'show' : '')
        }
        } onfocus=${(ev: any) => setShow(ev.target.value.length >= 2 && canShowSearch() ? 'show' :
            '')} onclick=${(ev: Event) => {
                ev.preventDefault();
                ev.stopImmediatePropagation();
                ev.stopPropagation();
            }} />
                    <span class="fas fa-search search-box-icon"></span>
                </form>

                <button class="btn-close position-absolute right-0 top-50 translate-middle shadow-none p-1 mr-1 fs--2"
                    type="button" data-dismiss="search"></button>

                <!--<div class="dropdown-menu border font-base left-0 mt-2 py-0 overflow-hidden w-100 ${show}">
                    <div class="scrollbar list py-3" style="max-height: 24rem;">

                        ${searchView(state, dispatch)}


                    </div>
                </div>-->
            </div>
        </li>
    </ul>
    <ul class="navbar-nav navbar-nav-icons ml-auto flex-row align-items-center">


        <li class="nav-item dropdown"><a class="nav-link pr-0" role="button" onclick=${(ev: any) => {
            ev.preventDefault();
            ev.stopImmediatePropagation();
            setProfileOpen(!profileOpen);
        }}>
                <div class="avatar avatar-xl">
                    <img class="rounded-circle" src="/assets/img/team/avatar.png" alt="" />

                </div>
            </a>
            <${ProfileDropdown} show=${profileOpen} />

        </li>
    </ul>
</nav>
<div class="modal fade" 
             id="install-info"
             tabindex="-1" 
             role="dialog"> 
            <div class="modal-dialog" 
                 role="document"> 
                <div class="modal-content" style="width:516px"> 
                    <div class="modal-header"> 
                        <!-- w-100 class so that header 
                div covers 100% width of parent div -->
                        <h5 class="modal-title w-100" 
                            id="exampleModalLabel"> 
                          Comment installer 
                      </h5> 
                    </div> 
  
                    <!--Modal body with image-->
                    <div class="modal-body"> 
                        <img src="/assets/img/install.png" /> 
                    </div> 

                </div> 
            </div> 
        </div> 
`;
}

export function ProfileDropdown({ show }: { show: boolean }) {
    return html`<div class="dropdown-menu dropdown-menu-right py-0 ${show ? 'show' : ''}">
    <div class="bg-white rounded-lg py-2">
   <!--
    <a class="dropdown-item font-weight-bold text-warning" href="#!"><span class="fas fa-crown mr-1"></span><span>Go
                Pro</span></a>

        <div class="dropdown-divider"></div>
        <a class="dropdown-item" href="#!">Set status</a>
        <a class="dropdown-item" href="pages/profile.html">Profile &amp; account</a>
        <a class="dropdown-item" href="#!">Feedback</a>

        <div class="dropdown-divider"></div>
        <a class="dropdown-item" href="/settings">Settings</a>
-->
        <a class="dropdown-item" href="/logout">Logout</a>
    </div>
</div>`;
}

function None() {
    route('/procedures');
}

/*

    <${PatientsOverview} a="sd" path="/patients" />
    <${UnpaidPatientsOverview} a="sd" path="/stats/patients-unpaid" />
    <${AddPatient} path="/patients-add" />
    <${PatientDetails} path="/patients/:id" />
    <${UpdatePatient} path="/patients/:id/edit" />
    <${AddPrescription} path="/patients/:id/prescribe" />
    <${AddExams} path="/patients/:id/exams" />
    <${AddAttendanceCertificate} path="/patients/:id/attendance-certificate" />
    <${AddMedicalCertificate} path="/patients/:id/medical-certificate" />

    
    ${can(session, 'drugs') ? html`
    <${DrugsOverview} path="/drugs" />
    <${PrescriptionsOverview} path="/prescriptions" />
    <${PrescriptionView} path="/prescriptions/:id" />
    <${ExamsOverview} path="/exams" />
    <${ExamsView} path="/exams/:id" />
    <${CertificatesOverview} path="/certificates" />

    <${CertificateView} path="/certificates/:id" />

    <${DrugView} path="/drugs/:id" />` : html``}

    ${can(session, 'purchase-orders') ? html` 
    <${PurchaseOrdersOverview} path="/inventory/purchase-orders" />
    <${AddPurchaseOrder} path="/inventory/purchase-orders/new" />
    <${PurchaseOrderDetails} path="/inventory/purchase-orders/:id" />
    <${StockCountAdd} path="/inventory/count" />` : html``}

    <${ProductsOverview} a="sd" path="/products" />
    <${AddProduct} path="/products-add" />
    <${ProductDetails} path="/products/:id" />
    <${UpdateProduct} path="/products/:id/edit" />


    <${StockCountOverview} path="/inventory/counts" />
    <${StockCountAdd} path="/inventory/counts-add" />
    <${StockCountDetails} path="/inventory/counts/:id" />
    

    ${can(session, 'calendar') ? html`<${Calendar} path="/calendar" />` : html``}
    ${can(session, 'waiting') ? html`<${Kanban} path="/waiting" />` : html``}


    
    ${is(session, 'admin') ? html`
    <${Stats} path="/stats/revenue" />
    <${SettingsView} path="/admin/settings" />
    <${PrescriptionSettings} path="/settings/prescription" />
    <${ExamsSettings} path="/settings/exams" />
    <${AttendanceCertificateSettings} path="/settings/attendance-certificate" />
    <${MedicalCertificateSettings} path="/settings/medical-certificate" />` : html``}
    */

export function Page() {
    const [session] = store.useSession();
    return html`<${Router}>

    <${Drive} path="/files" />
    <${FileDetails} path="/files/:id" />



    ${can(session, 'users') ? html`<${UsersOverview} path="/admin/users" />
    <${ProceduresTypesOverview} path="/admin/procedures-types" />
    <${AddProcedureType} path="/procedures-types-add" />
    <${ProcedureTypeDetails} path="/procedures-types/:id" />
    <${UpdateProcedureType} path="/procedures-types/:id/edit" />

    <${ProceduresOverview} path="/procedures" />
    <${AddProcedure} path="/procedures-add" />
    <${ProcedureDetails} path="/procedures/:id" />
    <${UpdateProcedure} path="/procedures/:id/edit" />

    <${EntitiesOverview} path="/entities" />
    <${AddEntity} path="/entities-add" />
    <${EntityDetails} path="/entities/:id" />
    <${UpdateEntity} path="/entities/:id/edit" />

    <${ContactsOverview} path="/contacts" />
    <${AddContact} path="/contacts-add" />
    <${ContactDetails} path="/contacts/:id" />
    <${UpdateContact} path="/contacts/:id/edit" />
    

    <${AddUser} path="/users-add" />
    <${UserDetails} path="/users/:id" />
    <${UpdateUser} path="/users/:id/edit" />` : html``}

    <${None} default />
</${Router}>`;
}

export function Main() {

    const [session, dispatch] = store.useSession();

    if (!session.token) {
        return route('/login');
    }


    const [showNavbar, setShowNavbar] = useState(false);
    const [progress, setProgress] = useState(0);

    const [int, setInt] = useState(null as any);



    return html`<main class="main" id="top">
    <div class="container-fluid">
        <${Nav1} ...${{ showNavbar, setShowNavbar }}  />
        <div class="content">
            <${Nav2} ...${{ showNavbar, setShowNavbar }} />
            <${Page} />
            <footer>
                <div class="row g-0 justify-content-between fs--1 mt-4 mb-3">

                </div>
            </footer>
        </div>
        <div class="modal fade modal-fixed-right modal-theme overflow-hidden" id="settings-modal" tabindex="-1"
            role="dialog" aria-labelledby="settings-modal-label" aria-hidden="true"
            data-options='{"autoShow":true,"autoShowDelay":3000,"showOnce":true}'>
            <div class="modal-dialog modal-dialog-vertical" role="document">
                <div class="modal-content border-0 vh-100 scrollbar">
                    <div class="modal-header modal-header-settings">
                        <div class="z-index-1 py-1">
                            <h5 class="text-white" id="settings-modal-label"> <span
                                    class="fas fa-palette mr-2 fs-0"></span>Settings</h5>
                            <p class="mb-0 fs--1 text-white opacity-75"> Set your own customized style</p>
                        </div>
                        <button class="btn-close btn-close-white z-index-1 mt-0" type="button" data-dismiss="modal"
                            aria-label="Close"></button>
                    </div>
                    <div class="modal-body px-card">
                        <div class="d-flex justify-content-between">
                            <div class="d-flex align-items-start"><img class="mr-2"
                                    src="/assets/img/icons/left-arrow-from-left.svg" width="20" alt="" />
                                <div class="flex-1">
                                    <h5 class="fs-0">RTL Mode</h5>
                                    <p class="fs--1 mb-0">Switch your language direction </p>
                                </div>
                            </div>
                            <div class="form-check form-switch">
                                <input class="form-check-input ml-0" id="mode-rtl" type="checkbox"
                                    data-home-url="index.html" data-page-url="documentation/RTL.html" />
                            </div>
                        </div>
                        <hr />
                        <div class="d-flex justify-content-between">
                            <div class="d-flex align-items-start"><img class="mr-2" src="/assets/img/icons/arrows-h.svg"
                                    width="20" alt="" />
                                <div class="flex-1">
                                    <h5 class="fs-0">Fluid Layout</h5>
                                    <p class="fs--1 mb-0">Toggle container layout system </p>
                                </div>
                            </div>
                            <div class="form-check form-switch">
                                <input class="form-check-input ml-0" id="mode-fluid" type="checkbox"
                                    data-home-url="index.html" data-page-url="documentation/fluid-layout.html" />
                            </div>
                        </div>
                        <hr />
                        <div class="d-flex align-items-start"><img class="mr-2" src="/assets/img/icons/paragraph.svg"
                                width="20" alt="" />
                            <div class="flex-1">
                                <h5 class="fs-0 d-flex align-items-center">Navigation Position </h5>
                                <p class="fs--1 mb-2">Select a suitable navigation system for your web application </p>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" id="option-navbar-vertical" type="radio"
                                        name="navbar" value="vertical" checked="checked"
                                        data-page-url="components/navbar/vertical.html" />
                                    <label class="form-check-label" for="option-navbar-vertical">Vertical</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" id="option-navbar-top" type="radio" name="navbar"
                                        value="top" data-page-url="components/navbar/top.html" />
                                    <label class="form-check-label" for="option-navbar-top">Top</label>
                                </div>
                                <div class="form-check form-check-inline mr-0">
                                    <input class="form-check-input" id="option-navbar-combo" type="radio" name="navbar"
                                        value="combo" data-page-url="components/navbar/combo.html" />
                                    <label class="form-check-label" for="option-navbar-combo">Combo</label>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <h5 class="fs-0 d-flex align-items-center">Vertical Navbar Style</h5>
                        <p class="fs--1">Switch between styles for your vertical navbar </p>
                        <div class="btn-group btn-block btn-group-navbar-style">
                            <div class="row gx-2">
                                <div class="col-6">
                                    <input class="btn-check" id="navbar-style-transparent" type="radio"
                                        name="navbarStyle" checked="checked" data-page-url="index.html"
                                        value="transparent" />
                                    <label class="btn btn-block btn-navbar-style fs--1" for="navbar-style-transparent">
                                        <img class="img-fluid img-prototype" src="/assets/img/generic/default.png"
                                            alt="" /><span class="label-text"> Transparent</span></label>
                                </div>
                                <div class="col-6">
                                    <input class="btn-check" id="navbar-style-inverted" type="radio" name="navbarStyle"
                                        data-page-url="demo/navbar-vertical-inverted.html" value="inverted" />
                                    <label class="btn btn-block btn-navbar-style fs--1" for="navbar-style-inverted">
                                        <img class="img-fluid img-prototype" src="/assets/img/generic/inverted.png"
                                            alt="" /><span class="label-text"> Inverted</span></label>
                                </div>
                                <div class="col-6">
                                    <input class="btn-check" id="navbar-style-card" type="radio" name="navbarStyle"
                                        data-page-url="demo/navbar-vertical-card.html" value="card" />
                                    <label class="btn btn-block btn-navbar-style fs--1" for="navbar-style-card"> <img
                                            class="img-fluid img-prototype" src="/assets/img/generic/card.png"
                                            alt="" /><span class="label-text"> Card</span></label>
                                </div>
                                <div class="col-6">
                                    <input class="btn-check" id="navbar-style-vibrant" type="radio" name="navbarStyle"
                                        data-page-url="demo/navbar-vertical-vibrant.html" value="vibrant" />
                                    <label class="btn btn-block btn-navbar-style fs--1" for="navbar-style-vibrant"> <img
                                            class="img-fluid img-prototype" src="/assets/img/generic/vibrant.png"
                                            alt="" /><span class="label-text"> Vibrant</span></label>
                                </div>
                            </div>
                        </div>
                        <div class="text-center mt-5"><img class="mb-4" src="/assets/img/illustrations/settings.png"
                                alt="" width="120" />
                            <h5>Like What You See?</h5>
                            <p class="fs--1">Get Falcon now and create beautiful dashboards with hundreds of widgets.
                            </p><a class="btn btn-primary"
                                href="https://themes.getbootstrap.com/product/falcon-admin-dashboard-webapp-template/"
                                target="_blank">Purchase</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="authentication-modal" tabindex="-1" role="dialog"
            aria-labelledby="authentication-modal-label" aria-hidden="true">
            <div class="modal-dialog mt-6" role="document">
                <div class="modal-content border-0">
                    <div class="modal-header px-5 text-white position-relative modal-shape-header">
                        <div class="position-relative z-index-1">
                            <h4 class="mb-0 text-white" id="authentication-modal-label">Register</h4>
                            <p class="fs--1 mb-0">Please create your free Falcon account</p>
                        </div>
                        <button class="btn-close btn-close-white position-absolute top-0 right-0 mt-2 mr-2"
                            data-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body py-4 px-5">
                        <form>
                            <div class="mb-3">
                                <label class="form-label" for="modal-auth-name">Name</label>
                                <input class="form-control" type="text" id="modal-auth-name" />
                            </div>
                            <div class="mb-3">
                                <label class="form-label" for="modal-auth-email">Email address</label>
                                <input class="form-control" autocomplete="new-username" type="email" id="modal-auth-email" />
                            </div>
                            <div class="row gx-3">
                                <div class="mb-3 col-sm-6">
                                    <label class="form-label" for="modal-auth-password">Password</label>
                                    <input class="form-control" autocomplete="new-password"  type="password" id="modal-auth-password" />
                                </div>
                                <div class="mb-3 col-sm-6">
                                    <label class="form-label" for="modal-auth-confirm-password">Confirm Password</label>
                                    <input class="form-control" autocomplete="new-password" type="password" id="modal-auth-confirm-password" />
                                </div>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" id="modal-auth-register-checkbox" />
                                <label class="form-label" for="modal-auth-register-checkbox">I accept the <a
                                        href="#!">terms </a>and <a href="#!">privacy policy</a></label>
                            </div>
                            <div class="mb-3">
                                <button class="btn btn-primary btn-block mt-3" type="submit"
                                    name="submit">Register</button>
                            </div>
                        </form>
                        <div class="position-relative mt-5">
                            <hr class="bg-300" />
                            <div
                                class="position-absolute top-50 left-50 translate-middle px-3 bg-white font-sans-serif fs--1 text-500 text-nowrap">
                                or register with</div>
                        </div>
                        <div class="row g-2 mt-2">
                            <div class="col-sm-6"><a class="btn btn-outline-google-plus btn-sm btn-block" href="#"><span
                                        class="fab fa-google-plus-g mr-2" data-fa-transform="grow-8"></span> google</a>
                            </div>
                            <div class="col-sm-6"><a class="btn btn-outline-facebook btn-sm btn-block" href="#"><span
                                        class="fab fa-facebook-square mr-2" data-fa-transform="grow-8"></span>
                                    facebook</a></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>
`;
}



function searchView(state: store.SessionContext, dispatch: any) {

    const path = window.location.pathname;

    if (path.indexOf('/purchase-orders/') !== -1 || path.indexOf('/prescribe') !== -1) {

        return html`<h6 class="dropdown-header font-weight-medium text-uppercase px-card fs--2 pt-0 pb-2">Drugs</h6>
        ${store.searchVisibleDrugs(state.drugs, state.search).slice(0, 5).map(drug => html`<div
            style="display:flex"><a class="dropdown-item px-card py-2"
            onclick=${() => dispatch({ type: 'quick', data: { drug } })}>
                <div class="d-flex align-items-center">
                    <div class="avatar avatar-l statuss-onlinee mr-2">
                        <img class="rounded-circle" src="/assets/img/team/2.jpg" alt="" />
    
                    </div>
                    <div class="flex-1">
                        <h6 class="mb-0 title">${drug.fullname || drug.name}</h6>
                        <p class="fs--2 mb-0">${drug._id}</p>
                    </div>
                </div>
            </a><a href="/drugs/${drug._id}" class="dropdown-item px-card py-2" style="width:50px">
                <div class="d-flex align-items-center" style="padding-top:8px">
                    <${FontAwesomeIcon} icon=${['fa', 'eye']} />
                </div>
    
            </a>
        </div>`)}`;


    } else {

        return html`<h6 class="dropdown-header font-weight-medium text-uppercase px-card fs--2 pt-0 pb-2">Patients
    </h6>
    ${store.searchPatients(state.patients, state.search).slice(0, 5).map(patient => html`<div
        style="display:flex"><a class="dropdown-item px-card py-2"
        onclick=${() => dispatch({
            type: 'quick-patient', data:
                patient
        })}
        
        >
            <div class="d-flex align-items-center">
                <div class="avatar avatar-l statuss-onlinee mr-2">
                    <img class="rounded-circle" src="/assets/img/team/2.jpg" alt="" />

                </div>
                <div class="flex-1">
                    <h6 class="mb-0 title">${patient.firstName} ${patient.lastName}</h6>
                    <p class="fs--2 mb-0">${patient.birthdate}</p>
                </div>
            </div>
        </a><a href="/patients/${patient._id}" class="dropdown-item px-card py-2" style="width:50px">
                        <div class="d-flex align-items-center" style="padding-top:8px">
                <${FontAwesomeIcon} icon=${['fa', 'eye']} />
            </div>

        </a>
    </div>`)}`;

    }

}