import { html } from "htm/preact";
import { generateId, getPage, Pagination, t, useInput } from "./core";
import { useContext, useEffect, useRef, useState } from "preact/hooks";

import Select from 'react-select'
import { citites, socialSecurityOptions } from "./data";

import { Api, debounce, filesStore, getDownloadUrl, merge, searchObjects, SessionContext, SFile, state, useSession } from "./store";
import { Billable } from "./shared/core";
import { route } from "preact-router";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CreatableSelect from 'react-select/creatable';
import { Drive } from "./products";

// hello

const options = citites.map(it => ({ label: it.c + ', ' + it.g, value: it.id1 + '-' + it.id2 }));
const insuranceOptions = [{ label: 'Selectioner' }, { label: 'CNSS', value: 'cnss' }, { label: 'CNRPS', value: 'cnrps' }];

/*
function useForm(pairs:[string,string][],callback:any){

    const debounce = 
    const out:any[] = [];
    for(const pair of pairs) {
        const [state, setState] = useState(pair[1]);
        out.push([state, (ev: any) => {
            setState(ev.target.value);


            // dispatch({collection, type: 'mutate', id,  data: {[pair[0]]: ev.target.value}})
        }]);
    }
    return out;
}*/


export function FileUpload() {

    return html`<form class="dropzone dropzone-multiple p-0" id="my-awesome-dropzone" data-dropzone="data-dropzone" action="#!">
    <div class="fallback"><input name="file" type="file" multiple="multiple" /></div>
    <div class="dz-message" data-dz-message="data-dz-message"> <img class="me-2" src="../../../assets/img/icons/cloud-upload.svg" width="25" alt="" />Drop your files here</div>
    <div class="dz-preview dz-preview-multiple m-0 d-flex flex-column">
      <div class="d-flex media mb-3 pb-3 border-bottom btn-reveal-trigger"><img class="dz-image" src="../../../assets/img/generic/image-file-2.png" alt="..." data-dz-thumbnail="data-dz-thumbnail" />
        <div class="flex-1 d-flex flex-between-center">
          <div>
            <h6 data-dz-name="data-dz-name"></h6>
            <div class="d-flex align-items-center">
              <p class="mb-0 fs-10 text-400 lh-1" data-dz-size="data-dz-size"></p>
              <div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress=""></span></div>
            </div><span class="fs-11 text-danger" data-dz-errormessage="data-dz-errormessage"></span>
          </div>
          <div class="dropdown font-sans-serif"><button class="btn btn-link text-600 btn-sm dropdown-toggle btn-reveal dropdown-caret-none" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><span class="fas fa-ellipsis-h"></span></button>
            <div class="dropdown-menu dropdown-menu-end border py-2"><a class="dropdown-item" href="#!" data-dz-remove="data-dz-remove">Remove File</a></div>
          </div>
        </div>
      </div>
    </div>
  </form>`;
}


export function UpdateObject(form: any, path: string, { id }: { id: string }, label?: string) {
    const [session] = useSession();


    const [object, setObject] = useState();


    useEffect(() => {
        if (id) {
            Api.get(path, id, session.token as any).then((data) => {
                setObject(data);
            });
        }
    }, []);

    if (object) {

        return html`<div class="row g-0">
    <div>
        <div class="card mb-3">
            <div class="card-header">
                <h5 class="mb-0">${t('Update')} ${t(label || '')}</h5>
            </div>
            <div class="card-body bg-light">
                <${form} data=${object} />
            </div>
        </div>
    </div>
</div>`;
    } else {
        return html`<div>Loading</div>`;
    }
}



export function ObjectsOverview(name: string, path: string, ObjectRow_: any, props: any) {

    const [session, dispatch] = useSession();

    const [proceduresTypes, setProceduresTypes] = useState<any[]>([]);

    const [objects, setObjects] = useState<User[]>([]);
    const [sort, setSort] = useState({ field: '', dir: {} as any });

    const onSort = (field: string) => {
        return () => {
            setSort({ field, dir: { [field]: sort.dir[field] == 'asc' ? 'desc' : 'asc' } });
        };
    };

    const page = getPage();

    const q = session.search.trim().toLowerCase();
    const dir = sort.dir[sort.field] == 'asc' ? 1 : -1;
    // const allUsers = searchObjects(session.users, q, sort, dir, page);
    // const users = allUsers.slice((page - 1) * 20, page * 20);

    useEffect(() => {
        Api.search(path, '', 1, {}, session.token).then(({ data }: { data: User[] }) => {
            setObjects(data);
            //dispatch({ type: 'set', collection: 'users', data: users });
        });
    }, []);

    useEffect(() => {
        Api.search('/procedures-types', '', 1, {}, session.token).then((res) => {
            setProceduresTypes(res.data);
        });
    }, []);


    const onDelete = (object: any) => {
        setObjects(objects.filter(it => it.id != object.id));
    }

    return html`<div class="card mb-3" id="customersTable"
    data-list='{"valueNames":["name","email","phone","address","joined"],"page":10,"pagination":true}'>
    <div class="card-header">
        <div class="row flex-between-center">
            <div class="col-4 col-sm-auto d-flex align-items-center pr-0">
                <h5 class="fs-0 mb-0 text-nowrap py-2 py-xl-0">${t(name)}</h5>
            </div>
            <div class="col-8 col-sm-auto text-right pl-2">
                <div id="table-customers-replace-element">
                    <a href="${path}-add" class="btn btn-falcon-default btn-sm" type="button"><span
                            class="fas fa-plus" data-fa-transform="shrink-3 down-2"></span><span
                            class="d-none d-sm-inline-block ml-1">${t('Add')}</span></a>
                </div>
            </div>
        </div>
    </div>
    <div class="card-body p-0" style="height:${window.innerHeight - 240}px;overflow:auto">
        <div class="table-responsive">
            <table class="table table-sm table-striped fs--1 mb-0">
                <thead class="bg-200 text-900">
                    <tr>
                        <th class="no align-middle white-space-nowrap py-2" onclick=${onSort('id')}
                            class="sort ${sort.dir.id}" style="width:10px" data-sort>
                            ${t('National Identification Number')}</th>
                        <th onclick=${onSort('name')}
                            class="sort pr-1 align-middle white-space-nowrap ${sort.dir.name}" data-sort>${t('Name')}</th>
                      <!--  <th onclick=${onSort('role')}
                            class="sort pr-1 align-middle white-space-nowrap ${sort.dir.role}" data-sort>Email
                        </th> -->
                        <th class="sort align-middle white-space-nowrap desktop" >${t('Phone')}</th>
                        <th class="sort pr-1 align-middle white-space-nowrap pl-5 desktop" data-sort="address"
                            style="min-width: 200px;">${t('Email')}</th>
                        <th class="align-middle no-sort"></th>
                    </tr>
                </thead>
                <tbody class="list" id="table-customers-body">
                    ${objects.map(it => ObjectRow(path, it, onDelete))}
                    <br/>
        <br/>
                </tbody>
            </table>
        </div>
    </div>
    <${Pagination} count=${objects.length} page=${page}/>
</div>`;
}


export interface User {
    id: number;
    identifier: string;
    name: string;
    roles: string[];
    status: string;
    password: string;
}

function diff(a: any, base: any) {
    const out: any = {};
    for (const key in a) {
        if (a[key] != base[key]) {
            out[key] = a[key];
        }
    }
    return out;
}

export function ObjectRow(path: string, object: any, onDelete: any) {

    const [open, setOpen] = useState(false);
    const [state, dispatch] = useSession();
    const [busy, setBusy] = useState(false);

    useEffect(() => {
        const handler = () => setOpen(false);
        document.addEventListener('click', handler, { passive: true });
        return () => {
            document.removeEventListener('click', handler);
        };
    }, []);


    function onDeleteClick(object: any) {
        if (busy) return;
        setBusy(true);
        Api.remove(path + '/' + object.id, state.token || '')
            .then(() => {
                console.log('DELETED');
                setOpen(false);
                onDelete(object);
                setBusy(false);
            })
            .catch((err) => {
                console.log('ERROR', err);
                setBusy(false);
            });
    };

    JSON.stringify("OBJECT", object);

    return html`<tr class="btn-reveal-trigger">
    <td class="no align-middle white-space-nowrap">${object.identifier}</td>
    <td class="name align-middle white-space-nowrap">
        <a href="${path}/${object.id}">
            <h6>${object.name}</h6>
        </a>
    </td>
    <td class="phone align-middle white-space-nowrap desktop">${object.phone}</td>
    <td class="address align-middle white-space-nowrap pl-5 desktop">${t(object.email)}</td>
    <td class="align-middle white-space-nowrap text-right actions-column">
        <div class="dropdown font-sans-serif">
            <button onclick=${(ev: any) => {
            ev.preventDefault();
            ev.stopImmediatePropagation();
            setOpen(!open);
        }} class="btn btn-link text-600 btn-sm dropdown-toggle btn-reveal"
                type="button"
                id="customer-dropdown-0" data-toggle="dropdown" data-boundary="window" aria-haspopup="true"
                aria-expanded="false">
                <${FontAwesomeIcon} icon=${['fas', 'ellipsis-h']} class="fs--1" /></button>
            <div class="dropdown-menu dropdown-menu-right border py-0 ${open ? 'show' : ''}"
                aria-labelledby="customer-dropdown-0">
                <div class="bg-white">
                    <!--<a class="dropdown-item" href="#!">Edit</a>-->
                    <a class="dropdown-item text-danger" onclick=${() => onDeleteClick(object)}>Delete</a></div>
            </div>
        </div>
    </td>
</tr>`;
}

function NoValue(label: string) {
    return html`<p class="font-italic text-400 mb-0">${label}</p>`;
}

function Value({ label, value }: { label: string, value: string }) {
    return value ? (html`${value}`) : NoValue(label);
}

export function UserDetails({ id }: { id?: string }) {

    const [session, dispatch] = useSession();

    const [user, setUser] = useState<User | undefined>();

    useEffect(() => {
        if (id) {
            Api.get('/users', id, session.token as any).then((data) => {
                setUser(data);
            });
        }
    }, []);
    /*
    const saveNotes:any = useRef(debounce(function(notes:string){
        dispatch({type:'mutate',collection: 'users',id, data:{notes} });
    },4000)).current;
*/
    const setNotes = (ev: any) => dispatch({ type: 'mutate', collection: 'users', id, data: { notes: ev.target.value }, debounce: true });
    if (user) {

        return html`<div class="card mb-3">
    <div class="card-header">
        <div class="row align-items-center">
            <div class="col">
                <h5 class="mb-0">#${user.identifier}</h5>
            </div>

            <div class="col-auto"><a class="btn btn-falcon-default btn-sm" href="/users/${id}/edit">
                    <${FontAwesomeIcon} icon=${['fas', 'pencil-alt']} /> Mettre à jour les détails</a></div>
        </div>
    </div>
    <div class="card-body bg-light border-top">
        <div class="row">
            <div class="col-lg col-xxl-5">
                <div class="row">
                    <div class="col-5 col-sm-4">
                        <p class="font-weight-semi-bold mb-1">${t('Name')}</p>
                    </div>
                    <div class="col">${user.name}</div>
                </div>
                <div class="row">
                    <div class="col-5 col-sm-4">
                        <p class="font-weight-semi-bold mb-0">Addresse</p>
                    </div>
                    <div class="col">
                        <${Value} label="Pas d'addresse" value=${user.address || user.city?.label} />
                    </div>
                </div>
            </div>
            <div class="col-lg col-xxl-5 mt-4 mt-lg-0 offset-xxl-1">
                <div class="row">
                    <div class="col-5 col-sm-4">
                        <p class="font-weight-semi-bold mb-1">Email</p>
                    </div>
                    <div class="col">${user.email}</div>
                </div>
                <div class="row">
                    <div class="col-5 col-sm-4">
                        <p class="font-weight-semi-bold mb-1">Téléphone</p>
                    </div>
                    <div class="col">
                        <${Value} label="Pas de téléphone" value=${user.phones?.join(';')} />
                    </div>
                </div>
                <div class="row">
                    <div class="col-5 col-sm-4">
                        <p class="font-weight-semi-bold mb-1">${t('Roles')}</p>
                    </div>
                    <div class="col">${user.roles?.map(t).join(', ')}</div>
                </div>
            </div>
        </div>
    </div>
</div>
`;

    } else {
        return html`<div>User ${id} not found</div>`;
    }
}
